import { FC, useCallback, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { toast } from "react-toastify";

import { IosShare, MailOutline } from "@mui/icons-material";
import { Box, Button, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi, ShareApplicationParams } from "@api";
import { loadTranslations } from "@lib";
import { useMutation } from "@tanstack/react-query";
import { Application } from "@typings";

interface Props {
  application: Application;
}

export const ApplicationShare: FC<Props> = ({ application }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState<Array<string>>([]);

  const shareApplicationMutation = useMutation<void, AxiosError, ShareApplicationParams>({
    mutationFn: (params) => ApplicationApi.shareApplication(params),

    onSuccess: () => {
      toast.success(t("share_success"));
      setValues([]);
    },

    onError: () => {
      toast.error(t("share_error"));
    },
  });

  const handleConfirm = useCallback(() => {
    setModalOpen(false);

    const payload: ShareApplicationParams = {
      application_id: application.id,
      body: {
        emails: values,
      },
    };

    shareApplicationMutation.mutate(payload);
  }, [application.id, shareApplicationMutation, values]);

  return (
    <Box>
      <Button
        sx={{
          padding: "8px",
        }}
        onClick={() => {
          setModalOpen(true);
        }}
        color="secondary"
        variant="outlined"
        id="share_candidate_file"
      >
        <IosShare
          sx={{
            width: "24px",
            height: "24px",
            marginRight: "2.5px",
          }}
        />
        {t("share")}
      </Button>
      <Modal
        isOpen={modalOpen}
        modalTitle={t("share_profile")}
        modalIcon={<MailOutline />}
        cancelText={t("cancel")}
        confirmText={t("send")}
        onClose={() => {
          setModalOpen(false);
        }}
        onConfirm={handleConfirm}
        options={{ confirmProps: { disabled: values.length === 0 } }}
      >
        <ThemeProvider theme={theme}>
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                color: "var(--text-color-low-info)",
              }}
            >
              {t("share_info")}
            </Typography>
            <ReactMultiEmail
              style={{
                paddingBottom: "8px",
              }}
              placeholder={t("email_example")}
              emails={values}
              onChange={(emails: string[]) => {
                setValues(emails);
              }}
              getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </Box>
        </ThemeProvider>
      </Modal>
    </Box>
  );
};
