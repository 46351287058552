import dynamic from "next/dynamic";

import { FC, SyntheticEvent, useCallback, useState } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Box, Button } from "@mui/material";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Application, CommentKind } from "@typings";

const ReactQuill = dynamic(
  () => {
    return import("react-quill");
  },
  { ssr: false }
);

type AddCommentTextAreaProps = {
  application: Application;
};

export const AddCommentTextArea: FC<AddCommentTextAreaProps> = ({ application }) => {
  const { t } = useTranslation(["comment-list", "dates"]);
  loadTranslations("comment-list");
  loadTranslations("dates");

  const [comment, setComment] = useState("");

  const queryClient = useQueryClient();

  const commentMutation = useMutation({
    mutationFn: ApplicationApi.createComment,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, application.id],
        })
        .catch(() => {});
      toast.success(t("comment_created"));
      setComment("");
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const createComment = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const content =
        comment.trim().replace(/(<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>)|(<img\b.*>)/gi, "") || "";
      const application_id = application.id;
      const createComment = {
        application_id,
        body: {
          content: content,
          is_bulk_action: false,
          kind: CommentKind.COMMENT,
        },
      };

      commentMutation.mutate(createComment);
    },
    [application.id, comment, commentMutation]
  );

  return (
    <Box
      sx={{
        backgroundColor: "white",
        marginTop: "15px",
        height: "200px",
        marginBottom: "90px",
      }}
    >
      <ReactQuill
        style={{ backgroundColor: "white", height: "100%" }}
        className="--body-copy"
        theme="snow"
        value={comment}
        onChange={setComment}
        modules={{
          toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
        }}
      />

      <Button
        aria-label="créer un commentaire"
        sx={(theme) => ({
          marginTop: "3.5rem",
          padding: "6px 18px",

          float: "right",

          color: theme.palette.background.paper,
          background: theme.palette.primary[800],

          minWidth: "fit-content",
          width: "48px",
          height: "2rem",

          fontSize: "13px",
          textTransform: "none",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.primary[900],
          },
          "&:disabled": {
            backgroundColor: "var(--color-palette-base-100)",
          },
        })}
        onClick={createComment}
        disabled={comment.trim().length === 0}
        id="save_comment"
      >
        {t("save")}
      </Button>
    </Box>
  );
};
