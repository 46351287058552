type MenuOption = {
  name: string;
  icon: string;
  url: string;
  subItems?: MenuOption[];
};

const MENU_OPTIONS: MenuOption[] = [
  {
    name: "home_page_title",
    icon: "🏠",
    url: "/",
  },
  /*{
    name: "settings",
    icon: "⚙️",
    url: "#",
    subItems: [
      {
        name: "user_management",
        icon: "",
        url: "/organizations/members",
      },
    ],
  },*/
  {
    name: "Sourcing",
    icon: "🚀",
    url: "#",
    subItems: [
      {
        name: "sourcing_request_title",
        icon: "",
        url: "/sourcing-campaign",
      },
      {
        name: "start_sourcing_head",
        icon: "",
        url: "/",
      },
    ],
  },
];

export type MenuItemType = {
  name: string;
  icon: string;
  url: string;
  id: string;
  depth: number;
  subItems?: MenuItemType[];
};

function makeMenuLevel(options: MenuOption[], depth = 0): MenuItemType[] {
  return options.map((option, idx) => ({
    ...option,
    id: depth === 0 ? idx.toString() : `${depth}.${idx}`,
    depth,
    subItems: option.subItems && option.subItems.length > 0 ? makeMenuLevel(option.subItems, depth + 1) : undefined,
  }));
}

export const MENU_ITEMS: MenuItemType[] = makeMenuLevel(MENU_OPTIONS);
