import { FC, memo, useCallback, useMemo, useState } from "react";

import { isEqual } from "lodash";
import { MRT_Header, MRT_TableInstance } from "material-react-table";
import moment from "moment";
import { useTranslation } from "next-i18next";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { loadTranslations } from "@lib";
import { Row } from "@tanstack/react-table";
import { ApplicationWithLocation } from "@typings";

enum DateFilterType {
  EXACT = "exact", // the exact day
  BEFORE = "before", // more than x days
  AFTER = "after", // last x days
}

export type FilterValue = {
  type: DateFilterType;
  days: number;
};

type FilterValueOption = {
  label: string;
  value: FilterValue;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: MRT_TableInstance<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  header: MRT_Header<any>;
  value?: FilterValue | null;
  onChange: (value?: FilterValue | null) => void;
};

const RADIO_OPTIONS: Array<FilterValueOption> = [
  { label: "today", value: { type: DateFilterType.EXACT, days: 0 } },
  { label: "days", value: { type: DateFilterType.BEFORE, days: 7 } },
  { label: "days", value: { type: DateFilterType.BEFORE, days: 15 } },
  { label: "days", value: { type: DateFilterType.BEFORE, days: 30 } },
  { label: "days", value: { type: DateFilterType.BEFORE, days: 60 } },
];

const LastInteractionDateFilterComponent: FC<Props> = ({ table, header, value, onChange }) => {
  const { t } = useTranslation(["last-interaction-date-filter"]);
  loadTranslations("last-interaction-date-filter");

  const {
    options: { localization },
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const [open, setOpen] = useState(false);
  const [selectedRadioOption, setSelectedRadioOption] = useState<FilterValue | null>(value || null);

  const filterPlaceholder = localization.filterByColumn?.replace("{column}", String(columnDef.header)) ?? "";
  const handleFilter = useCallback(() => {
    onChange(selectedRadioOption);
    setOpen(false);
  }, [selectedRadioOption, onChange]);

  const selectedOptionLabel = useMemo(() => {
    const option = RADIO_OPTIONS.find((el) => isEqual(el.value, value));
    if (option) {
      return t(option.label, { count: option.value.days });
    }
    return "";
  }, [t, value]);

  return (
    <>
      <FormControl sx={{ m: "0.5rem 0" }}>
        <TextField
          size="small"
          margin="none"
          placeholder={filterPlaceholder}
          InputProps={{
            readOnly: true,

            endAdornment: (
              <InputAdornment position="end">
                <Tooltip arrow placement="right" title={localization.clearFilter ?? ""}>
                  <IconButton
                    disabled={!value?.toString()?.length}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRadioOption(null);
                      onChange(null);
                    }}
                    size="small"
                    sx={{
                      height: "1.75rem",
                      width: "1.75rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            sx: {
              fontSize: "0.8rem",
            },
          }}
          value={selectedOptionLabel}
          onClick={() => setOpen(true)}
          fullWidth
          sx={() => ({
            p: 0,
            minWidth: "auto",
            width: "calc(100% + 4px)",
            mx: "-2px",
          })}
        />
      </FormControl>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              color={(theme) => theme.palette.background.darker}
              fontWeight={"bold"}
              variant="subHeadline"
              marginRight={"1rem"}
            >
              {t("title", { ns: "last-interaction-date-filter" })}
            </Typography>
            <CloseIcon onClick={() => setOpen(false)} color="disabled" style={{ cursor: "pointer" }} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl>
            <RadioGroup
              value={selectedRadioOption}
              onChange={(_, value) => {
                setSelectedRadioOption(JSON.parse(value) as FilterValue);
              }}
            >
              {RADIO_OPTIONS.map((option) => (
                <FormControlLabel
                  sx={{ display: "flex", alignItems: "center", mb: "10px", fontSize: "0.875rem" }}
                  checked={isEqual(option.value, selectedRadioOption)}
                  key={`radio_option_${JSON.stringify(option.value)}`}
                  value={JSON.stringify(option.value)}
                  control={<Radio />}
                  label={
                    <Typography sx={{ fontSize: "0.875rem" }}>
                      {t(option.label, { count: option.value.days })}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>

          <Button variant="contained" onClick={() => handleFilter()}>
            {t("filter")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const LastInteractionDateFilter = memo(LastInteractionDateFilterComponent);

export const LastInteractionDateFilterFn = (
  row: Row<ApplicationWithLocation>,
  id: string,
  filterValue: FilterValue
) => {
  if (!filterValue) return true;

  const diff = moment().diff(moment(row.getValue(id)), "day");

  switch (filterValue.type) {
    case DateFilterType.EXACT:
      return diff === filterValue.days;
    case DateFilterType.BEFORE:
      return diff >= filterValue.days;
    case DateFilterType.AFTER:
      return diff <= filterValue.days;
    default:
      return true;
  }
};
