import { AxiosError } from "axios";

import { SourcingCampaignApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import { AtsIntegration } from "@typings";

export const useFetchAtsIntegrationByCampaignID = ({
  campaign_id,
  options,
}: {
  campaign_id: string;
  options?: Omit<UseQueryOptions<AtsIntegration, AxiosError>, "queryKey" | "queryFn">;
}): UseQueryResult<AtsIntegration, AxiosError> => {
  return useQuery({
    queryKey: [QUERY_KEYS.ATS_INTEGRATIONS, campaign_id],
    queryFn: () => SourcingCampaignApi.getAtsIntegration(campaign_id),
    ...options,
  });
};
