import {
  CreateOrganizationRequestFormLinkParams,
  DeleteOrganizationRequestFormLinkParams,
  OrganizationRequestFormLink,
  ReadOrganizationRequestFormLinkParams,
  UpdateOrganizationRequestFormLinkParams,
} from "@typings";

import { organizationsAPI } from "./api";

const create = async (params: CreateOrganizationRequestFormLinkParams): Promise<OrganizationRequestFormLink> => {
  const { data } = await organizationsAPI.post(`/${params.organization_id}/request_form_link`, {
    link: params.request_form_link,
  });
  return data;
};

const get = async (params: ReadOrganizationRequestFormLinkParams): Promise<OrganizationRequestFormLink | undefined> => {
  try {
    const { data } = await organizationsAPI.get(`/${params.organization_id}/request_form_link`);
    return data;
  } catch (e) {
    if (e?.response?.status === 404) {
      return;
    }

    throw e;
  }
};

const update = async (params: UpdateOrganizationRequestFormLinkParams): Promise<OrganizationRequestFormLink> => {
  const { data } = await organizationsAPI.put(`/${params.organization_id}/request_form_link`, {
    link: params.request_form_link,
  });
  return data;
};

const destroy = async (params: DeleteOrganizationRequestFormLinkParams): Promise<void> => {
  await organizationsAPI.delete(`/${params.organization_id}/request_form_link`);
  return;
};

export const OrganizationRequestFormLinkAPI = {
  create,
  get,
  update,
  destroy,
};
