import { ReactNode } from "react";

import { Card } from "./styles";

type CardFormProps = {
  children: ReactNode;
  style?: object;
  alignCenter?: boolean;
};

export function CardForm({ children, style, alignCenter }: CardFormProps) {
  if (alignCenter === undefined) {
    alignCenter = true;
  }
  return (
    <Card style={style} alignCenter={alignCenter}>
      {children}
    </Card>
  );
}
