import { Dispatch, FC, SetStateAction } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { PersonRemove as PersonRemoveIcon } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi, InterviewApi } from "@api";
import { Form } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CommentKind, Interview } from "@typings";

import { yupResolver } from "@hookform/resolvers/yup";

const DEFAULT_VALUES: { comment: string } = {
  comment: "",
};

type DeleteInterviewModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;

  interview: Interview;
};

export const DeleteInterviewModal: FC<DeleteInterviewModalProps> = ({
  open,
  setOpen,

  interview,
}) => {
  const { t } = useTranslation(["delete-interview-modal"]);
  loadTranslations("delete-interview-modal");

  const theme = useTheme();

  const interviewForm = useForm({
    shouldUnregister: false,
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        comment: yup.string().required(t("comment_required")).min(1),
      })
    ),
  });

  const queryClient = useQueryClient();
  const interviewMutation = useMutation({
    mutationFn: InterviewApi.deleteInterview,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.INTERVIEW, interview.application_id],
      });
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.INTERVIEW, interview.application_id],
        })
        .catch(() => {});
    },
  });

  const commentMutation = useMutation({
    mutationFn: ApplicationApi.createComment,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, interview.application_id],
        })
        .catch(() => {});
    },
  });

  const {
    reset,
    control,
    clearErrors,
    formState: { errors, isValid },
    trigger,
    getValues,
  } = interviewForm;

  const onSubmit = (data: { comment: string }) => {
    interviewMutation.mutate(interview.id);

    if (data.comment) {
      const createComment = {
        application_id: interview.application_id,
        body: {
          content: data.comment,
          is_bulk_action: false,
          kind: CommentKind.INTERVIEW_CANCELLED,
        },
      };

      commentMutation.mutate(createComment);
    }

    toast.success(t("interview_deleted"));

    onCancel();
  };

  const onCancel = () => {
    reset(DEFAULT_VALUES);
    setOpen(false);
    clearErrors();
  };

  return (
    <Modal
      isOpen={open}
      aria-label="application-interview-modal-delete"
      aria-describedby="application-interview-modal-delete"
      modalTitle={t("delete_modal_title")}
      title={t("delete_title")}
      cancelText={t("cancel")}
      onClose={onCancel}
      options={{
        confirmProps: { variant: "danger", disabled: !isValid },
        maxWidth: "650px",
      }}
      confirmText={t("submit")}
      onConfirm={() => {
        trigger().then((isValid) => {
          if (isValid) {
            onSubmit(getValues());
          }
        });
      }}
      modalIcon={<PersonRemoveIcon />}
    >
      <ThemeProvider theme={theme}>
        <Form methods={interviewForm} submitHandler={onSubmit}>
          <Grid
            container
            sx={(theme) => ({
              marginTop: "1rem",
              padding: "1.5rem 2rem",
              backgroundColor: theme.palette.background.default,
            })}
          >
            <Grid item xs={12}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontSize: "24px",
                  fontWeight: 600,
                })}
              >
                {moment(interview.date).format("ll")}
                {" - "}
                {moment(interview.time_start, "HH:mm").format("LT")}
                {" - "}
                {moment(interview.time_end, "HH:mm").format("LT")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0.5rem" }}>
              {interview.location}
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingTop: "1rem",
            }}
          >
            <Controller
              name={"comment"}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.comment}>
                  <InputLabel shrink htmlFor={"comment"} sx={{ display: "list-item" }} required>
                    {t("comment_label")}
                  </InputLabel>

                  <TextField
                    inputProps={{ "aria-label": "comment-to-delete-interview" }}
                    {...field}
                    multiline
                    rows={4}
                    placeholder={t("comment_placeholder")}
                  />

                  {errors.comment && <FormHelperText>{errors.comment?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid
            container
            sx={{
              paddingTop: "1rem",
            }}
          >
            <PersonRemoveIcon
              sx={{
                position: "relative",
                bottom: "5px",
                float: "left",
                width: "2rem",
                height: "2rem",
                paddingRight: "0.5rem",
                fontWeight: 550,
              }}
            />

            {t("reminders_help_text")}
          </Grid>
        </Form>
      </ThemeProvider>
    </Modal>
  );
};
