import { TFunction } from "next-i18next";
import * as yup from "yup";

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    first_name: yup.string().required(t("first_name_required")),
    last_name: yup.string().required(t("last_name_required")),
    organization_name: yup.string().required(t("organization_name_required")),
    email: yup.string().email(t("email_invalid")).required(t("email_required")),
  });
