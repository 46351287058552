import axios from "axios";
import moment from "moment";
import { Session } from "next-auth";
import { getSession } from "next-auth/react";

// to the request headers
let currentSession: Session | null = null;

// getCachedSession is a small util function working with `currentSession`
// to store the session in a var and re-use it until it expires because we are
// using the session for every data request we make, and it was getting slow.
export const getCachedSession = async () => {
  if (currentSession === null) {
    currentSession = await getSession();
  } else {
    const now = moment();
    const pastDate = moment.utc(currentSession.accessTokenExpired);

    // negative when in the future, **positive when in the past**
    const dDiff = now.diff(pastDate);
    if (dDiff > 0) {
      currentSession = await getSession();
    }
  }

  return Promise.resolve(currentSession);
};

// to send the X-JWT access token in headers
const jwtInterceptor = async (config) => {
  const session = await getCachedSession();

  if (session) {
    // Put the current headers after, so it allows manual override of the default headers.
    config.headers = Object.assign(
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.accessToken}`,
        //"ACCEPT-LANGUAGE": session?.user?.locale || "fr",
      },
      config.headers
    );
  }
  return config;
};

const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const applicationsAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APPLICATIONS_BASE_URL,
  headers: jsonHeaders,
});
applicationsAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const jobsAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_JOBS_BASE_URL,
  headers: jsonHeaders,
});
jobsAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const usersAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_USERS_BASE_URL,
  headers: jsonHeaders,
});
usersAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const scoringAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SCORING_BASE_URL,
  headers: jsonHeaders,
});
scoringAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const candidatesAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CANDIDATES_BASE_URL,
  headers: jsonHeaders,
});
candidatesAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const sourcingCampaignsAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SOURCING_CAMPAIGN_BASE_URL,
  headers: jsonHeaders,
});
sourcingCampaignsAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const authProxyAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_PROXY_BASE_URL,
  headers: jsonHeaders,
});
authProxyAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const messagingAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MESSAGING_BASE_URL,
  headers: jsonHeaders,
});
messagingAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const organizationsAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ORGANIZATIONS_BASE_URL,
  headers: jsonHeaders,
});
organizationsAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const atsAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ATS_BASE_URL,
  headers: jsonHeaders,
});
atsAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const interviewAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_INTERVIEW_BASE_URL,
  headers: jsonHeaders,
});
interviewAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

const clickToCallAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLICK_TO_CALL_BASE_URL,
  headers: jsonHeaders,
});
clickToCallAPI.interceptors.request.use(jwtInterceptor, Promise.reject);

export {
  applicationsAPI,
  atsAPI,
  authProxyAPI,
  candidatesAPI,
  clickToCallAPI,
  interviewAPI,
  jobsAPI,
  messagingAPI,
  organizationsAPI,
  scoringAPI,
  sourcingCampaignsAPI,
  usersAPI,
};
