import { useCallback, useEffect, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { OrganizationRequestFormLinkAPI, OrganizationsAPI, UpdateOrganizationParams } from "@api";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { MutationFunction, useMutation } from "@tanstack/react-query";
import {
  CreateOrganizationRequestFormLinkParams,
  MutateOrganizationRequestFormLinkParams,
  OrganizationRequestFormLink,
  UpdateOrganizationRequestFormLinkParams,
} from "@typings";
import { checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";
import { CurrentUserOrganizationHook, useOrganizationRequestFormLink } from "@hooks/queries";

export const useOrganizationPhoneUpdater = (
  organization: CurrentUserOrganizationHook,
  accessToken: string | undefined
) => {
  const { t } = useTranslation(["organization-form"]);
  loadTranslations("organization-form");

  const userPermissions = useUserPermissions(accessToken);
  const canEditPhone = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.OrganizationsPermissions.Write]),
    [userPermissions]
  );

  const updateOrganizationInfoMutation = useMutation<void, AxiosError, UpdateOrganizationParams>({
    mutationFn: (params) => OrganizationsAPI.update(params),

    onSuccess: async () => {
      toast.success(t("organization_form_phone_updated"));
      await organization.refetch();
    },

    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const updatePhoneNumber = useCallback(
    ({ organization_phone }: { organization_phone: string }) => {
      updateOrganizationInfoMutation.mutate({ body: { phone: organization_phone } });
    },
    [updateOrganizationInfoMutation]
  );

  return {
    canEditPhone,
    submit: updatePhoneNumber,
    isUpdating: updateOrganizationInfoMutation.isPending,
    defaultValue: organization.data?.phone || "",
  };
};

export const useRequestFormLink = (organization: CurrentUserOrganizationHook, accessToken: string | undefined) => {
  const { t } = useTranslation(["organization-form"]);
  loadTranslations("organization-form");

  const [editRequestFormLink, setEditRequestFormLink] = useState(false);
  const userPermissions = useUserPermissions(accessToken);
  const canEditRequestFormLink = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.OrganizationRequestFormLinkPermissions.Write]),
    [userPermissions]
  );
  const requestFormLink = useOrganizationRequestFormLink(organization.data?.group_id, !canEditRequestFormLink);

  const organizationRequestFormLinkForm = useForm({
    shouldUnregister: false,
    defaultValues: {
      request_form_link: "",
    },
    mode: "onChange",
  });

  const mutateRequestFormLink = useCallback<
    MutationFunction<OrganizationRequestFormLink | void, MutateOrganizationRequestFormLinkParams>
  >(
    async (params: MutateOrganizationRequestFormLinkParams): Promise<OrganizationRequestFormLink | void> => {
      // A link was already set.
      if (requestFormLink.data?.link) {
        // We delete this link.
        if (params.request_form_link == null || params.request_form_link === "") {
          await OrganizationRequestFormLinkAPI.destroy(params);
          return;
        }

        // We update this link.
        return await OrganizationRequestFormLinkAPI.update(params as UpdateOrganizationRequestFormLinkParams);
      }

      // No link was set, we create a new one.
      if (params.request_form_link == null) return;

      return await OrganizationRequestFormLinkAPI.create(params as CreateOrganizationRequestFormLinkParams);
    },
    [requestFormLink.data?.link]
  );

  const updateOrganizationRequestFormLinkMutation = useMutation<
    OrganizationRequestFormLink | void,
    AxiosError,
    MutateOrganizationRequestFormLinkParams
  >({
    mutationFn: mutateRequestFormLink,
    onSuccess: (data) => {
      toast.success(t("organization_request_form_link_updated"));
      organizationRequestFormLinkForm.setValue("request_form_link", data?.link || "");
      setEditRequestFormLink(false);
      requestFormLink.refetch();
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const submitOrganizationRequestFormLink = useCallback(
    ({ request_form_link }: { request_form_link: string }) => {
      if (organization.data?.group_id == null) return;

      updateOrganizationRequestFormLinkMutation.mutate({
        organization_id: organization.data.group_id,
        request_form_link,
      });
    },
    [organization.data?.group_id, organizationRequestFormLinkForm, updateOrganizationRequestFormLinkMutation]
  );

  useEffect(() => {
    organizationRequestFormLinkForm.setValue("request_form_link", requestFormLink.data?.link || "");
  }, [organizationRequestFormLinkForm, requestFormLink.data?.link]);

  return {
    edit: editRequestFormLink,
    setEdit: setEditRequestFormLink,
    canEdit: canEditRequestFormLink,
    form: organizationRequestFormLinkForm,
    submit: submitOrganizationRequestFormLink,
    isUpdating: updateOrganizationRequestFormLinkMutation.isPending,
  };
};
