import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";

import { FC, ReactNode, useMemo } from "react";

import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import {
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  Message as MessageIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Link,
  List,
  Drawer as MuiDrawer,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { ActiveLink, ActiveMenuButton, ActiveMenuButtonProps, MenuItem } from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";

import { Content, PageContainer } from "./styles";

type DashboardLayoutProps = {
  title?: string;
  children: ReactNode;
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(() => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowX: "hidden",
  width: "74px",
  "& .MuiDrawer-paper": {
    borderWidth: "0",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    overflowX: "hidden",
    width: "74px",
  },
}));

interface CustomNavLinkProps {
  iconSrc: string;
  iconAlt: string;
  tooltip: string;
  href: string;
}

const CustomNavLink: FC<CustomNavLinkProps> = ({ iconSrc, iconAlt, href, tooltip }) => {
  const theme = useTheme();
  const router = useRouter();

  const isActive = router.asPath === href;

  return (
    <MenuItem active={isActive}>
      <ActiveLink active={isActive} href={href}>
        <Tooltip
          title={
            <Typography
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                fontSize: "1rem",
              }}
            >
              {tooltip}{" "}
            </Typography>
          }
          placement="right-end"
        >
          <Image
            src={iconSrc}
            alt={iconAlt}
            style={{ marginTop: "0.5rem" }}
            className="menu-item-icon"
            color="primary"
            width={30}
            height={30}
          />
        </Tooltip>
      </ActiveLink>
    </MenuItem>
  );
};

interface CustomNavButtonProps extends Omit<ActiveMenuButtonProps, "active"> {
  tooltip: string;
  iconSrc: string;
  iconAlt: string;
}

const CustomNavButton: FC<CustomNavButtonProps> = ({ tooltip, iconSrc, iconAlt, items, ...props }) => {
  const theme = useTheme();
  const { asPath } = useRouter();

  const isActive = useMemo(() => {
    const item = items.find((item) => item.type === "link" && item.link === asPath);
    return !!item;
  }, [asPath, items]);

  return (
    <MenuItem active={isActive}>
      <ActiveMenuButton items={items} active={isActive} {...props}>
        <Tooltip
          title={<Typography sx={{ color: "black", fontSize: "1rem" }}>{tooltip || ""} </Typography>}
          placement="right-end"
        >
          <Image src={iconSrc} alt={iconAlt} width={30} height={30} style={{ color: theme.palette.primary[900] }} />
        </Tooltip>
        <Divider />
      </ActiveMenuButton>
      <Divider />
    </MenuItem>
  );
};

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const theme = useTheme();

  const { t } = useTranslation(["header", "common", "support"]);
  loadTranslations("header");
  loadTranslations("common");
  loadTranslations("support");

  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const canCreateCampaign = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.SourcingCampaignsPermissions.Write]),
    [userPermissions]
  );

  const signout = async () => {
    const idToken = session?.idToken;
    idToken && (await signOut({ callbackUrl: `/api/auth/logout?id_token=${idToken}` }));
  };

  const mail_href = `mailto:${t("support:mailto")}?subject=${encodeURIComponent(
    `${t("support:message")} - [ ${String(session?.user.groups[0])} ]  - ${t("support:subject")}`
  )}`;

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" anchor="left">
        <Box sx={{ display: "flex", alignItem: "center", justifyContent: "center", marginBottom: "2.5rem" }}>
          <NextLink href="/" passHref>
            <Image src="/assets/logo-seiza.png" alt="logo" width="41" height="40" />
          </NextLink>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <List>
            {canCreateCampaign && (
              <CustomNavLink
                iconSrc="/assets/create-campaign-icon.png"
                iconAlt="create-campaign"
                tooltip={t("common:add")}
                href="/sourcing-campaign"
              />
            )}
            <CustomNavLink
              iconSrc="/assets/campaign-page-logo.png"
              iconAlt="campaign-page"
              tooltip={t("common:list")}
              href="/"
            />
          </List>

          <List>
            <CustomNavButton
              tooltip={t("support:help_title")}
              iconSrc="/assets/help-icon.png"
              iconAlt="help"
              items={[
                {
                  label: t("support:contact_customer"),
                  type: "link",
                  link: mail_href,
                  action: () => {},
                  icon: MessageIcon,
                },
              ]}
              header={
                <Box display="flex" alignItems="center" sx={{ paddingBottom: "1rem" }}>
                  <Box sx={{ paddingRight: "4px" }}></Box>
                  <Box>
                    <Typography
                      sx={(theme) => ({
                        fontSize: "15px",
                        fontWeight: "bold",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: theme.palette.primary[900],
                      })}
                    >
                      {t("support:help_title")}
                    </Typography>
                  </Box>
                </Box>
              }
              footer={
                <>
                  <Box component="span" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Link href={t("support:privacy_policy_link")} sx={{ textDecoration: "none" }}>
                      <Typography
                        sx={(theme) => ({
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          textAlign: "left",
                          cursor: "pointer",
                          color: theme.palette.primary[900],
                        })}
                      >
                        {t("support:privacy_policy")}
                      </Typography>
                    </Link>
                    <Link href={t("support:terms_of_use_link")} sx={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          textAlign: "left",
                          cursor: "pointer",
                          color: theme.palette.primary[900],
                        }}
                      >
                        {t("support:terms_of_use")}
                      </Typography>
                    </Link>
                  </Box>
                </>
              }
            />
            <CustomNavButton
              tooltip={t("common:profil")}
              iconSrc="/assets/profile-icon.png"
              iconAlt="profile"
              items={[
                { label: t("profile"), type: "link", link: "/my-profile", action: () => {}, icon: PersonIcon },
                {
                  label: t("organization_management"),
                  type: "link",
                  link: "/settings",
                  action: () => {},
                  icon: SettingsIcon,
                },
                { label: t("logout"), type: "button", link: "", action: () => signout(), icon: LogoutIcon },
              ]}
              header={
                <Box display="flex" alignItems="center" sx={{ paddingBottom: "1rem" }}>
                  <Box sx={{ paddingRight: "4px" }}>
                    <AccountCircleIcon sx={{ color: theme.palette.primary[900] }} />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "15px", fontWeight: "400", lineHeight: "20px", textAlign: "left" }}>
                      {session?.user.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "1rem",
                        textAlign: "left",
                        color: "#7F89A3",
                      }}
                    >
                      {session?.user.groups[0]}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </List>
        </Box>
      </Drawer>
      <Container maxWidth={false} sx={{ flexGrow: 1, minWidth: 0 }}>
        <Content>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <PageContainer>{children}</PageContainer>
          </div>
        </Content>
      </Container>
    </Box>
  );
}
