import { scoringAPI } from "./api";

// check if a campaign has a scoring engine set up
const hasEngine = async (campaignID: string): Promise<boolean> => {
  const res = await scoringAPI.get(`/${campaignID}/engine/exists`).catch((err) => {
    // Catch 404 errors.
    if (err.response.status === 404) {
      return { status: 404 };
    }
    throw err;
  });

  return res.status === 204;
};

// retrieve the score of an application
const getScore = async (applicationID: string): Promise<number> => {
  const { data } = await scoringAPI.get(`/${applicationID}/score`);
  return data?.score;
};

// return a map of application_id: score for the campaign.
const getAllScores = async (campaignID: string): Promise<Record<string, number>> => {
  const { data } = await scoringAPI.get(`/${campaignID}/applications_score`);
  return data;
};

// recomputes the score of an application
const recomputeScore = async (applicationID: string): Promise<void> => {
  await scoringAPI.post(`/${applicationID}/score`, {});
};

export const ScoringAPI = {
  hasEngine,
  getAllScores,
  getScore,
  recomputeScore,
};
