import { FC, useState } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm, useWatch } from "react-hook-form";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";

import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

import { ChangePasswordModal } from "./change-password-modal";

export const ProfileSettings: FC = () => {
  const { t } = useTranslation(["profile-settings", "profile-validation"]);
  loadTranslations("profile-validation");
  loadTranslations("profile-settings");

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const { data: session } = useSession();
  // TODO: update when https://github.com/Work4Labs/platform-ui/pull/264 is merged
  const role = hasRole(session, Roles.ADMIN) ? t(Roles.ADMIN) : t(Roles.RECRUITER);

  const { control } = useForm({
    shouldUnregister: false,
    defaultValues: {
      role: role,
    },
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography
            variant="h3"
            sx={(theme) => ({
              color: theme.palette.primary[900],
              fontSize: "20px",
              fontStyle: "normal",
              lineHeight: "1.5rem",
              fontWeight: "600",
            })}
          >
            {t("settings")}
          </Typography>

          <Box sx={{ marginTop: "2rem" }}>
            <InputLabel>{t("role")}</InputLabel>
            <TextField
              placeholder={t("role")}
              fullWidth
              defaultValue={useWatch({ control, name: "role" })}
              disabled
              sx={(theme) => ({
                WebkitTextFillColor: theme.palette.primary[900],
                zIndex: "0",
              })}
            />
          </Box>
          <Box sx={{ marginTop: "2rem" }}>
            <InputLabel>{t("current_password")}</InputLabel>
            <TextField
              placeholder={t("current_password")}
              type="text"
              defaultValue="XXXXXXXXXXXXX"
              fullWidth
              disabled
              sx={(theme) => ({
                WebkitTextFillColor: theme.palette.primary[900],
                zIndex: "0",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setOpenPasswordModal(true)}>
                      <EditIcon sx={(theme) => ({ cursor: "pointer", color: theme.palette.grey[800] })}></EditIcon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <ChangePasswordModal open={openPasswordModal} setOpen={setOpenPasswordModal}></ChangePasswordModal>
    </Box>
  );
};
