import { AxiosResponse } from "axios";

import {
  Application,
  ApplicationStatusReasonSource,
  ApplicationStatusReasons,
  ApplicationsReport,
  CommentKind,
  DocumentsLogs,
  Job,
  Question,
  Status,
} from "@typings";

import { applicationsAPI, jobsAPI } from "./api";
import { downloadFileFromResponse } from "./utils";

// a small cache to avoid querying the same job too many times. It will reset every time the page loads.
// the goal here is to load the jobs info into the application.
const jobsCache: Record<string, Promise<AxiosResponse<Job>>> = {};

// for one application, query the job's info and add them to the application
async function addJobInfo(application: Application): Promise<Application> {
  // query if we've never queried yet or if we are in tests (to simply mocks)
  if (jobsCache[application.job_id] === undefined || process.env.IN_TEST === "true") {
    jobsCache[application.job_id] = jobsAPI.get(`/${application.job_id}`);
  }

  const { data: jobsData } = await jobsCache[application.job_id];
  application.job_title = jobsData.title;
  application.city = jobsData.city;
  application.country_code = jobsData.country_code;
  application.state = jobsData.location_state;
  application.postal_code = jobsData.postal_code;
  application.region = jobsData.region;

  return Promise.resolve(application);
}

interface ListApplicationsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const list = async (params: ListApplicationsQueryParams): Promise<Array<Application>> => {
  const { data } = await applicationsAPI.get<Array<Application>>("", { params });

  const applicationWithJobs = await Promise.allSettled(data.map((application) => addJobInfo(application)));

  return (
    applicationWithJobs
      // filter the successes, which make them PromiseFulfilledResult. We ignore the fails for now.
      .filter((result) => result.status === "fulfilled")
      .map((result: PromiseFulfilledResult<Application>) => result.value)
  );
};

const get = async (id: string): Promise<Application> => {
  const { data: applicationData } = await applicationsAPI.get<Application>(`/${id}`);
  return addJobInfo(applicationData);
};

const deleteApplication = async (id: string): Promise<void> => {
  await applicationsAPI.delete(`/${id}`);
  return;
};

const listStatuses = async (): Promise<Array<Status>> => {
  const { data } = await applicationsAPI.get("/statuses");
  return data;
};

export interface UpdateApplicationStatusParams {
  application_id: string;
  body: {
    id: string; // status id
    is_bulk_action: boolean;
    send_sms: boolean;
  };
}
export interface UpdateApplicationStatusResponse {
  id: string;
}
const updateApplicationStatus = async (
  params: UpdateApplicationStatusParams
): Promise<UpdateApplicationStatusResponse> => {
  const { data } = await applicationsAPI.post(`/${params.application_id}/status`, params.body);
  return data;
};

export type ApplicationStatusReason = {
  source: ApplicationStatusReasonSource | "";
  reason: ApplicationStatusReasons[keyof ApplicationStatusReasons];
  comment: string;
};

export type CreateApplicationStatusReasonParams = {
  application_id: string;
  status_id: string;
  body: ApplicationStatusReason;
};

const createApplicationStatusReason = async ({
  status_id,
  application_id,
  body,
}: CreateApplicationStatusReasonParams): Promise<void> => {
  const { data } = await applicationsAPI.post(`/${application_id}/status/${status_id}/reason`, body);
  return data;
};

export interface GetApplicationStatusReasonParams {
  application_id: string;
  status_id: string;
}
const getApplicationStatusReason = async (
  params: GetApplicationStatusReasonParams
): Promise<ApplicationStatusReason> => {
  const { data } = await applicationsAPI.get(`/${params.application_id}/status/${params.status_id}/reason`);
  return data;
};

interface CreateApplicationCommentParams {
  application_id: string;
  body: {
    content: string; // the content of the comment
    is_bulk_action: boolean;
    kind: CommentKind;
  };
}
const createComment = async (params: CreateApplicationCommentParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/comments`, params.body);
};

interface GetReportingQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const getReporting = async (params: GetReportingQueryParams): Promise<ApplicationsReport> => {
  const { data } = await applicationsAPI.get(`/reporting`, { params });
  return data;
};

interface ListQuestionsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const listQuestions = async (params: ListQuestionsQueryParams): Promise<Array<Question>> => {
  const { data } = await applicationsAPI.get("/questions", { params });
  return data;
};

interface GetResumeQueryParams {
  application_id: string;
  lang: string;
}
const getResume = async (params: GetResumeQueryParams): Promise<void> => {
  const res = await applicationsAPI.get(`/${params.application_id}/resume?lang=${params.lang}`, {
    headers: {
      Accept: ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
      "Content-Type": ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
    },
    responseType: "blob",
  });

  downloadFileFromResponse(res, { defaultFilename: "resume.pdf" });
};

export interface ShareApplicationParams {
  application_id: string;
  body: {
    emails: Array<string>;
  };
}

const shareApplication = async (params: ShareApplicationParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/share`, params.body);
};

interface RequestDocumentsParams {
  application_id: string;
  body: {
    document_type: string;
  };
}

const requestDocuments = async (params: RequestDocumentsParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/documents/request`, params.body);
};

const listDocuments = async (applicationID: string): Promise<Array<string>> => {
  const { data } = await applicationsAPI.get(`/${applicationID}/documents/`);
  return data;
};

const listDocumentsLogs = async (applicationID: string): Promise<Array<DocumentsLogs>> => {
  const { data } = await applicationsAPI.get(`/${applicationID}/documents/logs`);
  return data;
};

export const ApplicationApi = {
  get,
  list,
  deleteApplication,
  listStatuses,
  updateApplicationStatus,
  createComment,
  getReporting,
  listQuestions,
  getResume,
  createApplicationStatusReason,
  getApplicationStatusReason,
  shareApplication,
  requestDocuments,
  listDocuments,
  listDocumentsLogs,
};
