import { ILabelValueItem } from "@typings";

export enum Roles {
  ADMIN = "admin",
  RECRUITER = "recruiter",
  SUPER_ADMIN = "super_admin",
}

export const ROLES_OPTIONS: ILabelValueItem<string, Roles>[] = [
  {
    label: `common:roles.${Roles.ADMIN}`,
    value: Roles.ADMIN,
  },
  {
    label: `common:roles.${Roles.RECRUITER}`,
    value: Roles.RECRUITER,
  },
];
