import { ForwardedRef, HTMLProps, PropsWithoutRef, ReactElement, RefAttributes, forwardRef } from "react";

import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

export type FormProps<TFieldValues extends FieldValues = FieldValues> = HTMLProps<HTMLFormElement> & {
  methods: UseFormReturn<TFieldValues>;
  submitHandler: (data: TFieldValues) => void;
};

const FormComponent = <TFieldValues extends FieldValues = FieldValues>(
  { submitHandler, methods, ...rest }: FormProps<TFieldValues>,
  ref: ForwardedRef<HTMLFormElement>
) => {
  return (
    <FormProvider {...methods}>
      <form
        role="form"
        {...rest}
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={methods.handleSubmit(submitHandler)}
        noValidate
      />
    </FormProvider>
  );
};

export const Form = forwardRef(FormComponent) as <TFieldValues extends FieldValues = FieldValues>(
  props: PropsWithoutRef<FormProps<TFieldValues>> & RefAttributes<HTMLFormElement>
) => ReactElement;
