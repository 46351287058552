import { FC } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Grid } from "@mui/material";

import { BasicTab, ItemTab, PageTitle } from "@components";
import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

import { FeaturesSettings } from "./forms/features";
import { MemberSettings } from "./forms/members";
import { OrganizationSettings } from "./forms/organization-settings";

export const OrganizationForm: FC = () => {
  const { t } = useTranslation(["settings"]);
  loadTranslations("settings");

  const { data: session } = useSession();

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <div
          style={{
            padding: "6px 10px 6px px",
            height: 32,
            marginBottom: 8,
          }}
        >
          <PageTitle>{t("settings:settings_title")}</PageTitle>
        </div>
      </Grid>
      <BasicTab
        style={{
          marginTop: "1.5rem",
        }}
        tabs={{
          [t("settings:settings_info")]: {
            node: <OrganizationSettings />,
          } as ItemTab,
          [t("settings:settings_users")]: {
            node: <MemberSettings />,
          } as ItemTab,
          [t("settings:settings_features")]: {
            node: <FeaturesSettings />,
            hidden: !hasRole(session, Roles.SUPER_ADMIN),
          } as ItemTab,
        }}
      />
    </Grid>
  );
};
