import { authProxyAPI, usersAPI } from "./api";

interface UpdateUserInfoBody {
  first_name: string;
  last_name: string;
  phone: string;
}

const updateUserInfo = async (body: Partial<UpdateUserInfoBody>): Promise<void> => {
  await usersAPI.patch("/me", body);
};

interface UpdateEmailBody {
  password: string;
  email: string;
}

const updateEmail = async (body: UpdateEmailBody): Promise<void> => {
  await authProxyAPI.post("/profile/email", body);
};

interface UpdatePasswordBody {
  old_password: string;
  new_password: string;
  repeat_password: string;
}

const updatePassword = async (body: UpdatePasswordBody): Promise<void> => {
  await authProxyAPI.post("/profile/password", body);
};

export interface inviteUserBody {
  first_name: string;
  last_name: string;
  role: string;
  email: string;
  locale: string;
  group_id: string;
}

export interface inviteUserResult {
  id: string;
}

const sendInvite = async (body: inviteUserBody): Promise<inviteUserResult> => {
  const { data } = await usersAPI.post("", body);
  return data;
};

export interface updateRoleBody {
  id: string;
  body: {
    enabled?: boolean;
    role?: string;
  };
}

const updateRole = async (body: updateRoleBody): Promise<void> => {
  const { data } = await usersAPI.patch(`/${body.id}`, body.body);
  return data;
};

export const userApi = {
  updateUserInfo,
  updateEmail,
  updatePassword,
  sendInvite,
  updateRole,
};
