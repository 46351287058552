import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";

import { Box, Grid, Typography } from "@mui/material";

import { EmailBox } from "@components";
import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { Candidate } from "@typings";
import { parsePhoneNumber } from "@utils";

interface UserInfoProps {
  label: string;
  hide?: boolean;
  children: ReactNode;
}

const UserInfo: FC<UserInfoProps> = ({ label, hide, children }) =>
  hide ? null : (
    <Grid
      container
      spacing={1}
      sx={{
        lineHeight: "1.5rem",
        marginBottom: "5px",
      }}
    >
      <Grid item xs={4}>
        <Box>
          <Typography variant="tagsStrong" color={TEXT_COLOR.mainInfo}>
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sx={{ wordBreak: "break-all" }}>
        {children}
      </Grid>
    </Grid>
  );

type ApplicationUserInfoProps = {
  candidate: Candidate;
};

export const ApplicationUserInfo: FC<ApplicationUserInfoProps> = ({ candidate }) => {
  const { t } = useTranslation(["application-user-info"]);
  loadTranslations("application-user-info");

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }}>
      <UserInfo hide={!candidate.phone} label={t("phone")}>
        <Typography variant="tags" color={TEXT_COLOR.mainInfo}>
          {parsePhoneNumber(candidate.phone)}
        </Typography>
      </UserInfo>
      <UserInfo hide={!candidate.email} label={t("email")}>
        <Typography variant="tags" color={TEXT_COLOR.mainInfo}>
          <EmailBox sx={(theme) => ({ fontSize: "14px", color: theme.palette.grey[800] })}>{candidate.email}</EmailBox>
        </Typography>
      </UserInfo>
    </Grid>
  );
};
