import React, { FC, useCallback, useState } from "react";

import { useSession } from "next-auth/react";
import { TFunction, Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import {
  ArrowDropDown as ArrowDropDownIcon,
  Description as DocumentIcon,
  Update as UpdateIcon,
} from "@mui/icons-material";
import { Box, Button, Chip, Grid, Menu, MenuItem, ThemeProvider, Typography, useTheme } from "@mui/material";

import { InfoBox, Modal, Select } from "@work4Labs/design-system";

import { ApplicationApi, downloadS3File } from "@api";
import { LoadingAnimation } from "@components";
import { BORDER_COLOR, BORDER_RADIUS, COLOR_PALETTE, DOCUMENT_TYPES, QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type ApplicationDocumentsModalProps = {
  open: boolean;

  // a function to close the modal
  closeModal: () => void;

  applicationID: string;
};

const ApplicationDocumentsModal: FC<ApplicationDocumentsModalProps> = ({ open, closeModal, applicationID }) => {
  const { t } = useTranslation(["application-documents"]);
  loadTranslations("application-documents");

  const { data: session } = useSession();

  const [documentType, setDocumentType] = useState<DOCUMENT_TYPES>(DOCUMENT_TYPES.Resume);

  const theme = useTheme();

  const queryClient = useQueryClient();
  const requestDocumentsMutation = useMutation({
    mutationFn: ApplicationApi.requestDocuments,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
        })
        .catch(() => {});

      toast.success(t("request_sent"));
      closeModal();
    },
    onError: () => {
      toast.error(t("request_failed"));
    },
  });

  const onConfirmButton = useCallback(() => {
    requestDocumentsMutation.mutate({
      application_id: applicationID,
      body: {
        document_type: documentType,
      },
    });
    closeModal();
  }, [applicationID, closeModal, requestDocumentsMutation]);

  const modalCustomButtons = useCallback(() => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          variant="outlined"
          onClick={closeModal}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#F2F3F7",
            },
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={onConfirmButton}
          sx={{
            ...buttonStyle,
            marginRight: "0.5rem",
            color: "white",
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          {t("submit_request")}
        </Button>
      </>
    );
  }, [closeModal, onConfirmButton, t, theme]);

  return (
    <Modal
      isOpen={open}
      aria-label="application-documents-modal-request"
      aria-describedby="application-documents-modal-request"
      scroll="body"
      modalTitle={t("modal_title")}
      title={t("title")}
      modalIcon={<UpdateIcon />}
      onClose={closeModal}
      onConfirm={onConfirmButton}
      customActions={modalCustomButtons}
      options={{
        maxWidth: "450px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Grid item xs={12}>
            <InfoBox title={""} level={"info"}>
              <Trans
                t={t}
                i18nKey="info_box"
                // eslint-disable-next-line react/jsx-key
                components={[<strong key={0} />]}
              />
            </InfoBox>
          </Grid>
          <Grid item xs={12}>
            <Select
              renderValue={(selected) => t(`document_type_${selected}`)}
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value as DOCUMENT_TYPES)}
              required
              label={t("choose_documents")}
            >
              {Object.values(DOCUMENT_TYPES).map((kind) => (
                <MenuItem key={kind} value={kind}>
                  {t(`document_type_${kind}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="bodyCopy" sx={{ fontWeight: 700 }}>
              {t("sms_preview")}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ backgroundColor: COLOR_PALETTE.BASE[100], padding: 2 }}>
            <Typography>{t("sms_preview_content", { organization_name: session?.user?.groups?.[0] || "" })}</Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};

// the back returns paths like: applicationID/filename.ext. This removes the applicationID in the prefix.
// It also tries to translate the filename if possible
const extractFileName = (t: TFunction, path: string, applicationID: string, withTranslation: boolean) => {
  const filename = path.replace(applicationID + "/", "");
  const filenameParts = filename.split(".");

  return withTranslation ? `${t(filenameParts[0])}.${filenameParts[1]}` : filename;
};

type ApplicationDocumentsProps = {
  applicationID: string;
};

export const ApplicationDocuments: FC<ApplicationDocumentsProps> = ({ applicationID }) => {
  const [requestDocumentsModalOpen, setRequestDocumentsModalOpen] = useState(false);

  const { t } = useTranslation(["application-documents"]);
  loadTranslations("application-documents");

  const closeModal = useCallback(() => setRequestDocumentsModalOpen(false), [setRequestDocumentsModalOpen]);

  const applicationDocumentsQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_DOCUMENTS, applicationID],
    queryFn: () => ApplicationApi.listDocuments(applicationID),
  });

  const [currentFilePath, setCurrentFilePath] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);

  const openMenu = useCallback(
    (event: React.MouseEvent) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const closeMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  // shows the loading element
  if (applicationDocumentsQuery.isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }}>
      <Grid container>
        <Grid item xs={7} sx={{ margin: "auto" }}>
          <Box aria-label="compatibility" role="region">
            <DocumentIcon sx={{ width: "24px", height: "24px", marginRight: "12px", float: "left" }} />
            <Typography variant="bodyCopyStrong" color={TEXT_COLOR.mainInfo}>
              {t("documents")}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Button
            sx={{ float: "right", marginLeft: "1px" }}
            variant="outlined"
            onClick={() => {
              setRequestDocumentsModalOpen(true);
            }}
            id="request_document"
          >
            {t("request_document")}
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ paddingTop: "16px", gap: "8px" }}>
        {applicationDocumentsQuery.data?.map((docPath: string) => {
          return (
            <Chip
              key={docPath}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {extractFileName(t, docPath, applicationID, true)}{" "}
                  <ArrowDropDownIcon
                    sx={{ width: "24px", height: "24px", color: `${TEXT_COLOR.lowestInfo} !important` }}
                  />
                </Box>
              }
              icon={
                <DocumentIcon sx={{ width: "24px", height: "24px", color: `${TEXT_COLOR.lowestInfo} !important` }} />
              }
              onClick={(e) => {
                openMenu(e);
                setCurrentFilePath(docPath);
              }}
              sx={{
                backgroundColor: "transparent",
                borderRadius: BORDER_RADIUS[4],
                border: `1px solid ${BORDER_COLOR.inputBase}`,

                color: `${TEXT_COLOR.lowInfo}`,
              }}
            />
          );
        })}
      </Grid>

      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu} hideBackdrop={true}>
        <MenuItem
          onClick={() => {
            downloadS3File(currentFilePath, extractFileName(t, currentFilePath, applicationID, false));
            closeMenu();
          }}
        >
          {t("download")}
        </MenuItem>
      </Menu>

      <ApplicationDocumentsModal
        open={requestDocumentsModalOpen}
        closeModal={closeModal}
        applicationID={applicationID}
      />
    </Grid>
  );
};
