import { useRouter } from "next/router";

import { FC } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Button } from "@mui/material";
import { Box } from "@mui/system";

import { ApplicationApi } from "@api";
import { loadTranslations } from "@lib";
import { Application } from "@typings";

interface Props {
  application: Application;
}

export const ApplicationResume: FC<Props> = ({ application }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const { locale } = useRouter();

  return (
    <Box sx={{ padding: "24px 0 24px 96px" }}>
      <Button
        color="primary"
        onClick={() => {
          ApplicationApi.getResume({ application_id: application.id, lang: locale || "fr" }).catch(() => {
            toast.error(t("download_failed"));
          });
        }}
        id="download_candidate_file"
      >
        {t("download")}
      </Button>
    </Box>
  );
};
