import { ClickToCallAPI } from "@api";
import { PHONE_VALIDATION_STATUS, QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const usePhoneNumberVerificationStatus = (phone: string) => {
  const numberStatusQuery = useQuery({
    queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
    queryFn: () => ClickToCallAPI.getNumberVerificationStatus(phone),
    enabled: !!phone,
  });

  return {
    isLoading: numberStatusQuery.isLoading,
    error: numberStatusQuery.error,
    status: numberStatusQuery.data?.status || PHONE_VALIDATION_STATUS.UNVERIFIED,
    verified: numberStatusQuery.data?.status === PHONE_VALIDATION_STATUS.VERIFIED,
  };
};
