import { AxiosResponse } from "axios";

interface DownloadFileParams {
  defaultFilename: string;
}

export const downloadFileFromResponse = (response: AxiosResponse, params: DownloadFileParams) => {
  let filename = params.defaultFilename;

  // Try to fetch filename from headers.
  const match = response.headers["content-disposition"].match(/filename="([^"]+)"/);
  if (match && match.length >= 1) {
    // The backend server uses go, which handles all strings utf8 by default. HTTP headers are, however, handled
    // as ISO-8859-1. This causes bad encoding for special characters such as accent. Decoding the string fixes
    // the issue.
    filename = decodeURIComponent(encodeURIComponent(match[1]));
  }

  // Create file link in browser's memory
  const href = URL.createObjectURL(
    new Blob([response.data] as BlobPart[], { type: response.headers["content-type"]?.toString() })
  );

  downloadFile(href, filename);

  URL.revokeObjectURL(href);
};

export const downloadFile = (url: string, filename: string) => {
  // Create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();

  // Clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
};

export const downloadS3File = (path: string, filename: string) => {
  const s3_url = process.env.NEXT_PUBLIC_S3_URL;
  return downloadFile(s3_url + path, filename);
};
