import { Trans, useTranslation } from "next-i18next";

import { Alert } from "@mui/material";

import { loadTranslations } from "@lib";
import { useNotification } from "@telnyx/react-client";

export const CallInProgressAlert = () => {
  const { t } = useTranslation("click-to-call");
  loadTranslations("click-to-call");

  const notification = useNotification();

  if (["new", "requesting", "trying", "early", "active"].includes(notification?.call?.state as string)) {
    return (
      <Alert severity="error" sx={{ borderRadius: 0 }}>
        <Trans t={t} i18nKey={"click-to-call:call_in_progress"} />
      </Alert>
    );
  }

  return <></>;
};
