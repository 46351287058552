import { FC, useCallback } from "react";

import { useTranslation } from "next-i18next";

import { Box, Grid, Typography } from "@mui/material";

import {
  ApplicationScoringChip,
  ApplicationStatusUpdateParams,
  SelectApplicationStatus,
  updateApplicationStatus,
} from "@components";
import { TEXT_COLOR } from "@constants";
import { computeApplicationJobLocation, loadTranslations } from "@lib";
import { useQueryClient } from "@tanstack/react-query";
import { Application, Candidate, SourcingCampaign, Status } from "@typings";

import { HeaderContent } from "./styles";

/**
 * Hook to update the application status
 * @param applicationID
 * @param campaignID
 * @returns
 */
export const useApplicationStatusUpdater = (applicationID: string, campaignID: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    async (newStatus: Status | null, params: ApplicationStatusUpdateParams) => {
      await updateApplicationStatus({ applicationID, campaignID, queryClient, newStatus, updateParams: params });
    },
    [applicationID, campaignID, queryClient]
  );
};

type ApplicationNameStatusProps = {
  sourcingCampaign: SourcingCampaign;
  candidate: Candidate;
  application: Application;
  applicationScore: number | undefined;
};

export const ApplicationNameStatus: FC<ApplicationNameStatusProps> = ({
  sourcingCampaign,
  candidate,
  application,
  applicationScore,
}) => {
  const { t } = useTranslation(["localization"]);
  loadTranslations("localization");

  const onStatusChange = useApplicationStatusUpdater(application.id, sourcingCampaign.campaign_id);

  return (
    <Grid sx={{ marginTop: "1rem" }}>
      <HeaderContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container>
              <Typography color={TEXT_COLOR.mainInfo} variant="headingLG">
                {candidate.first_name} {candidate.last_name}
              </Typography>
            </Grid>

            {applicationScore !== undefined && applicationScore >= 90 && (
              <Grid container sx={{ marginTop: "0.5rem" }}>
                <ApplicationScoringChip
                  applicationID={application.id}
                  campaignID={sourcingCampaign.campaign_id}
                  renderChipForLowPercentages={false}
                  chipSx={{
                    borderRadius: "3px",
                    width: "138px",
                    height: "26px",
                  }}
                />
              </Grid>
            )}

            <Box
              sx={{
                marginTop: "0.5rem",
              }}
            >
              <Typography variant="bodyCopySmall" color={TEXT_COLOR.mainInfo}>
                {application.job_title}
                {" | "}
                {computeApplicationJobLocation(application, t("localization:missing"))}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <SelectApplicationStatus
              displayInputLabel={false}
              application={application}
              campaignId={sourcingCampaign.campaign_id}
              onChange={onStatusChange}
              withReasonWhySelection={true}
              withSetupInterview={true}
              sendSMS
            />
          </Grid>
        </Grid>
      </HeaderContent>
    </Grid>
  );
};
