import { useRouter } from "next/router";

import { useCallback, useEffect, useState } from "react";

import { AxiosError } from "axios";
import { TFunction, useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { SourcingCampaignApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AtsIntegration, AtsIntegrationUpsertion } from "@typings";

import { yupResolver } from "@hookform/resolvers/yup";
import { IChangeEvent } from "@rjsf/core";

const atsUpsertValidationSchema = (t: TFunction) =>
  yup.object().shape({
    status_ids: yup
      .array()
      .of(yup.string())
      .min(1, t("validation.status_ids_required", { ns: "ats-export" }))
      .required(t("validation.status_ids_required", { ns: "ats-export" })),
  });

interface AtsIntegrationSettingHookProps {
  atsIntegration?: AtsIntegration;
  campaignId: string;
  workflowPath: string;
}

export const useATSIntegrationSetting = ({
  atsIntegration,
  campaignId,
  workflowPath,
}: AtsIntegrationSettingHookProps) => {
  const { t } = useTranslation(["ats-export"]);
  loadTranslations("ats-export");

  const [formData, setFormData] = useState(JSON.parse(atsIntegration?.input || "{}"));

  const queryClient = useQueryClient();
  const { locale } = useRouter();

  // update the form data when the atsIntegration changes.
  useEffect(() => {
    setFormData(JSON.parse(atsIntegration?.input || "{}"));
  }, [atsIntegration?.input]);

  const form = useForm({
    defaultValues: {
      status_ids: atsIntegration?.status_ids || [],
      lang: atsIntegration?.lang || locale?.substring(0, 2) || "fr",
    },
    resolver: yupResolver(atsUpsertValidationSchema(t)),
  });

  const upsertFormMutation = useMutation<unknown, AxiosError, AtsIntegrationUpsertion>({
    mutationFn: (payload) => SourcingCampaignApi.upsertAtsIntegration(campaignId, payload),
    onSuccess: () => {
      toast.success(t("ats_integration_upserted", { ns: "ats-export" }));
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.ATS_INTEGRATIONS, campaignId],
        })
        .catch();
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const deleteFormMutation = useMutation<unknown, AxiosError>({
    mutationFn: () => SourcingCampaignApi.deleteAtsIntegration(campaignId),
    onSuccess: () => {
      toast.success(t("ats_integration_deleted", { ns: "ats-export" }));
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.ATS_INTEGRATIONS, campaignId],
        })
        .catch();
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const submitUpsert = useCallback(
    async (data: IChangeEvent, isValid?: boolean) => {
      const isFormValid = await form.trigger().catch(() => false);

      if (isValid && isFormValid && !!workflowPath) {
        upsertFormMutation.mutate({
          status_ids: form.getValues("status_ids"),
          input: JSON.stringify(data.formData),
          workflow_path: workflowPath,
          lang: form.getValues("lang"),
        });
      }
    },
    [workflowPath, form, upsertFormMutation]
  );

  const submitDelete = useCallback(() => {
    deleteFormMutation.mutate();
  }, [deleteFormMutation]);

  return {
    upsert: submitUpsert,
    delete: submitDelete,
    form: {
      errors: form.formState.errors,
      control: form.control,
      data: formData,
      setData: setFormData,
    },
  };
};
