import { styled } from "@mui/material/styles";

export const Content = styled("div")`
  display: flex;
  flex: 1;
`;

export const PageContainer = styled("div")`
  padding: 20px;
  width: 100%;
  max-width: 1440px;
  flex-grow: 1;
`;
