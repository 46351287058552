import { Dispatch, FC, SetStateAction } from "react";

import { AxiosError } from "axios";
import { Form, FormInput, FormSelect } from "components/controls";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { CircularProgress, Grid, ThemeProvider, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { inviteUserBody, inviteUserResult, userApi } from "@api";
import { LOCALE_OPTIONS, QUERY_KEYS, ROLES_OPTIONS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import { useCurrentUserOrganization } from "@hooks/queries";

import { getValidationSchema } from "./validate";

type Props = {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
const DEFAULT_VALUES = {
  role: "",
  first_name: "",
  last_name: "",
  email: "",
};

export const InviteUserModal: FC<Props> = ({ open, onClose, setOpen }) => {
  const { t } = useTranslation([
    "members-form",
    "profile-contact",
    "profile-validation",
    "generic-validation",
    "common",
  ]);
  loadTranslations("members-form");
  loadTranslations("profile-validation");
  loadTranslations("profile-contact");
  loadTranslations("generic-validation");
  loadTranslations("common");

  const theme = useTheme();

  const validationSchema = getValidationSchema(t);

  const { data: session } = useSession();
  const currentOrganization = useCurrentUserOrganization(session?.user?.id);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const queryClient = useQueryClient();
  const {
    reset,
    trigger,
    getValues,
    formState: { isValid },
  } = methods;

  const mutation = useMutation<inviteUserResult, AxiosError, inviteUserBody, unknown>({
    mutationFn: userApi.sendInvite,
    onSuccess: () => {
      toast.success(t("generic-validation:creation_user"));
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.USERS],
        })
        .catch(() => {})
        .finally(() => {
          setOpen(false);
          onCancel();
        });
    },
    onError: (err: AxiosError) => {
      switch (err.response?.status) {
        case 409:
          toast.error(t("generic-validation:creation_user_error_conflict"));
          break;
        case 401:
          toast.error(t("generic-validation:creation_user_error_group"));
          break;
        default:
          toast.error(t("generic-validation:creation_error_user"));
      }
    },
  });

  const onSubmit = (data: inviteUserBody) => {
    mutation.mutate(data);
  };

  const onCancel = () => {
    reset(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      modalTitle={t("invite_new_user")}
      title={t("configure_info")}
      confirmText={t("submit")}
      cancelText={t("cancel")}
      scroll="body"
      onConfirm={() => {
        trigger().then((isValid) => {
          if (isValid) {
            const values = getValues() as inviteUserBody;
            if (currentOrganization.data?.group_id !== undefined) {
              values.group_id = currentOrganization.data.group_id;
              onSubmit(values);
            } else {
              toast.error(t("generic-validation:creation_user_error_group"));
            }
          }
        });
      }}
      onClose={onCancel}
      options={{
        confirmProps: { disabled: !isValid },
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid justifyContent="center">
          {mutation.isPending ? (
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Form methods={methods} submitHandler={onSubmit}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormInput
                    name="first_name"
                    label={t("first_name")}
                    placeholder={t("members-form:first_name_placeholder")}
                    rows={1}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: "24px 0px 24px 0px" }}>
                  <FormInput
                    name="last_name"
                    label={t("last_name")}
                    placeholder={t("members-form:last_name_placeholder")}
                    required
                    rows={1}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ padding: "0px 0px 24px 0px" }}>
                  <FormInput
                    name="email"
                    label={t("email")}
                    placeholder={t("members-form:email_placeholder")}
                    required
                    rows={1}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ padding: "0px 0px 24px 0px" }}>
                  <FormSelect
                    name="role"
                    label={t("role")}
                    placeholder={t("members-form:role_placeholder")}
                    fullWidth
                    required
                    options={ROLES_OPTIONS.map((o) => ({ ...o, label: t(o.label) }))}
                  />
                </Grid>

                <Grid item xs={12} sx={{ padding: "0px 0px 24px 0px" }}>
                  <FormSelect
                    name="locale"
                    label={t("locale")}
                    placeholder={t("members-form:locale_placeholder")}
                    fullWidth
                    required
                    options={LOCALE_OPTIONS.map((o) => ({ ...o, label: t(o.label) }))}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};
