import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { updateRoleBody, userApi } from "@api";
import { QUERY_KEYS, ROLES_OPTIONS, Roles } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthProxyUsersListItem, ILabelValueItem } from "@typings";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user: AuthProxyUsersListItem;
};

export const UpdateRoleModal: FC<Props> = ({ open, setOpen, user }) => {
  const { t } = useTranslation(["update-role-modal", "common"]);
  loadTranslations("update-role-modal");
  loadTranslations("common");

  const theme = useTheme();

  const [userRole, setUserRole] = useState(user.role.toString());

  const { reset } = useForm({ shouldUnregister: false, mode: "onChange" });
  const queryClient = useQueryClient();

  const updateRoleMutation = useMutation<void, AxiosError, Partial<updateRoleBody>>({
    mutationFn: userApi.updateRole,
    onSuccess: () => {
      toast.success(t("role_updated", { firstName: user.first_name, lastName: user.last_name }));

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.USERS],
        })
        .catch(() => {})
        .finally(closeModal);
    },
    onError: (err) => {
      switch (err.response?.status) {
        case 403:
          toast.error(t("not_allowed"));
          break;
        default:
          toast.error(t("error_occurred"));
      }
    },
  });

  const updateRole = () => {
    updateRoleMutation.mutate({ id: user.id, body: { role: userRole } });
  };

  //reset forms when closing modal
  const closeModal = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    setUserRole(user.role.toString());
  }, [user.role]);

  const handleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value.toString());
  };

  return (
    <Modal
      isOpen={open}
      aria-label="modifier role modal"
      confirmText={t("submit")}
      cancelText={t("decline")}
      onConfirm={updateRole}
      onClose={closeModal}
      options={{
        centerTitle: true,
      }}
      title={t("update_user_role")}
    >
      <ThemeProvider theme={theme}>
        <Box className="content" sx={{ maxWidth: "620px", width: "456px" }}>
          {updateRoleMutation.isPending ? (
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Box>
              <Box sx={{ marginTop: "2rem" }}>
                <InputLabel htmlFor="selected-user-name">{t("selected_user")}</InputLabel>
                <OutlinedInput
                  id="selected-user-name"
                  placeholder={t("selected_user")}
                  type="text"
                  disabled
                  defaultValue={user.first_name + " " + user.last_name}
                  fullWidth
                  sx={(theme) => ({
                    WebkitTextFillColor: theme.palette.primary[900],
                  })}
                />
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <InputLabel htmlFor="role">{t("new_role")}</InputLabel>
                <Select id="role" value={userRole} labelId="demo-simple-select-label" fullWidth onChange={handleChange}>
                  {ROLES_OPTIONS.map((role: ILabelValueItem<string, Roles>) => (
                    <MenuItem key={role.value} value={role.value}>
                      {t(role.label)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
