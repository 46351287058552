import { palette } from "@theme";

export type Campaign = {
  campaign_name: string;
  campaign_objective: string;
  campaign_goal: number;
  start_at: Date;
  campaign_duration: Date;
  launch_asap: boolean;
  budget: string;
  budget_value: number;
  currency: string;
  stakeholders: Array<string>;
  content_to_promote: string;
  creatives: Array<string>;
  creatives_check: boolean;
  wordings_check: boolean;
  wordings: Array<string>;
  geolocation: string;
  expected_qualification: string;
  content: string;
};

export type SourcingCampaign = {
  campaign_id: string;
  name: string;
  status: string;
  objective: string;
  budget: string;
  start_at: string;
  end_at: string;
  launch_live_date: string;
  launch_asap: boolean;
  duration: string;
  applications_count: number;
};

export interface ColorStyle {
  color?: string;
  backgroundColor?: string;
}

// Returns a Dict to map a campaign status with it's associated color.
export const CampaignStatusToStyle: Record<string, ColorStyle> = {
  "Under review": {
    backgroundColor: palette.primary[100],
    color: palette.primary[900],
  },
  "Additional details required": {
    backgroundColor: palette.warning.light || "",
    color: palette.warning.main || "",
  },
  Implementation: {
    backgroundColor: palette.warning.light || "",
    color: palette.warning.main || "",
  },
  Live: {
    backgroundColor: palette.success.light || "",
    color: palette.success.main || "",
  },
  Paused: {
    backgroundColor: palette.warning.light || "",
    color: palette.warning.main || "",
  },
  Finished: {
    backgroundColor: palette.grey[100] || "",
    color: palette.grey[800] || "",
  },
  Closed: {
    backgroundColor: palette.grey[100] || "",
    color: palette.grey[800] || "",
  },
};

export interface AtsIntegration {
  id: string;
  workflow_path: string;
  input: string;
  lang: string;
  status_ids: string[];
}

export type AtsIntegrationUpsertion = Omit<AtsIntegration, "id">;
