import { FC } from "react";

import { remove } from "lodash";
import { useTranslation } from "next-i18next";

import { ExpandMoreSharp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import { CheckboxListWithCount, CheckboxListWithCountItem, Spinner } from "@components";
import { loadTranslations } from "@lib";
import { ColumnFiltersState, OnChangeFn } from "@tanstack/react-table";
import { FiltererItemWithColor } from "@typings";

type FilterSelectorProps = {
  isLoading: boolean;
  filterTitles: Record<string, string>;
  filters: Record<string, Record<string, FiltererItemWithColor>>;
  onFilterChange: OnChangeFn<ColumnFiltersState>;
};

// FilterSelector is a generic component to display of list of options and their checkbox for a list of filters.
// It works with the idea that filterTitles and filters (on the first level) have keys that are matching
// the field names of the objects that we are filtering.
// On display, we loop over those and then over the list of possible values for the field and we display it to the user
// When a user toggle on a checkbox, we call the `onSelectionChange` function with the filter_key and a map
// of all the values: `true or false` depending on the user actions.
export const FilterSelector: FC<FilterSelectorProps> = ({ isLoading, filterTitles, filters, onFilterChange }) => {
  const { t } = useTranslation(["filter-selector"]);
  loadTranslations("filter-selector");

  return (
    <>
      {Object.entries(filters).map(([filterKey, filter]: [string, Record<string, FiltererItemWithColor>]) => (
        <Accordion
          key={filterKey}
          defaultExpanded
          aria-label={t("aria_filter_title")}
          sx={{
            bgcolor: "transparent",
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreSharp />}
            aria-controls="panel1a-content"
            sx={{
              "&.Mui-expanded": { minHeight: 24, height: 24 },
              height: 24,
              minHeight: 24,
              padding: "18px 16px",
            }}
          >
            <Typography variant="bodyCopyStrong">{filterTitles[filterKey]}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ padding: 0 }} aria-label={t("aria_list_of_filters")} role="list">
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner style={{ position: "relative", left: 0, top: 0 }} />
              </div>
            ) : (
              <CheckboxListWithCount
                items={Object.entries(filter).map(
                  ([key, item]: [string, FiltererItemWithColor]): CheckboxListWithCountItem => ({
                    value: key,
                    label: item.label || key,
                    count: item.count,
                    color: item.color,
                    backgroundColor: item.backgroundColor,
                  })
                )}
                onSelectionChange={(newActiveFilters: Record<string, boolean>) => {
                  onFilterChange((prev) => {
                    remove(prev, (f) => f.id === filterKey);

                    return [
                      ...prev,
                      {
                        id: filterKey,
                        value: Object.keys(newActiveFilters).filter((f) => newActiveFilters[f]),
                      },
                    ];
                  });
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
