import { ApplicationApi } from "@api";
import { QUERY_KEYS, STATUSES_WITH_REASON } from "@constants";
import { useQuery } from "@tanstack/react-query";
import { Application, ApplicationStatus } from "@typings";

export const useApplicationStatusReason = (status?: ApplicationStatus, application?: Application) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_STATUS_REASON, application?.id, status?.id],
    enabled: status != null && application != null && STATUSES_WITH_REASON.includes(status.label),
    queryFn: () =>
      ApplicationApi.getApplicationStatusReason({
        application_id: application?.id ?? "",
        status_id: status?.id ?? "",
      }),
  });
};
