import { Dispatch, FC, Fragment, SetStateAction, useMemo } from "react";

import { useSession } from "next-auth/react";

import { Box, Divider, Grid, SwipeableDrawer } from "@mui/material";

import { ApplicationApi, ScoringAPI } from "@api";
import { CallInProgressAlert, CommentList, Spinner } from "@components";
import { COLOR_PALETTE, QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";
import { Application, Candidate, ORGANIZATIONS_FEATURES, SourcingCampaign } from "@typings";

import {
  useCurrentUserOrganization,
  useFetchInterviewsConfigurationByCampaignID,
  useOrganizationFeatures,
} from "@hooks/queries";

import { ApplicationClickToCall } from "./application-click-to-call";
import { ApplicationCompatibility } from "./application-compatibility";
import { ApplicationDetailsHeader } from "./application-details-header";
import { ApplicationDocuments } from "./application-documents";
import { ApplicationInterview } from "./application-interview";
import { ApplicationNameStatus } from "./application-name-status";
import { ApplicationQualification } from "./application-qualification";
import { ApplicationResume } from "./application-resume";
import { ApplicationShare } from "./application-share";
import { ApplicationUserInfo } from "./application-user-info";

type ApplicationDetailsProps = {
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  sourcingCampaign: SourcingCampaign;
  candidate: Candidate;
  applicationId: string;
  previousApplication: (applicationIdx: number) => void;
  nextApplication: (applicationIdx: number) => void;
  applications: Application[];
  scoringEnabled: boolean;
};

export const ApplicationDetails: FC<ApplicationDetailsProps> = ({
  toggleDrawer,
  isOpen,
  sourcingCampaign,
  candidate,
  applicationId,
  previousApplication,
  nextApplication,
  applications,
  scoringEnabled,
}) => {
  // we have the `applications` array but the values contains in those are partial, thus this query.
  const { data: application } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS, applicationId],
    queryFn: () => ApplicationApi.get(applicationId),
    // refetch the application every minute to ensure that the messages history is
    // up-to-date
    refetchInterval: 60 * 1000,
  });

  const { data: applicationsScore } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, sourcingCampaign.campaign_id],
    queryFn: () => ScoringAPI.getAllScores(sourcingCampaign.campaign_id),
    staleTime: Infinity,
  });

  const { data: interviewConfiguration } = useFetchInterviewsConfigurationByCampaignID({
    campaignId: sourcingCampaign.campaign_id,
  });

  const applicationScore = useMemo(() => applicationsScore?.[applicationId], [applicationId, applicationsScore]);

  const { data: session } = useSession();

  const shouldRenderScoreChip = applicationScore !== undefined;

  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);

  const { hasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id || "", {
    enabled: currentOrganizationQuery.data?.group_id !== undefined,
  });

  return (
    <Fragment>
      <SwipeableDrawer
        data-testid={`application-details-${applicationId}`}
        role="region"
        aria-label="détails de l'application"
        anchor="right"
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        PaperProps={{
          sx: {
            overflow: "hidden",
          },
        }}
      >
        <Box role="presentation" sx={() => ({ width: "1256px", height: "100%" })}>
          {candidate && sourcingCampaign && application ? (
            <>
              <CallInProgressAlert />

              <ApplicationDetailsHeader
                sourcingCampaign={sourcingCampaign}
                application={application}
                toggleDrawer={toggleDrawer}
                nextApplication={nextApplication}
                previousApplication={previousApplication}
                applications={applications}
              />

              <Divider />

              <Grid
                container
                spacing={1}
                sx={{
                  paddingTop: "0px",
                  marginTop: "0px", // paddingTop and marginTop 0 so that the divider is correctly placed at the top
                  height: "calc(100% - 70px)",
                  overflow: "auto",
                }}
              >
                <Grid item xs={6} sx={{ paddingTop: "0px" }}>
                  <ApplicationNameStatus
                    sourcingCampaign={sourcingCampaign}
                    candidate={candidate}
                    application={application}
                    applicationScore={applicationScore}
                  />
                  <Divider />
                  <Box sx={{ padding: "24px 40px 24px 40px" }} display="flex" justifyContent="space-between">
                    {hasFeature(ORGANIZATIONS_FEATURES.CLICK_TO_CALL) && (
                      <ApplicationClickToCall
                        applicationId={application.id}
                        callerId={session?.user.id || ""}
                        applicantName={candidate.first_name + " " + candidate.last_name}
                        applicantPhone={candidate.phone}
                      />
                    )}
                    <ApplicationShare application={application} />
                  </Box>
                  <Divider />
                  <ApplicationUserInfo candidate={candidate} />
                  <Divider />
                  {interviewConfiguration && (
                    <>
                      <ApplicationInterview
                        sourcingCampaign={sourcingCampaign}
                        applicationID={applicationId}
                        interviewConfiguration={interviewConfiguration}
                      />
                      <Divider />
                    </>
                  )}
                  {scoringEnabled && (
                    <>
                      <ApplicationCompatibility
                        applicationId={applicationId}
                        campaignId={sourcingCampaign.campaign_id}
                        applicationScore={applicationScore}
                        shouldRenderScoreChip={shouldRenderScoreChip}
                      />
                      <Divider />
                    </>
                  )}
                  {hasFeature(ORGANIZATIONS_FEATURES.APPLICATIONS_DOCUMENTS) && (
                    <ApplicationDocuments applicationID={applicationId} />
                  )}

                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <ApplicationQualification application={application} />
                    </Grid>
                    <Grid item xs={6}>
                      <ApplicationResume application={application} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    marginBottom: "1rem",
                    margin: "0px",
                    padding: "32px 32px 0px 32px !important",
                    backgroundColor: COLOR_PALETTE.BASE[100],
                  }}
                >
                  {sourcingCampaign && application && <CommentList application={application}></CommentList>}
                </Grid>
              </Grid>
            </>
          ) : (
            <Spinner style={{ position: "relative" }} />
          )}
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};
