import { TFunction } from "next-i18next";
import * as yup from "yup";

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    first_name: yup.string().required(t("profile-validation:first_name_required")),
    last_name: yup.string().required(t("profile-validation:last_name_required")),
    role: yup.string().required(t("profile-validation:organization_name_required")),
    email: yup.string().required(t("profile-validation:email_required")).email(t("profile-validation:email_invalid")),
    locale: yup.string().required(t("profile-validation:locale_required")),
  });
