import { AxiosError } from "axios";
import moment, { Moment } from "moment";

import { Interview, InterviewsConfiguration, UpsertInteviewsConfigurationParams } from "@typings";

import { interviewAPI } from "./api";

const getConfiguration = async (campaignID: string): Promise<InterviewsConfiguration | undefined> => {
  const { data } = await interviewAPI
    .get<InterviewsConfiguration>(`/${campaignID}/configuration`)
    .catch((err: AxiosError) => {
      if (err.response?.status === 404) {
        return { data: undefined };
      }

      throw err;
    });
  return data;
};

const upsertConfiguration = async (params: UpsertInteviewsConfigurationParams): Promise<void> => {
  const { data } = await interviewAPI.post(`/${params.campaign_id}/configuration`, params.payload);
  return data;
};

const deleteConfiguration = async (campaign_id: string): Promise<void> => {
  const { data } = await interviewAPI.delete(`/${campaign_id}/configuration`);
  return data;
};

const getInterview = async (applicationID: string): Promise<Interview[]> => {
  const { data } = await interviewAPI
    .get<Interview[]>(`/applications/${applicationID}/interviews`)
    .catch((err: AxiosError) => {
      if (err.response?.status === 404) {
        return { data: [] };
      }

      throw err;
    });

  if (!data) {
    return data;
  }

  return data.map((interviewData) => {
    const interview = new Interview(
      interviewData["id"],
      interviewData["application_id"],
      interviewData["campaign_id"],
      interviewData["recruiter_user_id"],
      interviewData["date"],
      interviewData["time_start"],
      interviewData["time_end"],
      interviewData["location"],
      interviewData["stakeholders"],
      interviewData["additional_information"],
      interviewData["created_at"],
      interviewData["deleted"]
    );
    interview.time_start = moment(interview.time_start, "HH:mm");
    interview.time_end = moment(interview.time_end, "HH:mm");
    interview.date = moment(interview.date);

    return interview;
  });
};

export interface InterviewCreation {
  application_id: string;
  campaign_id: string;

  date: Moment;
  time_start: Moment;
  time_end: Moment;

  location: string;
  stakeholders: string[];
  additional_information: string;
}

const createInterview = async (interview: InterviewCreation): Promise<Interview> => {
  const { data } = await interviewAPI.post(`/applications/${interview.application_id}/interviews`, {
    ...interview,

    date: moment(interview.date).format("YYYY-MM-DD"),
    time_start: moment(interview.time_start).format("HH:mm"),
    time_end: moment(interview.time_end).format("HH:mm"),
  });
  return data;
};

const deleteInterview = async (interview_id: string): Promise<Interview> => {
  const { data } = await interviewAPI.delete(`/${interview_id}`);
  return data;
};

export const InterviewApi = {
  getConfiguration,
  upsertConfiguration,
  deleteConfiguration,
  getInterview,
  createInterview,
  deleteInterview,
};
