import { ReactElement } from "react";

import { Control, Controller, FieldValues, Path, UseFormRegisterReturn } from "react-hook-form";

import { Label } from "@styledcomponents";

export type RadioButtonProps = {
  label: ReactElement;
  register: UseFormRegisterReturn;
  name: string;
  isSelected: boolean;
  onChange: () => void;
  value: string;
  isDisabled?: boolean;
  defaultChecked?: boolean;
  radioInputCSS?: object;
  genericCSS?: object;
};

const RadioButton = ({
  label,
  register,
  name,
  isSelected,
  onChange,
  value,
  isDisabled = false,
  defaultChecked,
  radioInputCSS,
  genericCSS,
}: RadioButtonProps) => {
  return (
    <label style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%", ...genericCSS }}>
        <div style={{ float: "left", height: "100%", marginRight: 10, ...radioInputCSS }}>
          {isSelected && <div></div>}
          <input
            {...register}
            disabled={isDisabled}
            type="radio"
            name={name}
            value={value}
            className="opacity-0"
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
        </div>

        <div>
          <span>{label}</span>
        </div>
      </div>
    </label>
  );
};

export type RadioButtonGroupOption = {
  key: string;
  label: ReactElement;
  value: string;
  isDisabled?: boolean;
  defaultChecked: boolean;
  radioInputCSS?: object;
  genericCSS?: object;
};

export type RadioButtonGroupProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  options: RadioButtonGroupOption[];
  isDisabled: boolean;
  register: UseFormRegisterReturn;
};

export const RadioButtonGroup = <T extends FieldValues>({
  name,
  label,
  options,
  control,
  isDisabled,
  register,
}: RadioButtonGroupProps<T>) => {
  return (
    <div style={{ width: "100%" }}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr))", gridGap: "1em" }}
            >
              {options.map((opt) => (
                <RadioButton
                  key={opt.key}
                  label={opt.label}
                  register={register}
                  value={opt.value}
                  isSelected={field.value === opt.value}
                  name={name}
                  onChange={field.onChange}
                  isDisabled={opt.isDisabled || isDisabled}
                  defaultChecked={opt.defaultChecked}
                  radioInputCSS={opt.radioInputCSS}
                  genericCSS={opt.genericCSS}
                ></RadioButton>
              ))}
            </div>
          );
        }}
      ></Controller>
    </div>
  );
};
