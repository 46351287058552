import { FC } from "react";

import dompurify from "dompurify";
import moment, { Moment } from "moment";

import { Person as PersonIcon } from "@mui/icons-material";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography } from "@mui/material";

export const CommentTextBox: FC<{ children: string | Node }> = ({ children }) => (
  <Box
    sx={(theme) => ({
      paddingTop: "0.5rem",
      padding: "0.5rem 1rem",

      backgroundColor: "white",
      color: theme.palette.grey[600],

      borderRadius: "0.5rem",

      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "1rem",
    })}
  >
    <div
      className="--body-copy"
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(children, {
          FORCE_BODY: true,
          FORBID_TAGS: ["img", "script", "style"],
          FORBID_ATTR: ["src"],
          ADD_ATTR: ["target"],
        }),
      }}
    />
  </Box>
);

export const CommentListSubInfo: FC<{
  created_at: string | Moment;
  is_bulk_action?: string;
  kind?: string;
}> = ({ created_at, is_bulk_action = "", kind = "" }) => (
  <Box>
    <Typography variant="tags">
      {`${moment(created_at).format("LL")} | ${moment(created_at).format("LT")}`}
      {is_bulk_action && (
        <>
          {" | "} <b>{is_bulk_action}</b>
        </>
      )}
      {kind && (
        <>
          {" | "} <b>{kind}</b>
        </>
      )}
    </Typography>
  </Box>
);

export const TimelinePerson = () => (
  <TimelineDot
    sx={{
      width: "36px",
      height: "36px",
      backgroundColor: "#4C4E5D",
    }}
  >
    <PersonIcon
      sx={{
        width: "1.5rem",
        height: "1.5rem",
        color: "white",
        backgroundColor: "#4C4E5D",
      }}
    />
  </TimelineDot>
);
