import { ComponentProps, ReactNode } from "react";

import MaterialReactTable from "material-react-table";
import { useSession } from "next-auth/react";

import { Paper, Typography } from "@mui/material";

import { MRT_Localization_EN, MRT_Localization_FR } from "@locales";

const gridHeight: Readonly<number> = 500;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataGridWrapperProps<TData extends Record<string, any>> = Omit<
  ComponentProps<typeof MaterialReactTable<TData>>,
  | "muiSearchTextFieldProps"
  | "muiTableBodyRowProps"
  | "muiTablePaperProps"
  | "muiTableHeadCellProps"
  | "muiTableHeadCellFilterTextFieldProps"
  | "localization"
> & {
  total?: ReactNode;
};

// A wrapper around a data-grid (passed as the children).
// It's useful to factorize the results (total) part above the grid, as well as the design of the paper.
// Another reason for it is that when we enabled the footer on data-grid, it always gets out of bound because
// of the `flex` property. With this component, we can account for that and handle it by adding a gridHeight property.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataGridWrapper = <TData extends Record<string, any>>({
  total,
  initialState,
  ...props
}: DataGridWrapperProps<TData>) => {
  const { data: session } = useSession();

  return (
    <Paper
      sx={{
        width: "100%",
        mb: 2,
        borderRadius: "1rem",
        boxShadow:
          "22px 18px 11px rgba(5, 5, 102, 0.01), 12px 10px 9px rgba(5, 5, 102, 0.02), 5px 4px 7px rgba(5, 5, 102, 0.03), 1px 1px 4px rgba(5, 5, 102, 0.04), 0px 0px 0px rgba(5, 5, 102, 0.04)",
        overflow: "hidden",
      }}
    >
      {total && (
        <Typography
          variant="bodyCopyStrong"
          sx={{
            height: 64,
            display: "flex",
            alignItems: "center",
            padding: "0px 24px",
          }}
        >
          {total}
        </Typography>
      )}
      <MaterialReactTable
        localization={session?.user?.locale === "en" ? MRT_Localization_EN : MRT_Localization_FR}
        // style
        muiTableBodyRowProps={({ row }) => ({
          style: {
            backgroundColor: row.getIsSelected() ? "#E5F6FD" : "white",
          },
        })}
        muiTablePaperProps={{
          sx: (theme) => ({
            borderRadius: "1rem",
            boxShadow: "none",

            "& .MuiTableHead-root .MuiTableCell-root": {
              backgroundColor: theme.palette.background.paper,
            },

            "& .MuiToolbar-root": {
              backgroundColor: theme.palette.background.paper,
            },
          }),
        }}
        muiTableHeadCellProps={{
          sx: (theme) => ({
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
              color: theme.palette.grey[600],
              fontWeight: "400",
            },
          }),
        }}
        muiSearchTextFieldProps={{
          variant: "outlined",
          sx: (theme) => ({
            ".MuiInputBase-root": {
              borderRadius: "8px",
              border: "1px solid #B6B9DB",
              background: theme.palette.primary[50],
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              padding: "8px 12px",
            },
            ".MuiSvgIcon-root": {
              height: "20px",
              width: "20px",
            },
            "& input": {
              fontSize: "16px",
              lineHeight: "16px",
              padding: "0",
              height: "1lh",
            },
          }),
        }}
        muiTableHeadCellFilterTextFieldProps={{
          sx: {
            m: "0.5rem 0",
            width: "100%",
            "& input": {
              fontSize: "0.8rem",
            },
          },
          variant: "outlined",
          size: "small",
        }}
        muiTableContainerProps={{
          sx: {
            height: `${gridHeight}px`, // fixed as it's matching the DataGridWrapper.gridHeight prop for now
          },
        }}
        initialState={{
          showGlobalFilter: true,
          pagination: { pageSize: 50, pageIndex: 0 },
          ...initialState,
        }}
        {...props}
      />
    </Paper>
  );
};
