import { FC } from "react";

import { useTranslation } from "next-i18next";

import { Box, Grid, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

import { BasicTab, ItemTab } from "../common";
import { PersonalInformation, ProfileSettings } from "./forms";

//ProfileForm is to render the Tab in my-profile page
export const ProfileForm: FC = () => {
  const { t } = useTranslation(["profile-form"]);
  loadTranslations("profile-form");
  return (
    <Box sx={{ marginTop: "4rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography
            variant="h3"
            sx={(theme) => ({
              color: theme.palette.primary[700],
              fontSize: "34px",
              fontWeight: 550,
            })}
          >
            {t("my_profile")}
          </Typography>
        </Grid>

        <BasicTab
          style={{
            marginTop: "18px",
          }}
          tabs={{
            [t("profile")]: {
              node: (
                <>
                  <PersonalInformation />
                </>
              ),
            } as ItemTab,
            [t("settings")]: {
              node: (
                <>
                  <ProfileSettings />
                </>
              ),
            } as ItemTab,
          }}
        />
      </Grid>
    </Box>
  );
};

export default ProfileForm;
