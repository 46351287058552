import { Dispatch, FC, SetStateAction, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Box, Grid, IconButton, Typography } from "@mui/material";

import { COLOR_PALETTE, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { palette } from "@theme";
import { Application, SourcingCampaign } from "@typings";

import { IconDefinition, faAngleLeft, faAngleRight, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Header } from "./styles";

interface IconButtonProps {
  onClick: () => void;
  disabled?: boolean;
  icon: IconDefinition;
}

const CustomIconButton: FC<IconButtonProps> = ({ onClick, disabled, icon }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    style={{ color: palette.grey[800], cursor: "pointer", width: "32px", height: "32px" }}
  >
    <FontAwesomeIcon
      icon={icon}
      style={{
        color: COLOR_PALETTE.BASE[400],
        cursor: "pointer",
        height: "24px",
        width: "24x",
      }}
    />
  </IconButton>
);

type ApplicationDetailsHeaderProps = {
  sourcingCampaign: SourcingCampaign;
  application: Application;
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
  previousApplication: (applicationIdx: number) => void;
  nextApplication: (applicationIdx: number) => void;
  applications: Application[];
};

export const ApplicationDetailsHeader: FC<ApplicationDetailsHeaderProps> = ({
  sourcingCampaign,
  application,
  toggleDrawer,
  previousApplication,
  nextApplication,
  applications,
}) => {
  const { t } = useTranslation(["application-details-header", "application-status", "localization"]);
  loadTranslations("application-details-header");
  loadTranslations("application-status");
  loadTranslations("localization");

  const totalApplication = useMemo(() => {
    return applications.length;
  }, [applications]);

  const applicationIndex = useMemo(() => {
    return applications.findIndex((a) => a.id === application.id);
  }, [application, applications]);

  return (
    <Box sx={{ height: "70px" }}>
      <Header>
        <Grid container>
          <Grid item xs={5} sx={{ margin: "auto" }}>
            <Grid container sx={{ width: "236px" }}>
              <Grid item xs={2}>
                <CustomIconButton
                  onClick={() => previousApplication(applicationIndex)}
                  disabled={applicationIndex <= 0}
                  icon={faAngleLeft}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  margin: "auto 0",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {t("candidate")} {applicationIndex + 1}/{totalApplication}
              </Grid>
              <Grid item xs={2}>
                <CustomIconButton
                  onClick={() => nextApplication(applicationIndex)}
                  disabled={applicationIndex >= totalApplication - 1}
                  icon={faAngleRight}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="bodyCopyStrong">{sourcingCampaign.name}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-8px" }}>
                <Typography variant="tags" color={TEXT_COLOR.lowInfo}>
                  {t("nb_candidates", { count: totalApplication })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "32px",
              margin: "auto",
            }}
          >
            <CustomIconButton onClick={() => toggleDrawer(false)} icon={faX} />
          </Grid>
        </Grid>
      </Header>
    </Box>
  );
};
