import Link from "next/link";

import { useCallback, useContext, useEffect, useState } from "react";

import { useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";

import { Call } from "@mui/icons-material";
import { Button, Link as MuiLink, Tooltip } from "@mui/material";

import { loadTranslations } from "@lib";
import { TelnyxRTCContext, useNotification } from "@telnyx/react-client";
import { Call as ICall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call";
import { Logger } from "@utils";

import { usePhoneNumberVerificationStatus } from "@hooks/queries";

export const ApplicationClickToCall = ({
  applicationId,
  callerId,
  applicantName,
  applicantPhone,
}: {
  applicationId: string;
  callerId: string;
  applicantName: string;
  applicantPhone: string;
}) => {
  const { t } = useTranslation(["click-to-call"]);
  loadTranslations("click-to-call");

  const { data: session } = useSession();
  const client = useContext(TelnyxRTCContext);
  const notification = useNotification();

  const [hasMicPermission, setHasMicPermission] = useState(false);

  const phoneNumberVerificationStatus = usePhoneNumberVerificationStatus(session?.user.phone || "");

  const doCall = useCallback(() => {
    try {
      client?.newCall({
        destinationNumber: applicantPhone.replaceAll(" ", ""),
        callerName: (session?.user.given_name || "") + " " + (session?.user.family_name || ""),
        callerNumber: session?.user.phone.replaceAll(" ", ""),
        audio: true,
        video: false,
        customHeaders: [
          {
            name: "X-Destionation-Name",
            value: applicantName,
          },
          {
            name: "X-Application-External-ID",
            value: applicationId,
          },
          {
            name: "X-Caller-ID",
            value: callerId,
          },
        ],
      });
    } catch (e) {
      Logger.error(e);
    }
  }, [
    applicantName,
    applicantPhone,
    applicationId,
    callerId,
    client,
    session?.user.family_name,
    session?.user.given_name,
    session?.user.phone,
  ]);

  useEffect(() => {
    async function getMicPermission() {
      if (navigator?.permissions != null) {
        return await navigator.permissions.query({ name: "microphone" as PermissionName });
      }
      return undefined;
    }

    // check if the user has mic permission
    // the button will be disabled if the user DENIED the mic permission
    // otherwise the browser will ask for the permission
    getMicPermission().then((micPermission) => {
      setHasMicPermission(micPermission?.state !== "denied");
    });
  }, []);

  const call: ICall | undefined = notification?.call;
  const callState = call?.state;

  return (
    <Tooltip
      title={
        <Trans
          t={t}
          i18nKey={
            !phoneNumberVerificationStatus.verified
              ? "click-to-call:unverified_number"
              : !hasMicPermission
              ? "mic_permission"
              : ""
          }
          components={[
            <MuiLink
              key="my-profile-link"
              href="/my-profile"
              component={Link}
              sx={{ color: "white !important", textDecoration: "underline" }}
            />,
          ]}
        />
      }
      disableHoverListener={
        phoneNumberVerificationStatus.isLoading || (phoneNumberVerificationStatus.verified && hasMicPermission)
      }
      placement="top"
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            color: "white",
            bgcolor: theme.palette.background.darker,
            textAlign: "center",
            fontSize: "0.875rem",
          }),
        },
      }}
      arrow
    >
      {/* the span is needed because the tooltip dosen't work on disabled elements */}
      <span>
        <Button
          disabled={
            (call && callState !== "destroy" && callState !== "hangup") ||
            session?.user.phone === undefined ||
            phoneNumberVerificationStatus.isLoading ||
            phoneNumberVerificationStatus.error != null ||
            !phoneNumberVerificationStatus.verified ||
            !hasMicPermission
          }
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            doCall();
          }}
          id="click_to_call"
        >
          <Call sx={{ mr: "1rem" }} /> {t("call_candidate", { ns: "click-to-call" })}
        </Button>
      </span>
    </Tooltip>
  );
};
