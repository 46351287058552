import { styled } from "@mui/material/styles";

export const Card = styled("div")<{ alignCenter: boolean }>`
  background: #fff;
  border-radius: 30px;
  padding: 30px 30px 30px 30px;
  min-width: 500px;
  min-height: 350px;
  display: flex;
  flex-direction: column;

  align-items: ${(props) => (props.alignCenter ? "center" : "")};
  justify-content: ${(props) => (props.alignCenter ? "center" : "")};

  margin: auto;
  padding: 20px;
  width: 50vw;
  margin: auto;
`;
