import { FC, useCallback, useState } from "react";

import { AxiosError } from "axios";
import { signIn, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Backdrop, Box, CircularProgress, IconButton, InputAdornment, Typography } from "@mui/material";

import {
  ControlledTextField,
  Form,
  Modal,
  SaveComponentDefault,
  SaveComponentModal,
  SaveComponentModalConfigModalProps,
  SelfEditableTextField,
  SelfEditableTextFieldEditProps,
} from "@work4Labs/design-system";

import { userApi } from "@api";
import { PhoneInput } from "@components";
import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { Error } from "@styledcomponents";
import { useMutation } from "@tanstack/react-query";
import { Logger, hasRole } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";

import { getEmailValidationSchema, getFirstNameValidationSchema, getLastNameValidationSchema } from "../validate";

const UpdateEmailModal: FC<SaveComponentModalConfigModalProps> = ({ isOpen, closeModal }) => {
  const { data: session } = useSession();
  const { t } = useTranslation(["change-email-modal"]);
  loadTranslations("change-email-modal");

  const [showPassword, setShowPassword] = useState(false);

  const emailForm = useForm({
    shouldUnregister: false,
    defaultValues: {
      email: "",
      current_email: session?.user.email || "",
      password: "",
    },
    resolver: yupResolver(getEmailValidationSchema(t)),
    mode: "onChange",
  });

  const updateEmailMutation = useMutation({
    mutationFn: userApi.updateEmail,
    onSuccess: () => {
      toast.success(t("email_updated"));
      //this sign-in enable refresh token information: https://github.com/nextauthjs/next-auth/issues/2269
      signIn("keycloak", { callbackUrl: "/my-profile" }).catch(Logger.error);
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 401) {
        toast.error(t("bad_credentials"));
      } else {
        Logger.error(err);
        toast.error(t("error_occurred"));
      }
    },
  });

  const updateEmail = useCallback(
    (data: { email: string; current_email: string; password: string }) => {
      updateEmailMutation.mutate({ email: data.email, password: data.password });
    },
    [updateEmailMutation]
  );

  return (
    <Modal
      confirmText={t("submit")}
      cancelText={t("decline")}
      options={{
        centerTitle: true,
        confirmProps: {
          disabled: updateEmailMutation.isPending || !emailForm.formState.isValid,
        },
      }}
      title={t("change_email")}
      onConfirm={() => {
        updateEmail({
          email: emailForm.getValues("email"),
          current_email: emailForm.getValues("current_email"),
          password: emailForm.getValues("password"),
        });
        closeModal();
      }}
      onClose={closeModal}
      isOpen={isOpen}
    >
      <Form
        submitHandler={() => {}}
        methods={emailForm}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <ControlledTextField label={t("current_email")} name="current_email" disabled fullWidth />
        <ControlledTextField
          label={t("email")}
          name="email"
          fullWidth
          error={
            emailForm.formState.errors.email ? <Error>{emailForm.formState.errors.email?.message}</Error> : undefined
          }
        />
        <ControlledTextField
          label={t("password")}
          InputProps={{
            type: showPassword ? "text" : "password",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          name="password"
          error={
            emailForm.formState.errors.password ? (
              <Error>{emailForm.formState.errors.password?.message}</Error>
            ) : undefined
          }
        />
      </Form>
    </Modal>
  );
};

// PersonalInformation is a component tab that allows user to update his personal information
export const PersonalInformation: FC = () => {
  const { data: session } = useSession();
  const { t } = useTranslation(["personal-information", "profile-validation"]);
  loadTranslations("profile-validation");
  loadTranslations("personal-information");

  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const firstNameValidationSchema = getFirstNameValidationSchema(t);
  const lastNameValidationSchema = getLastNameValidationSchema(t);

  const firstNameForm = useForm({
    resolver: yupResolver(firstNameValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      first_name: session?.user.given_name || "",
    },
    mode: "onChange",
  });

  const lastNameForm = useForm({
    resolver: yupResolver(lastNameValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      last_name: session?.user.family_name || "",
    },
    mode: "onChange",
  });

  const emailForm = useForm({
    shouldUnregister: false,
    defaultValues: {
      current_email: session?.user.email || "",
    },
    resolver: yupResolver(getEmailValidationSchema(t)),
    mode: "onChange",
  });

  const updateUserInfoMutation = useMutation({
    mutationFn: userApi.updateUserInfo,
    onSuccess: async () => {
      // This sign-in enables to refresh token information:
      // https://github.com/nextauthjs/next-auth/issues/2269
      await signIn("keycloak", { callbackUrl: "/my-profile" });
      toast.success(t("updated_data"));
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const updateFirstName = useCallback(
    ({ first_name }: { first_name: string }) => {
      updateUserInfoMutation.mutate({ first_name });
    },
    [updateUserInfoMutation]
  );

  const updateLastName = useCallback(
    ({ last_name }: { last_name: string }) => {
      updateUserInfoMutation.mutate({ last_name });
    },
    [updateUserInfoMutation]
  );

  const updatePhone = useCallback(
    ({ personal_phone }: { personal_phone: string }) => {
      updateUserInfoMutation.mutate({ phone: personal_phone });
    },
    [updateUserInfoMutation]
  );

  const saveFieldComponent = useCallback(
    (props: SelfEditableTextFieldEditProps) => <SaveComponentDefault {...props}>{t("save")}</SaveComponentDefault>,
    [t]
  );

  const saveEmailComponent = useCallback(
    (props: SelfEditableTextFieldEditProps) => (
      <SaveComponentModal readOnly modal={UpdateEmailModal} {...props}>
        {t("save")}
      </SaveComponentModal>
    ),
    [t]
  );

  return (
    <Box>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: "fixed" }}
        open={updateUserInfoMutation.isPending}
      >
        <CircularProgress />
      </Backdrop>
      <Box sx={{ width: "100%", maxWidth: "40rem", display: "flex", flexDirection: "column", gap: "2rem" }}>
        <Typography
          variant="h3"
          sx={(theme) => ({
            color: theme.palette.primary[900],
            fontSize: "20px",
            fontStyle: "normal",
            lineHeight: "1.5rem",
            fontWeight: "600",
          })}
        >
          {t("personal_information")}
        </Typography>
        <SelfEditableTextField
          methods={firstNameForm}
          submitHandler={updateFirstName}
          placeholder={t("personal-information:first_name")}
          label={t("personal-information:first_name")}
          isEditing={editFirstName}
          setIsEditing={setEditFirstName}
          required
          name="first_name"
          error={
            firstNameForm.formState.errors.first_name && (
              <Error>{firstNameForm.formState.errors.first_name?.message}</Error>
            )
          }
          saveComponent={saveFieldComponent}
        />
        <SelfEditableTextField
          methods={lastNameForm}
          submitHandler={updateLastName}
          placeholder={t("personal-information:last_name")}
          label={t("personal-information:last_name")}
          isEditing={editLastName}
          setIsEditing={setEditLastName}
          required
          name="last_name"
          error={
            lastNameForm.formState.errors.last_name && <Error>{lastNameForm.formState.errors.last_name?.message}</Error>
          }
          saveComponent={saveFieldComponent}
        />
        <PhoneInput
          name="personal_phone"
          defaultValue={session?.user.phone || ""}
          onSubmit={updatePhone}
          label={t("professional_phone")}
          placeholder={t("professional_phone")}
          required
          forceValidation={!hasRole(session, Roles.SUPER_ADMIN)}
        />
        <SelfEditableTextField
          methods={emailForm}
          submitHandler={() => {}}
          placeholder={t("email")}
          label={t("email")}
          isEditing={editEmail}
          setIsEditing={setEditEmail}
          required
          name="current_email"
          saveComponent={saveEmailComponent}
        />
      </Box>
    </Box>
  );
};
