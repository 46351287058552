import { isValidPhoneNumber } from "libphonenumber-js";
import { TFunction } from "next-i18next";
import * as yup from "yup";

export const getFirstNameValidationSchema = (t: TFunction) =>
  yup.object().shape({
    first_name: yup.string().required(t("profile-validation:first_name_required")),
  });

export const getLastNameValidationSchema = (t: TFunction) =>
  yup.object().shape({
    last_name: yup.string().required(t("profile-validation:last_name_required")),
  });

export const getPhoneValidationSchema = (t: TFunction) =>
  yup.object().shape({
    phone: yup
      .string()
      .required(t("profile-validation:phone_number_required"))
      .test("valid-phone", t("profile-validation:phone_number_not_valid"), (value) =>
        value === undefined ? false : isValidPhoneNumber(value)
      ),
  });

export const getEmailValidationSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("profile-validation:email_invalid"))
      .required(t("profile-validation:email_required"))
      .notOneOf([yup.ref("currentEmail")], t("profile-validation:not_same_email")),
    password: yup.string().required(t("profile-validation:password_required")),
  });

export const getPasswordValidationSchema = (t: TFunction, email: string) =>
  yup.object().shape({
    currentPassword: yup.string().required(t("profile-validation:password_required")),
    newPassword: yup
      .string()
      .required(t("profile-validation:new_password_required"))
      .min(12, t("profile-validation:min_character"))
      .matches(RegExp("[a-z]"), t("profile-validation:Lowercase"))
      .matches(RegExp("[A-Z]"), t("profile-validation:Uppercase"))
      .matches(RegExp("\\d"), t("profile-validation:Number"))
      .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), t("profile-validation:Special"))
      .notOneOf([yup.ref("password")], t("profile-validation:not_identical_password"))
      .notOneOf([email], t("profile-validation:not_identical_email")),

    passwordConfirmation: yup.string().oneOf([yup.ref("newPassword")], t("profile-validation:identical_password")),
  });
