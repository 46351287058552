import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { Form, FormInput } from "components/controls";
import { TFunction, useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Grid, ThemeProvider, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { loadTranslations } from "@lib";
import { Error } from "@styledcomponents";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApplicationWithLocation, CommentKind, SourcingCampaign, Status } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";

import { SelectApplicationStatus, updateApplicationStatus } from "../common";

const getValidationSchema = (t: TFunction) => {
  return yup.object().shape({
    new_status: yup.string().required(t("status_required")),
    comment: yup.string().required(t("comment_required")),
  });
};

interface FormValue {
  new_status: string;
  comment: string;
}

const DEFAULT_VALUES: FormValue = {
  new_status: "",
  comment: "",
};

type BulkApplicationStatusUpdateModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sourcingCampaign: SourcingCampaign;
  applications: Record<string, ApplicationWithLocation>;
  onSuccess: () => void;
};

export const BulkApplicationStatusUpdateModal: FC<BulkApplicationStatusUpdateModalProps> = ({
  open,
  setOpen,
  sourcingCampaign,
  applications,
  onSuccess,
}) => {
  const { t } = useTranslation(["bulk-update-modal"]);
  loadTranslations("bulk-update-modal");

  const theme = useTheme();
  const queryClient = useQueryClient();

  const [newStatus, setNewStatus] = useState<Status | null>(null);

  const { mutateAsync: commentMutation } = useMutation({ mutationFn: ApplicationApi.createComment });

  const validationSchema = getValidationSchema(t);

  const statusForm = useForm({
    shouldUnregister: false,
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    setValue,
    clearErrors,
    trigger,
    getValues,
    formState: { errors },
  } = statusForm;

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const onSubmit = useCallback(
    async (data: FormValue) => {
      const comment = data.comment;

      // The product wants us to first change the statuses and then create the comments.
      const statusPromises = Object.values(applications)
        .filter((application) => application.status !== newStatus?.label)
        .map((application) =>
          updateApplicationStatus({
            applicationID: application.id,
            campaignID: sourcingCampaign.campaign_id,
            queryClient: queryClient,
            newStatus,
            updateParams: { sendSMS: true },
          })
        );
      const statusResults = await Promise.allSettled(statusPromises);

      const commentPromises: Promise<void>[] = Object.entries(applications).map(([id]) =>
        commentMutation({
          application_id: id,
          body: {
            content: comment,
            is_bulk_action: true,
            kind: CommentKind.COMMENT,
          },
        })
      );
      const commentCreateResults = await Promise.allSettled(commentPromises);

      const failureCount =
        statusResults.filter((r) => r.status === "rejected").length +
        commentCreateResults.filter((r) => r.status === "rejected").length;

      const successCount = statusResults.length + commentCreateResults.length - failureCount;

      if (failureCount == 0) {
        // Only do the end part if we've updated at least one application in the first place
        // We do this because the product doesn't want success notification if nothing got updated
        // (some update can be skipped, like the status update for an application already in status).
        if (successCount > 0) {
          toast.success(t("bulk_action_done"));
          onSuccess();
        }
      } else {
        toast.error(t("bulk_action_error", { count: failureCount }));
      }

      reset(DEFAULT_VALUES);
      setOpen(false);
    },
    [applications, queryClient, commentMutation, newStatus, onSuccess, reset, setOpen, sourcingCampaign.campaign_id, t]
  );

  const onCancel = useCallback(() => {
    reset(DEFAULT_VALUES);
    setOpen(false);
  }, [reset, setOpen]);

  const onStatusChange = useCallback(
    (newStatus: Status) => {
      setNewStatus(newStatus);
      setValue("new_status", newStatus.label);
      clearErrors("new_status");
    },
    [clearErrors, setValue]
  );

  const onConfirm = useCallback(() => {
    trigger()
      .then((isValid) => (isValid ? onSubmit(getValues()) : undefined))
      .catch(Logger.error);
  }, [getValues, onSubmit, trigger]);

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      modalTitle={t("modal_title")}
      title={t("title")}
      confirmText={t("submit")}
      cancelText={t("cancel")}
      onConfirm={onConfirm}
      onClose={onCancel}
    >
      <ThemeProvider theme={theme}>
        <Form
          methods={statusForm}
          submitHandler={(data: FormValue) => {
            onSubmit(data).catch(Logger.error);
          }}
        >
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ padding: "24px 0px 24px 0px" }}>
              <SelectApplicationStatus
                campaignId={sourcingCampaign.campaign_id}
                customLabel={t("status_field_label")}
                onChange={onStatusChange}
                dropDownScaleWidth={1}
              />
              {errors.new_status && <Error>{errors.new_status?.message}</Error>}
            </Grid>

            <Grid item xs={12} sx={{ padding: "0px 0px 24px 0px" }}>
              <FormInput
                name="comment"
                label={t("comment_title")}
                placeholder={t("comment_placeholder")}
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Form>
      </ThemeProvider>
    </Modal>
  );
};
