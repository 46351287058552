import { AxiosError } from "axios";

import { PHONE_VALIDATION_STATUS } from "@constants";
import {
  CallHistory,
  InitiateNumberVerificationPayload,
  NumberVerificationResponse,
  VerifyNumberPayload,
} from "@typings";

import { clickToCallAPI } from "./api";

const getToken = async (): Promise<string> => {
  const { data } = await clickToCallAPI.post(`/token`);
  return data.token;
};

const getNumberVerificationStatus = async (phone: string): Promise<NumberVerificationResponse> => {
  const { data } = await clickToCallAPI.get(`/number_verification_status/${phone}`).catch((err: AxiosError) => {
    if (err.response?.status === 404) {
      return { data: { status: PHONE_VALIDATION_STATUS.UNVERIFIED } };
    }

    throw err;
  });

  return { status: data.status, phone };
};

const initiateNumberVerification = async (payload: InitiateNumberVerificationPayload): Promise<void> => {
  const { data } = await clickToCallAPI.post(`/initiate_number_verification`, payload);
  return data;
};

const verifyNumber = async (payload: VerifyNumberPayload): Promise<string> => {
  const { data } = await clickToCallAPI.post(`/verify_number`, payload);
  return data.status;
};

const listApplicationHistories = async (applicationId: string): Promise<CallHistory[]> => {
  const { data } = await clickToCallAPI.get(`/histories/${applicationId}`);
  return data;
};

export const ClickToCallAPI = {
  getToken,
  getNumberVerificationStatus,
  initiateNumberVerification,
  verifyNumber,
  listApplicationHistories,
};
