import { FC } from "react";

import { Trans, useTranslation } from "next-i18next";

import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS, STATUSES_REASONS_WITH_SOURCE, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { Application, ApplicationStatus, AriaProps } from "@typings";

import { useApplicationStatusReason } from "@hooks/queries";

import { CommentListSubInfo, CommentTextBox, TimelinePerson } from "./style";

interface Props extends AriaProps {
  status: ApplicationStatus;
  application: Application;
}

const useStatusKind = (status: ApplicationStatus, application: Application) => {
  const { t } = useTranslation("status-details");
  loadTranslations("status-details");

  const reason = useApplicationStatusReason(status, application);

  if (reason.isLoading) {
    return undefined;
  }

  if (reason?.data?.source && STATUSES_REASONS_WITH_SOURCE.includes(status.label)) {
    return t(`status_reason_source.${status.label}.${reason.data.source}`);
  }

  return undefined;
};

const useStatusLabel = (status: ApplicationStatus, application: Application) => {
  const { t } = useTranslation("application-status");
  loadTranslations("application-status");

  const reason = useApplicationStatusReason(status, application);

  if (reason.data) {
    return t(`reasons.${status.label}.labels.${reason.data.reason}`);
  }

  return t(status.label);
};

const useStatusComment = (status: ApplicationStatus, application: Application) => {
  const reason = useApplicationStatusReason(status, application);
  return reason.data?.comment;
};

export const StatusDetail: FC<Props> = ({ status, application, role }) => {
  const { t } = useTranslation(["status-details", "dates"]);
  loadTranslations("status-details");
  loadTranslations("dates");

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: [QUERY_KEYS.USERS, status.user_id],
    queryFn: () => AuthProxyAPI.getUser(status.user_id),
  });

  const kind = useStatusKind(status, application);
  const label = useStatusLabel(status, application);
  const comment = useStatusComment(status, application);

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[300] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && !isLoadingUser && (
          <Box>
            <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
              <Trans
                t={t}
                i18nKey="application_info"
                components={[<strong key={0} />]}
                values={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                  statusLabel: label,
                }}
              />
            </Box>
            {comment && <CommentTextBox>{comment}</CommentTextBox>}
            <CommentListSubInfo
              created_at={status.created_at}
              is_bulk_action={(status.is_bulk_action && t("status_bulk_action")) || ""}
              kind={kind}
            />
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
