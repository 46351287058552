import { FC, ReactNode } from "react";

import { useTheme } from "@mui/material";

type DisableOverlayProps = {
  enabled: boolean;
  text: string;
  children: ReactNode;
};

// DisableOverlay is a component that greys out its child and disable all clicks
// and show a text on top of it when enabled.
export const DisableOverlay: FC<DisableOverlayProps> = ({ enabled, text, children }) => {
  const theme = useTheme();
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <div
      role="section"
      aria-label="emplacement désactivé"
      style={{ backgroundColor: theme.palette.grey[400], display: "flex", pointerEvents: "none", position: "relative" }}
    >
      <div style={{ position: "absolute", top: "50%", marginLeft: "auto", width: "100%", textAlign: "center" }}>
        {text}
      </div>
      <div style={{ opacity: 0.1 }}>{children}</div>
    </div>
  );
};
