import { FC, useMemo, useState } from "react";

import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import moment from "moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { AuthProxyUsersListItem } from "typings";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, IconButton } from "@mui/material";

import { AuthProxyAPI, ClickToCallAPI } from "@api";
import { DataGridWrapper, DeleteUserModal, LoadingAnimation, UpdateRoleModal } from "@components";
import { QUERY_KEYS, UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { useQueries, useQuery } from "@tanstack/react-query";
import { checkPermissions, ensureArray } from "@utils";

import { useUserPermissions } from "@hooks";

import { PhoneNumberStatusChip } from "./phone-number-status-chip";

interface NumbersVerificationStatus {
  data: Record<string, string>;
  pending: boolean;
}

export const MemberList: FC = () => {
  const { t } = useTranslation(["member-list"]);
  loadTranslations("member-list");

  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const canUpdateUsers = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.UsersPermissions.Write]),
    [userPermissions]
  );

  const [isOpenUpdateRoleModal, setIsOpenUpdateRoleModal] = useState(false);
  const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
  const [authProxyUsersListItem, setAuthProxyUsersListItem] = useState<AuthProxyUsersListItem>();

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.USERS],
    queryFn: () => AuthProxyAPI.listUsers(session?.user.groups ? session.user.groups[0] : ""),
  });

  const phoneNumberVerificationQueries = useQueries({
    queries: [...new Set(ensureArray(users).map((user) => user.phone))].map((phone) => ({
      queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
      queryFn: () => ClickToCallAPI.getNumberVerificationStatus(phone),
    })),
    combine: (results): NumbersVerificationStatus => {
      return {
        data: results.reduce(
          (acc, result) => (result.isSuccess ? { ...acc, [result.data.phone]: result.data.status } : acc),
          {}
        ),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const displayUpdateRoleModal = (user: AuthProxyUsersListItem) => {
    setAuthProxyUsersListItem(user);
    setIsOpenUpdateRoleModal(true);
  };

  const displayDeleteUserModal = (user: AuthProxyUsersListItem) => {
    setAuthProxyUsersListItem(user);
    setIsOpenDeleteUserModal(true);
  };

  const columns = useMemo<MRT_ColumnDef<AuthProxyUsersListItem>[]>(
    () => [
      {
        id: "name",
        header: t("name"),
        flex: 1,
        enableGlobalFilter: true,
        accessorFn: (row: AuthProxyUsersListItem) => `${row.first_name} ${row.last_name}`,
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) => {
          return (
            <span style={{ fontWeight: "bold" }}>
              {row.original.first_name} {row.original.last_name}
            </span>
          );
        },
      },
      {
        id: "emailVerified",
        header: t("status"),
        flex: 1,
        enableGlobalFilter: false,
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) => (
          <Chip
            label={row.original.active ? t("activated") : t("pending")}
            sx={(theme) => ({
              backgroundColor: row.original.active ? theme.palette.success.light : theme.palette.secondary[100],
              color: row.original.active ? theme.palette.success.main : theme.palette.secondary[500],
              borderRadius: "0.5rem",
            })}
          />
        ),
      },

      {
        id: "email",
        header: t("email"),
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) => <span>{row.original.email}</span>,
        flex: 1,
        accessorKey: "email",
        enableGlobalFilter: true,
      },
      {
        id: "role",
        header: t("role"),
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) => <span>{t(row.original.role?.toString())}</span>,
        flex: 1,
        enableGlobalFilter: false,
      },
      {
        id: "number_status",
        header: t("number_status"),
        enableGlobalFilter: false,
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) =>
          phoneNumberVerificationQueries.pending ? (
            <LoadingAnimation />
          ) : (
            <PhoneNumberStatusChip
              phone={row.original.phone}
              numberStatus={phoneNumberVerificationQueries.data[row.original.phone]}
            />
          ),
        flex: 1,
      },
      {
        id: "last_activity",
        header: t("last_activity"),
        enableGlobalFilter: false,
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) =>
          row.original.active ? (
            <span>{moment(row.original.last_activity).format("L LT")}</span>
          ) : (
            <span>{t("never_accessed")}</span>
          ),
        flex: 1,
      },
      {
        id: "action",
        header: t("actions"),
        enableGlobalFilter: false,
        Cell: ({ row }: { row: MRT_Row<AuthProxyUsersListItem> }) => (
          <Box>
            <IconButton
              aria-label="afficher update role modal button"
              onClick={() => displayUpdateRoleModal(row.original)}
            >
              <EditIcon sx={(theme) => ({ cursor: "pointer", color: theme.palette.grey[800] })}></EditIcon>
            </IconButton>
            <IconButton
              aria-label="afficher delete user modal button"
              onClick={() => displayDeleteUserModal(row.original)}
            >
              <DeleteIcon sx={(theme) => ({ cursor: "pointer", color: theme.palette.grey[800] })}></DeleteIcon>
            </IconButton>
          </Box>
        ),
        flex: 1,
      },
    ],
    [phoneNumberVerificationQueries.data, phoneNumberVerificationQueries.pending, t]
  );

  return (
    <>
      <DataGridWrapper
        total={t("nb_results", { count: users?.length || 0, ns: "member-list" })}
        data={ensureArray<AuthProxyUsersListItem>(users)}
        columns={columns}
        getRowId={(row: AuthProxyUsersListItem) => row.id}
        state={{
          isLoading: isLoadingUsers,
          columnVisibility: {
            action: canUpdateUsers,
          },
        }}
        enableGlobalFilter
        enableRowSelection={false}
        enableColumnActions={false}
        enableStickyHeader={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableSorting={false}
        enableColumnFilters={false}
        // style
        muiTableBodyCellProps={({ column, row }) => {
          return {
            "aria-label": t("aria_label_cell", { col_name: column.columnDef.header, index: row.index + 1 }),
            sx: {
              backgroundColor: "inherit",
              cursor: "pointer",
            },
          };
        }}
      />
      {authProxyUsersListItem && (
        <Box>
          <UpdateRoleModal
            open={isOpenUpdateRoleModal}
            setOpen={setIsOpenUpdateRoleModal}
            user={authProxyUsersListItem}
          />
          <DeleteUserModal
            open={isOpenDeleteUserModal}
            setOpen={setIsOpenDeleteUserModal}
            user={authProxyUsersListItem}
          />
        </Box>
      )}
    </>
  );
};
