import { Dispatch, FC, SetStateAction } from "react";

import { AxiosError } from "axios";
import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Grid, ThemeProvider, Typography, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Application } from "@typings";

type DeleteApplicationUpdateModalProps = {
  open: boolean;
  campaignID: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  applicationIDs: string[];
  onSuccess: () => void;
};

export const DeleteApplicationUpdateModal: FC<DeleteApplicationUpdateModalProps> = ({
  open,
  campaignID,
  setOpen,
  applicationIDs,
  onSuccess,
}) => {
  const { t } = useTranslation(["delete-application"]);
  loadTranslations("delete-application");

  const theme = useTheme();

  const { mutateAsync: deleteApplicationMutation } = useMutation<void, AxiosError, string>({
    mutationFn: ApplicationApi.deleteApplication,
  });

  const queryClient = useQueryClient();

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const onSubmit = () => {
    const promises: Promise<void>[] = [];

    for (const applicationID of applicationIDs) {
      promises.push(deleteApplicationMutation(applicationID));
    }

    // allSettle leaves the opportunity of all queries to be run even if one fails.
    // Ideally we would revert all changes but we would have to create a specific endpoint for that.
    Promise.allSettled(promises).then((results) => {
      let errCount = 0;
      for (const r of results) {
        if (r.status == "rejected") {
          errCount += 1;
        }
      }

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignID],
      });

      // Snapshot the previous value
      const previousApplications = queryClient.getQueryData([
        QUERY_KEYS.CAMPAIGN_APPLICATIONS,
        campaignID,
      ]) as Application[];

      if (previousApplications && applicationIDs?.length > 0) {
        // create a map to create a new array of application
        const applicationsToExclude = new Map(applicationIDs.map((id) => [id, 1]));
        const newApplications = previousApplications.filter((application: Application) => {
          return applicationsToExclude.get(application.id) !== 1;
        });

        // Optimistically update to the new value
        queryClient.setQueryData([QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignID], newApplications);
      }

      if (errCount == 0) {
        toast.success(t("delete_application_done"));
        onSuccess();
      } else {
        toast.error(t("delete_application_error", { count: errCount }));
      }
      setOpen(false);
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={t("title")}
      confirmText={t("submit")}
      cancelText={t("cancel")}
      onConfirm={onSubmit}
      onClose={onCancel}
      options={{
        centerTitle: true,
        confirmProps: { variant: "danger" },
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ padding: "24px 0px 24px 0px", display: "flex", justifyContent: "flex-end" }}>
            <Typography sx={{ textAlign: "center" }}>
              <Trans
                t={t}
                i18nKey="warning_delete_application"
                components={[<strong key={0} />]}
                values={{
                  count: applicationIDs.length,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};
