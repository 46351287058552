import { FC } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Box, Typography } from "@mui/material";

import { SaveComponentDefault, SelfEditableTextField, TextField } from "@work4Labs/design-system";

import { PhoneInput } from "@components";
import { loadTranslations } from "@lib";

import { useOrganizationPhoneUpdater, useRequestFormLink } from "@hooks";
import { useCurrentUserOrganization } from "@hooks/queries";

export const OrganizationSettings: FC = () => {
  const { data: session } = useSession();
  const { t } = useTranslation(["organization-form"]);
  loadTranslations("organization-form");

  const currentOrganization = useCurrentUserOrganization(session?.user?.id);

  const phoneSettings = useOrganizationPhoneUpdater(currentOrganization, session?.accessToken);

  const requestFormLinkSettings = useRequestFormLink(currentOrganization, session?.accessToken);

  return (
    <Box sx={{ width: "100%", maxWidth: "40rem", display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Typography
        variant="h3"
        sx={(theme) => ({
          color: theme.palette.primary[900],
          fontSize: "20px",
          fontStyle: "normal",
          lineHeight: "1.5rem",
          fontWeight: "600",
          marginBottom: "1.5rem",
        })}
      >
        {t("information")}
      </Typography>
      <TextField disabled fullWidth label={t("organization_name")} value={session?.user?.groups?.[0] || ""} />
      <PhoneInput
        name="organization_phone"
        defaultValue={phoneSettings.defaultValue}
        onSubmit={phoneSettings.submit}
        loading={currentOrganization.isLoading}
        disabled={!phoneSettings.canEditPhone || !currentOrganization.data}
        label={t("organization_phone")}
        labelHelper={t("organization_form_phone_helper")}
        placeholder={t("organization_phone")}
        required
      />
      {requestFormLinkSettings.canEdit && (
        <SelfEditableTextField
          textFieldProps={{ type: "url" }}
          name="request_form_link"
          isEditing={requestFormLinkSettings.edit}
          setIsEditing={requestFormLinkSettings.setEdit}
          disabled={currentOrganization.isLoading || !currentOrganization.data || currentOrganization.isLoading}
          label={t("organization_request_form_link")}
          labelHelper={t("organization_request_form_link_helper")}
          submitHandler={requestFormLinkSettings.submit}
          methods={requestFormLinkSettings.form}
          saveComponent={(props) => (
            <SaveComponentDefault
              disabled={!requestFormLinkSettings.form.formState.isValid || requestFormLinkSettings.isUpdating}
              {...props}
            >
              {t("save")}
            </SaveComponentDefault>
          )}
        />
      )}
    </Box>
  );
};
