import { useEffect, useRef, useState } from "react";

export function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = useRef(null);
  useEffect(() => {
    if (!isOpen) return;
    function handleClick() {
      if (dropdown.current) {
        setIsOpen(false);
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [isOpen]);

  return <></>;
}
