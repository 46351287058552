import { FC } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Box, FormControlLabel, Typography } from "@mui/material";

import { IOSSwitch } from "@components";
import { loadTranslations } from "@lib";
import { ORGANIZATIONS_FEATURES } from "@typings";

import { useConfirm } from "@hooks";
import { useCurrentUserOrganization, useOrganizationFeatures } from "@hooks/queries";

export const FeaturesSettings: FC = () => {
  const { t } = useTranslation(["features-settings"]);
  loadTranslations("features-settings");

  const { data: session } = useSession();

  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);

  const { hasFeature, toggleFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "");

  const [ToggleFeatureDialog, confirmToggleFeature] = useConfirm({
    title: t("toggle_feature_modal.title"),
    modalTitle: t("toggle_feature_modal.sub_title"),
    message: t("toggle_feature_modal.message"),
  });

  const handleFeatureToggle = (featureName: string, enabled: boolean) => {
    const confirmToggle = async () => {
      return await confirmToggleFeature();
    };

    confirmToggle().then((ans) => {
      if (ans) {
        toggleFeature(featureName, enabled, {
          onSuccess: () => {
            toast.success(t("feature_updated"));
          },
          onError: () => {
            toast.error(t("error_occurred"));
          },
        });
      }
    });
  };

  return (
    <>
      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          variant="h3"
          sx={(theme) => ({
            color: theme.palette.primary[900],
            fontSize: "20px",
            fontStyle: "normal",
            lineHeight: "1.5rem",
            fontWeight: "600",
            marginBottom: "2rem",
          })}
        >
          {t("features_management")}
        </Typography>
        <Box display="flex" flexDirection="column" gap="1rem">
          {Object.values(ORGANIZATIONS_FEATURES).map((feature) => (
            <Box key={feature}>
              <FormControlLabel
                label={t(`features.${feature}`)}
                sx={(theme) => ({
                  color: theme.palette.primary[900],
                  fontSize: "14px",
                  margin: 0,
                })}
                labelPlacement="start"
                control={
                  <IOSSwitch
                    sx={{
                      marginLeft: "1rem",
                    }}
                    checked={hasFeature(feature)}
                    onChange={(e) => {
                      handleFeatureToggle(feature, e.target.checked);
                    }}
                  />
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
      <ToggleFeatureDialog />
    </>
  );
};
