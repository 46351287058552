import { useRouter } from "next/router";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AxiosError } from "axios";
import { orderBy } from "lodash-es";
import { MRT_Column, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from "material-react-table";
import moment from "moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Cached as CachedIcon, Delete as DeleteIcon, Lightbulb as LightbulbIcon } from "@mui/icons-material";
import { Autocomplete, Button, Chip, Grid, TextField, Tooltip } from "@mui/material";

import { ApplicationApi, AuthProxyAPI, ScoringAPI } from "@api";
import {
  ApplicationDetails,
  ApplicationScoreGetterToLabel,
  ApplicationScoringChip,
  BulkApplicationStatusUpdateModal,
  DataGridWrapper,
  DeleteApplicationUpdateModal,
  FilterSelector,
  FilterValue,
  LastInteractionDateFilter,
  LastInteractionDateFilterFn,
  RecomputeScoreModal,
} from "@components";
import { QUERY_KEYS, UserPermissions } from "@constants";
import { computeApplicationJobLocation, loadTranslations } from "@lib";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnFiltersState, Row, RowSelectionState, getFacetedUniqueValues } from "@tanstack/react-table";
import { useCallbacks as useTelnyxCallbacks } from "@telnyx/react-client";
import {
  Application,
  ApplicationStatusToStyle,
  ApplicationWithLocation,
  Candidate,
  CandidatesObject,
  FiltererItemWithColor,
  SourcingCampaign,
  StatusCategories,
  UsersObject,
} from "@typings";
import { Logger, checkPermissions, elaspedTimeFromNow, ensureArray, parsePhoneNumber } from "@utils";

import { useUserPermissions } from "@hooks";

const RedDot = () => (
  <span
    style={{
      backgroundColor: "#FF6060",
      borderRadius: "50%",
      display: "inline-block",
      margin: "0px -1px",
      padding: "4px",
      position: "relative",
      right: "15px",
    }}
  />
);

type Props = {
  sourcingCampaign: SourcingCampaign;
};

export const Leads: FC<Props> = ({ sourcingCampaign }) => {
  const { t } = useTranslation([
    "application-scoring",
    "application-status",
    "campaign-detail",
    "datagrid",
    "leads",
    "dates",
    "localization",
  ]);
  loadTranslations("application-scoring");
  loadTranslations("application-status");
  loadTranslations("campaign-detail");
  loadTranslations("datagrid");
  loadTranslations("leads");
  loadTranslations("dates");
  loadTranslations("localization");

  const tableInstanceRef = useRef<MRT_TableInstance<ApplicationWithLocation>>(null);
  const queryClient = useQueryClient();

  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const canDeleteApplication = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.ApplicationPermissions.Delete]),
    [userPermissions]
  );
  const canCreateScore = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.ScoringPermissions.Write]),
    [userPermissions]
  );

  useTelnyxCallbacks({
    onError: (e) => {
      Logger.error(new Error("C2C Error: " + (e.message as string)));
    },
    onSocketError: (e) => {
      Logger.error(new Error("C2C Error: " + (e.message as string)));
    },
  });

  const router = useRouter();
  const { id, application_id } = router.query;

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [isBulkUpdateModalOpen, setBulkUpdateModalOpen] = useState(false);
  const [isDeleteApplicationModalOpen, setDeleteApplicationModalOpen] = useState(false);
  const [isBulkRecomputeScoreModalOpen, setBulkRecomputeScoreModalOpen] = useState(false);

  // states needed for the application details drawer
  const [candidate, setCandidate] = useState<Candidate>();
  const [applicationId, setApplicationId] = useState<string>("");
  const [isApplicationDrawerOpen, setIsApplicationDrawerOpen] = useState(false);

  const [users, setUsers] = useState<UsersObject>({});

  const [candidateIds, setCandidateIds] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);

  const [filteredApplications, setFilteredApplications] = useState<ApplicationWithLocation[]>([]);

  const { data: statuses } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
  });

  const applicationsQuery = useQuery<Application[], AxiosError, ApplicationWithLocation[]>({
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS, id],
    queryFn: () => ApplicationApi.list({ campaign_id: id as string }),
    select: (applications) =>
      applications.map(
        (a): ApplicationWithLocation => ({
          ...a,
          job_location: computeApplicationJobLocation(a, t("localization:missing")),
        })
      ),
  });

  const applicationsByID = useMemo<Record<string, ApplicationWithLocation>>(() => {
    return applicationsQuery.data
      ? applicationsQuery.data.reduce((acc, application) => ({ ...acc, [application.id]: application }), {})
      : {};
  }, [applicationsQuery.data]);

  const questionsQuery = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, id],
    queryFn: () =>
      ApplicationApi.listQuestions({
        campaign_id: id as string,
        ...(session?.user.groups ? { organization_names: [session.user.groups[0]] } : {}),
      }),
  });

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, candidateIds],
    queryFn: () => AuthProxyAPI.searchCandidates(candidateIds),
    enabled: !applicationsQuery.isLoading && candidateIds.length > 0,
    select: (candidates) =>
      candidates.reduce((res, candidate) => ({ ...res, [candidate.id]: candidate }), {} as CandidatesObject),
  });

  // useful to know if we want to display the compatibility column.
  const scoringEnabledQuery = useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, sourcingCampaign.campaign_id],
    queryFn: () => ScoringAPI.hasEngine(sourcingCampaign.campaign_id),
  });

  // we're using this useEffect Hook because the react-query
  // onSuccess is called only when the request is sent.
  // When the data is fetched from cache the onSuccess is not called.
  useEffect(() => {
    if (applicationsQuery.data === undefined || !applicationsQuery.isSuccess) {
      return;
    }

    setCandidateIds([...new Set(applicationsQuery.data.map((a) => a.candidate_id))]);
    setUserIds([...new Set(applicationsQuery.data.map((a) => a.last_user_id))]);
  }, [applicationsQuery.data, applicationsQuery.isSuccess]);

  // when the applications var is updated we fetch only the users that are not already fetched
  // @TODO: add a new endpoint to uservice-auth-proxy to fetch a list of users by an array of ids
  useEffect(() => {
    if (applicationsQuery.isLoading || !applicationsQuery.isSuccess) {
      return;
    }

    const userQueries = userIds
      .filter((userId) => users[userId] === undefined)
      .map((userId) =>
        queryClient.fetchQuery({
          queryKey: [QUERY_KEYS.APPLICATIONS_USERS, userId],
          queryFn: () => AuthProxyAPI.getUser(userId),
        })
      );
    Promise.all(userQueries).then((users) =>
      setUsers(users.reduce((res, user) => ({ ...res, [user.id]: user }), {}) as UsersObject)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds, queryClient, applicationsQuery.isLoading, applicationsQuery.isSuccess]);

  // Build a map of status to their category, to be able to apply the color.
  const statusToCategory = useMemo<Record<string, string>>(() => {
    if (statuses === undefined) {
      return {};
    }
    return Object.fromEntries(
      statuses.map((status) => [status.label, StatusCategories[status.category]]) as [string, string][]
    );
  }, [statuses]);

  const getApplicationName = useCallback(
    ({ row }: { row: ApplicationWithLocation }) => {
      const candidate = candidatesQuery.isSuccess ? candidatesQuery.data[row.candidate_id] : undefined;
      return candidate ? `${candidate.first_name} ${candidate.last_name}` : "";
    },
    [candidatesQuery.data, candidatesQuery.isSuccess]
  );

  const scoringColumn = useScoringRowParams(sourcingCampaign.campaign_id);

  const columns = useMemo<MRT_ColumnDef<ApplicationWithLocation>[]>(() => {
    if (scoringEnabledQuery.isLoading || applicationsQuery.isLoading || applicationsQuery.data === undefined) {
      return [];
    }

    // Create a list of static cols. We have code bellow that will dynamically add cols to the list at certain indexes
    // depending on the configuration
    const cols: MRT_ColumnDef<ApplicationWithLocation>[] = [
      {
        id: "name",
        accessorFn: (row: ApplicationWithLocation) => getApplicationName({ row }),
        Cell: ({ row }: { row: MRT_Row<ApplicationWithLocation> }) => {
          const shouldDisplayNotOpened =
            !row.original.last_opened_at ||
            moment(row.original.last_opened_at) < moment(row.original.last_interaction_date);

          if (shouldDisplayNotOpened) {
            return (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      color: "white",
                      bgcolor: theme.palette.background.darker,
                      textAlign: "center",
                    }),
                  },
                }}
                title={t("red_dot_tooltip", { ns: "leads" })}
                placement="top"
                arrow
              >
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <RedDot />
                  <span style={{ fontWeight: "bold" }}>{getApplicationName({ row: row.original })}</span>
                </div>
              </Tooltip>
            );
          }

          return <span style={{ fontWeight: "bold" }}>{getApplicationName({ row: row.original })}</span>;
        },
        header: t("name", { ns: "leads" }),
        columnFilterModeOptions: ["fuzzy"],
        minSize: 130,
        enableGlobalFilter: true,
      },
      {
        id: "status",
        accessorFn: (row: ApplicationWithLocation) => row.status,
        Cell: ({ cell }) => (
          <Chip
            label={t(`${String(cell.getValue())}`, { nsSeparator: ":", ns: "application-status" })}
            sx={{ ...ApplicationStatusToStyle[statusToCategory[String(cell.getValue())]] }}
          />
        ),
        header: t(`status`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableGlobalFilter: false,
        enableColumnFilter: false,
        minSize: 100,
      },
      {
        id: "contact",
        accessorFn: (row: ApplicationWithLocation) =>
          candidatesQuery?.data?.[row.candidate_id] ? candidatesQuery.data[row.candidate_id]?.phone : "",
        Cell: ({ row }: { row: MRT_Row<ApplicationWithLocation> }) =>
          candidatesQuery?.data?.[row.original.candidate_id]?.phone
            ? parsePhoneNumber(candidatesQuery.data[row.original.candidate_id].phone)
            : "",
        header: t(`contact`, { ns: "leads" }),
        columnFilterModeOptions: ["fuzzy"],
        minSize: 100,
        enableGlobalFilter: true,
      },
      {
        id: "last_interaction_date",
        accessorFn: (row: ApplicationWithLocation) => {
          return row.last_interaction_date && new Date(row.last_interaction_date);
        },
        Cell: ({ row }: { row: MRT_Row<ApplicationWithLocation> }) => {
          return elaspedTimeFromNow(t, moment(row.original.last_interaction_date));
        },
        header: t(`date`, { ns: "leads" }),
        enableGlobalFilter: false,
        Filter: ({ header, column }) => {
          return (
            tableInstanceRef.current && (
              <LastInteractionDateFilter
                table={tableInstanceRef.current}
                header={header}
                value={column.getFilterValue() as FilterValue}
                onChange={column.setFilterValue}
              />
            )
          );
        },
        filterFn: LastInteractionDateFilterFn,
        minSize: 130,
      },
      {
        id: "last_treatment",
        accessorFn: (row: ApplicationWithLocation) => users[row.last_user_id]?.first_name || "",
        Filter: ({ column }) => <SelectFilter column={column} multiple />,
        Cell: ({ row }: { row: MRT_Row<ApplicationWithLocation> }) =>
          users[row.original.last_user_id]?.first_name || "",
        header: t(`last_treatment`, { ns: "leads" }),
        minSize: 150,
        filterFn: "arrayIncludes",
        enableColumnFilter: true,
        enableGlobalFilter: false,
      },
      {
        id: "job_location",
        accessorFn: (row: ApplicationWithLocation) => row.job_location,
        header: t(`job_location`, { ns: "leads" }),
        filterFn: (row: Row<ApplicationWithLocation>, id: string, filterValue: string[]) =>
          filterValue.includes(row.getValue(id)),
        enableColumnFilter: false,
      },
      {
        id: "job_title",
        accessorFn: (row: ApplicationWithLocation) => row.job_title,
        header: t(`job_title`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableColumnFilter: false,
      },
      // For search purposes only.
      {
        id: "email",
        accessorFn: (row: ApplicationWithLocation) =>
          candidatesQuery?.data?.[row.candidate_id] ? candidatesQuery.data[row.candidate_id]?.email : "",
        header: t(`email`, { ns: "leads" }),
        columnFilterModeOptions: ["fuzzy"],
        enableGlobalFilter: true,
      },
      ...ensureArray(questionsQuery.data).map(
        (question): MRT_ColumnDef<ApplicationWithLocation> => ({
          id: question.label,
          accessorFn: (row: ApplicationWithLocation) =>
            row?.answers?.find((answer) => answer.question_label == question.label)?.answer || "",
          header: question.label,
          minSize: 200,
          Filter: ({ column }) => <SelectFilter column={column} multiple />,
          filterFn: "arrayIncludes",
        })
      ),
    ];

    if (scoringEnabledQuery.data) {
      // prefetch all data to be able to do a search with the data-grid
      // In theory ApplicationScoringChip should be making the queries,
      // but it only runs when the line get loaded (i.e depending on pagination)
      // However, we want our users to be able to search without having to render all the lines.
      // So we pre-fetch all the data here so that we can use the cache during the search.
      // FYI, we can't do it in the `filterFn` either because it can't return a promise.

      // applicationsQuery.data.map((application) =>
      //   queryClient
      //     .fetchQuery([QUERY_KEYS.APPLICATION_SCORE, application.id], () => ScoringAPI.getScore(application.id), {
      //       // no stale for scoring, we don't expect it to change often. We also really want the cache to work.
      //       staleTime: Infinity,
      //     })
      //     .catch((e) => {
      //       if (e.response?.status === 404) {
      //         queryClient.setQueryData([QUERY_KEYS.APPLICATION_SCORE, application.id], undefined);
      //       }
      //     })
      // );

      // Mui tell us that the columns prop should keep the same reference between two renders.
      // Otherwise, we take the risk of losing elements like column width or order.
      // Currently, we don't have an issue with this, most likely because while loading, we are returning an empty array
      cols.splice(2, 0, {
        id: "compatibility",
        Filter: ({ column }) => <SelectFilter column={column} multiple />,
        accessorFn: (row: ApplicationWithLocation) => scoringColumn.getRowParams(row.id).translation,
        filterFn: (row: Row<ApplicationWithLocation>, _: string, filterValues: string[]) => {
          if (filterValues.length === 0) {
            return true;
          }

          return filterValues.includes(scoringColumn.getRowParams(row.id).translation);
        },
        sortingFn: (a, b) => {
          const aRank = scoringColumn.getRowParams(a.id).rank;
          const bRank = scoringColumn.getRowParams(b.id).rank;

          return aRank - bRank;
        },
        Cell: ({ row }: { row: MRT_Row<ApplicationWithLocation> }) => (
          <ApplicationScoringChip
            applicationID={row.original.id}
            renderChipForLowPercentages={false}
            campaignID={sourcingCampaign.campaign_id}
          />
        ),
        header: t("compatibility", { ns: "leads" }),
        enableColumnFilter: true,
        enableGlobalFilter: false,
        minSize: 100,
      });
    }

    return cols;
  }, [
    scoringEnabledQuery.isLoading,
    scoringEnabledQuery.data,
    applicationsQuery.isLoading,
    applicationsQuery.data,
    t,
    questionsQuery.data,
    getApplicationName,
    statusToCategory,
    candidatesQuery.data,
    users,
    scoringColumn,
    sourcingCampaign.campaign_id,
  ]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // a string to search applications (handled by the grid)
  const [globalFilter, setGlobalFilter] = useState("");

  const [sideFilters, setSideFilters] = useState<Record<string, Record<string, FiltererItemWithColor>>>({});

  useEffect(() => {
    // only run if we have retrieved data because else, data grid sends 10 null data to us for some reason,
    // which causes artifacts to render in the interface
    if (applicationsQuery.data) {
      const filtered = tableInstanceRef.current?.getFilteredRowModel().rows.map((a) => a.original) || [];
      setFilteredApplications(filtered);

      // Also reset the selected applications when the filters evolve.
      // we keep only keep selected the applications that are still involved in the filters.
      setSelectedRows((prevState) =>
        Object.assign(
          {},
          ...filtered
            .filter((application) => prevState[application.id])
            .map((application) => ({ [application.id]: true }))
        )
      );
    }
  }, [applicationsQuery.data, columnFilters, globalFilter]);

  // @TODO: refactor this part to use the getFacetedUniqueValues() from react-table
  useEffect(() => {
    if (applicationsQuery.data === undefined || filteredApplications === undefined || statuses === undefined) {
      return;
    }

    const applicationJobLocation = new Set<string>();
    const applicationJobTitles = new Set<string>();

    // loop over the applications to create the job titles and location set
    for (const application of applicationsQuery.data) {
      applicationJobLocation.add(application.job_location);
      applicationJobTitles.add(application.job_title);
    }

    const statusesFilters: Record<string, FiltererItemWithColor> = orderBy(statuses, ["rank"]).reduce(
      (acc, status) => ({
        ...acc,
        [status.label]: {
          ...ApplicationStatusToStyle[status.category],
          count: filteredApplications.filter((a) => a.status === status.label).length,
          label: t(`${status.label}`, { nsSeparator: ":", ns: "application-status" }),
        },
      }),
      {}
    );

    const jobTitlesFilters: Record<string, FiltererItemWithColor> = Array.from(applicationJobTitles.values())
      .sort()
      .reduce(
        (acc, jobTitle) => ({
          ...acc,
          [jobTitle]: {
            label: jobTitle,
            count: filteredApplications.filter((a) => a.job_title === jobTitle).length,
          },
        }),
        {}
      );

    const jobLocationsFilters: Record<string, FiltererItemWithColor> = Array.from(applicationJobLocation.values())
      .sort()
      .reduce(
        (acc, location) => ({
          ...acc,
          [location]: {
            label: location,
            count: filteredApplications.filter((a) => a.job_location === location).length,
          },
        }),
        {}
      );

    const filters: Record<string, Record<string, FiltererItemWithColor>> = { status: statusesFilters };

    if (applicationJobTitles.size > 1) {
      filters.job_title = jobTitlesFilters;
    }

    if (applicationJobLocation.size > 1) {
      filters.job_location = jobLocationsFilters;
    }

    setSideFilters(filters);
  }, [t, filteredApplications, statuses, applicationsQuery.data]);

  const onApplicationChange = useCallback(
    (newIdx: number) => {
      if (filteredApplications?.[newIdx]) {
        const application = filteredApplications[newIdx];
        const candidate = candidatesQuery?.data?.[application.candidate_id];
        if (candidate) {
          setApplicationId(application.id);
          setCandidate(candidate);
          router
            .push(
              {
                pathname: router.pathname,
                query: { id, application_id: application.id },
              },
              undefined,
              { shallow: true }
            )
            .catch(Logger.error);
        }
      } else {
        setApplicationId("");
        setCandidate(undefined);
        router
          .push(
            {
              pathname: router.pathname,
              query: { id },
            },
            undefined,
            { shallow: true }
          )
          .catch(Logger.error);
      }
    },
    [candidatesQuery?.data, filteredApplications, id, router]
  );

  const onRowClick = useCallback(
    (applicationId: string, candidateId: string) => {
      const candidate = candidatesQuery?.data?.[candidateId];
      if (candidate) {
        setApplicationId(applicationId);
        setCandidate(candidate);
        setIsApplicationDrawerOpen(true);
        router
          .push(
            {
              pathname: router.pathname,
              query: { id, application_id: applicationId },
            },
            undefined,
            { shallow: true }
          )
          .catch(Logger.error);
      }
    },
    [candidatesQuery?.data, id, router]
  );

  // this effect is used to open the application drawer on first load
  // if the application_id is set in the url
  useEffect(() => {
    if (application_id && applicationId === "") {
      const application = filteredApplications.find((a) => a.id === application_id);
      if (application) {
        const candidate = candidatesQuery.data?.[application.candidate_id];
        if (candidate) {
          setApplicationId(application_id as string);
          setCandidate(candidate);
          setIsApplicationDrawerOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_id, filteredApplications, candidatesQuery.data, id, router]);

  // this effect is used to close reset the states of the drawer when the drawer is closed
  useEffect(() => {
    if (!isApplicationDrawerOpen && applicationId !== "") {
      setApplicationId("");
      setCandidate(undefined);
      router
        .push(
          {
            pathname: router.pathname,
            query: { id },
          },
          undefined,
          { shallow: true }
        )
        .catch(Logger.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplicationDrawerOpen]);

  const filterTiles = useMemo(
    () =>
      Object.keys(sideFilters).reduce(
        (acc, key) => ({
          ...acc,
          [key]: t(`filter_by_${key}`, { ns: "campaign-detail" }),
        }),
        {}
      ),
    [sideFilters, t]
  );

  const topToolbarCustomActions = () =>
    Object.keys(selectedRows)?.length > 0 ? (
      // Group the buttons in a span so that they stay close together.
      // By default, mui is applying `justify-content: space-between;` to the whole bar, but we want our
      // buttons to stay grouped to the left.
      <span style={{ display: "flex", flexDirection: "row", gap: "1rem", flexWrap: "wrap" }}>
        <Button
          variant="contained"
          sx={(theme) => ({
            backgroundColor: "white",
            color: theme.palette.grey[800],
            border: `1px solid ${theme.palette.grey[500] || ""}`,
            ":hover": {
              bgcolor: "white",
            },
          })}
          onClick={() => {
            setBulkUpdateModalOpen(true);
          }}
        >
          <CachedIcon sx={{ marginRight: "12px" }} />
          {t("leads:update_bulk_action")}
        </Button>
        {canDeleteApplication && (
          <Button
            variant="contained"
            sx={(theme) => ({
              backgroundColor: "white",
              color: theme.palette.grey[800],
              border: `1px solid ${theme.palette.grey[500] || ""}`,
              ":hover": {
                bgcolor: "white",
              },
            })}
            onClick={() => setDeleteApplicationModalOpen(true)}
          >
            <DeleteIcon sx={{ marginRight: "12px" }} />
            {t("leads:delete_application")}
          </Button>
        )}
        {canCreateScore && (
          <Button
            variant="contained"
            sx={(theme) => ({
              backgroundColor: "white",
              color: theme.palette.grey[800],
              border: `1px solid ${theme.palette.grey[500] || ""}`,
              ":hover": {
                bgcolor: "white",
              },
            })}
            onClick={() => setBulkRecomputeScoreModalOpen(true)}
          >
            <LightbulbIcon sx={{ marginRight: "12px" }} />
            {t("leads:recompute_scoring_bulk_action")}
          </Button>
        )}
      </span>
    ) : (
      <span></span> // hacky empty span because if we use </> the header icons are moved to the left
    );

  const showApplicationDetails = candidate && applicationId && filteredApplications && !scoringEnabledQuery.isLoading;

  const applications = useMemo((): Record<string, ApplicationWithLocation> => {
    if (selectedRows == null) {
      return {};
    }

    return Object.keys(selectedRows).reduce((acc, appID) => {
      acc[appID] = applicationsByID[appID];
      return acc;
    }, {});
  }, [applicationsByID, selectedRows]);

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={3}>
          <FilterSelector
            filterTitles={filterTiles}
            filters={sideFilters}
            onFilterChange={setColumnFilters}
            isLoading={applicationsQuery.isLoading}
          />
        </Grid>
        <Grid item xs={9}>
          <DataGridWrapper
            total={t("nb_results", { count: filteredApplications?.length || 0, ns: "campaign-detail" })}
            tableInstanceRef={tableInstanceRef}
            columns={columns}
            data={applicationsQuery.data ?? []}
            enableGlobalFilter
            enableRowSelection
            getRowId={(app: ApplicationWithLocation) => app.id}
            onRowSelectionChange={setSelectedRows}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            selectAllMode="all"
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            getFacetedUniqueValues={getFacetedUniqueValues()}
            filterFns={{
              arrayIncludes: (row, id, filterValue: string[]) =>
                filterValue.length === 0 || filterValue.includes(row.getValue(id)),
            }}
            renderTopToolbarCustomActions={topToolbarCustomActions}
            initialState={{
              columnVisibility: { job_title: false, job_location: false, email: false },
              columnPinning: {
                left: ["mrt-row-select", "name"],
              },
              columnFilters: [{ id: "last_interaction_date", value: "" }],
            }}
            state={{
              columnFilters,
              globalFilter,
              rowSelection: selectedRows,
              isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading,
              showAlertBanner: applicationsQuery.isError || candidatesQuery.isError,
              showProgressBars: applicationsQuery.isFetching || candidatesQuery.isFetching,
            }}
            // style
            muiTableBodyCellProps={({ cell, row }) => ({
              sx: {
                backgroundColor: "inherit",
                cursor: "pointer",
              },
              onClick: () => {
                if (cell.id !== "mrt-row-select") {
                  onRowClick(row.original.id, row.original.candidate_id);
                }
              },
            })}
            muiTableContainerProps={{
              sx: {
                height: "auto",
              },
            }}
            muiToolbarAlertBannerProps={
              applicationsQuery.isError
                ? {
                    color: "error",
                    children: t("error", { ns: "datagrid" }),
                  }
                : undefined
            }
          />
        </Grid>
      </Grid>
      {statuses && (
        <BulkApplicationStatusUpdateModal
          applications={applications}
          sourcingCampaign={sourcingCampaign}
          open={isBulkUpdateModalOpen}
          setOpen={setBulkUpdateModalOpen}
          onSuccess={() => setSelectedRows({})}
        />
      )}

      <DeleteApplicationUpdateModal
        applicationIDs={Object.keys(selectedRows || {})}
        open={isDeleteApplicationModalOpen}
        campaignID={sourcingCampaign.campaign_id}
        setOpen={setDeleteApplicationModalOpen}
        onSuccess={() => setSelectedRows({})}
      />

      <RecomputeScoreModal
        applicationIDs={Object.keys(selectedRows || {})}
        open={isBulkRecomputeScoreModalOpen}
        setOpen={setBulkRecomputeScoreModalOpen}
        onSuccess={() => setSelectedRows({})}
      />

      {showApplicationDetails && (
        <ApplicationDetails
          toggleDrawer={setIsApplicationDrawerOpen}
          isOpen={isApplicationDrawerOpen}
          sourcingCampaign={sourcingCampaign}
          candidate={candidate}
          applicationId={applicationId}
          nextApplication={(currIdx) => onApplicationChange(currIdx + 1)}
          previousApplication={(currIdx) => onApplicationChange(currIdx - 1)}
          applications={filteredApplications}
          scoringEnabled={!!scoringEnabledQuery.data}
        />
      )}
    </>
  );
};

// A custom select filter based on mui autocomplete component
// when setting multiple to true you have to use the FilterFn "arrayIncludes" on the column
// @TODO: move this component to a separate file when we will refactor all the datagrids of the project
const SelectFilter: FC<{
  column: MRT_Column<ApplicationWithLocation>;
  multiple?: boolean;
}> = ({ column, multiple }) => {
  const { t } = useTranslation(["datagrid"]);
  loadTranslations("datagrid");

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(() => Array.from(column.getFacetedUniqueValues().keys()).sort(), [column]);

  return (
    <Autocomplete
      multiple={multiple}
      id={`${column.id}-filter`}
      options={sortedUniqueValues}
      value={columnFilterValue}
      onChange={(_, value) => column.setFilterValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ m: "0.5rem 0", width: "100%", "& input": { fontSize: "0.8rem" } }}
          variant="outlined"
          size="small"
          placeholder={t("filter_by", { ns: "datagrid", field: column.columnDef.header })}
        />
      )}
    />
  );
};

const useScoringRowParams = (campaignID: string) => {
  const { t } = useTranslation(["application-scoring"]);

  const scoringParams = useMemo(
    () => ({
      top_candidate: {
        translation: t("top_candidate", { ns: "application-scoring" }),
        rank: 4,
      },
      high: {
        translation: t("high", { ns: "application-scoring" }),
        rank: 3,
      },
      medium: {
        translation: t("medium", { ns: "application-scoring" }),
        rank: 2,
      },
      low: {
        translation: t("low", { ns: "application-scoring" }),
        rank: 1,
      },
      no_score: {
        translation: t("no_score", { ns: "application-scoring" }),
        rank: 0,
      },
    }),
    [t]
  );

  const { data: scoreData } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
    queryFn: () => ScoringAPI.getAllScores(campaignID),
    staleTime: Infinity,
  });

  const getRowParams = useCallback<(rowID: string) => { translation: string; rank: number }>(
    (rowID: string) => {
      if (scoreData == undefined) {
        return scoringParams.no_score;
      }

      const score: number | null = scoreData[rowID] ? scoreData[rowID] : null;
      return scoringParams[ApplicationScoreGetterToLabel(score)] || scoringParams.no_score;
    },
    [scoreData, scoringParams]
  );

  return { getRowParams };
};
