import Link from "next/link";
import { useRouter } from "next/router";

import { FC, useEffect, useMemo, useRef, useState } from "react";

import { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from "material-react-table";
import moment, { Moment } from "moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Box, Chip, FormControl, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { DataGridWrapper, FilterSelector, PageTitle } from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { SubmitButton } from "@styledcomponents";
import { ColumnFiltersState, getFacetedUniqueValues } from "@tanstack/react-table";
import { CampaignStatusToStyle, FiltererItemWithColor, SourcingCampaign } from "@typings";
import { BudgetFormatter, checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";
import { useCurrentUserOrganization, useOrganizationRequestFormLink } from "@hooks/queries";

// Small function to order the campaigns by status.
// We first group our campaigns by status in one array per status, and then we
// merge all the arrays.
// Currently, there is no ordering within one status.
// We don't use MaterialReactTable sorting yet, cf: https://github.com/Work4Labs/platform-ui/pull/257#discussion_r1058457640
function orderCampaignByStatus(matchingData: SourcingCampaign[]): SourcingCampaign[] {
  const campaignsByStatus: Record<string, SourcingCampaign[]> = {};
  for (const campaign of matchingData) {
    if (campaignsByStatus[campaign.status] === undefined) {
      campaignsByStatus[campaign.status] = [];
    }
    campaignsByStatus[campaign.status].push(campaign);
  }

  let orderedCampaigns: SourcingCampaign[] = [];
  // this order has been determined by the product
  for (const status of [
    "Live",
    "Paused",
    "Additional details required",
    "Implementation",
    "Under review",
    "Finished",
    "Closed",
  ]) {
    if (campaignsByStatus[status] !== undefined) {
      orderedCampaigns = orderedCampaigns.concat(campaignsByStatus[status]);
    }
  }

  return orderedCampaigns;
}

// Returns the launch_live_date is it's there and else the start at if it's there too.
function getCampaignStartAt(campaign: SourcingCampaign): string {
  if (campaign.launch_live_date) {
    return campaign.launch_live_date;
  }

  // rely on launch_asap to display the date, because if true, the date is 0001-01-01
  if (!campaign.launch_asap && campaign.start_at) {
    return campaign.start_at;
  }

  return "";
}

type CampaignTableWithFiltersProps = {
  campaigns: SourcingCampaign[];
  isLoading: boolean;
};

// CampaignTableWithFilters is a component displaying the filters and the data grid of our list of campaigns.
// It contains the logic to filter the data and display the filters alongside the grid, but just that.
// The rest should be put in CampaignListPageContent (that's why CampaignList itself is not exported BTW.)
const CampaignTableWithFilters: FC<CampaignTableWithFiltersProps> = ({ campaigns, isLoading }) => {
  const { t, i18n } = useTranslation(["campaign-list", "campaign-status"]);
  loadTranslations("campaign-list");
  loadTranslations("campaign-status");

  const router = useRouter();

  const tableInstanceRef = useRef<MRT_TableInstance<SourcingCampaign>>(null);

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: "campaign_start_date", value: null },
    { id: "campaign_end_date", value: null },
  ]);
  const [sideFilters, setSideFilters] = useState<Record<string, Record<string, FiltererItemWithColor>>>({});

  const [filteredCampaigns, setFilteredCampaigns] = useState<SourcingCampaign[]>([]);

  const columns = useMemo<MRT_ColumnDef<SourcingCampaign>[]>(
    () => [
      {
        id: "name",
        header: t("name"),
        accessorFn: (row) => row.name,
        Cell: ({ row }: { row: MRT_Row<SourcingCampaign> }) => {
          return <span style={{ cursor: "pointer", fontWeight: "bold" }}>{row.original.name}</span>;
        },
        columnFilterModeOptions: ["fuzzy"],
      },
      {
        id: "status",
        header: t("status"),
        accessorFn: (row) => row.status,
        Cell: ({ row }: { row: MRT_Row<SourcingCampaign> }) => (
          <Chip
            label={t(row.original.status, { ns: "campaign-status" })}
            sx={{ ...CampaignStatusToStyle[row.original.status] }}
          />
        ),
        enableGlobalFilter: false,
        enableColumnFilter: false,
        filterFn: "arrayIncludes",
      },
      {
        id: "times_left",
        header: t("times_left"),
        flex: 1,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        accessorFn: (campaign: SourcingCampaign) => {
          const end_date = campaign.end_at;

          if (campaign.status == "Paused") {
            return t("campaign-status:Paused");
          }

          if (campaign.status == "Finished") {
            return t("campaign-status:Finished");
          }

          if (end_date) {
            const startDate = moment(getCampaignStartAt(campaign));

            // We want to compute the remaining time. If the campaign has not started yet
            // i.e. now() < start date then the remaining time is the duration of the campaign.
            // if the campaign has started, we use now() for the diff because time has passed since
            // we've started the campaign and thus the remaining time has decreased.
            const compareDate = moment() < startDate ? startDate : moment();
            const diff = moment(end_date).diff(compareDate, "days");

            if (diff > 0) {
              return t("days", { days: diff + 1 });
            }
            if (diff === 0) {
              return t("campaign-list:today");
            }
            // diff < 0 is not covered, because we expect the status of the campaign to be Finished at this point
            // We also don't want to force show Finished if we've past the end date, but the campaign actually got
            // extended and is running.
          }

          return t("campaign-list:na");
        },
      },
      {
        id: "applications_count",
        accessorKey: "applications_count",
        header: t("applications_count"),
        enableGlobalFilter: false,
        enableColumnFilter: false,
      },
      {
        id: "budget",
        accessorKey: "budget",
        header: t("budget"),
        Cell: ({ row }) => BudgetFormatter(row.original.budget, i18n.language),
        enableGlobalFilter: false,
        enableColumnFilter: false,
      },
      {
        id: "campaign_start_date",
        header: t("campaign_start_date"),
        flex: 1,
        accessorFn: (row) => getCampaignStartAt(row) && new Date(getCampaignStartAt(row)),
        Cell: ({ row }) => {
          const start_date = getCampaignStartAt(row.original);
          return start_date ? moment(start_date).format("L") : t("campaign-list:default_start_at_grid_placeholder");
        },
        enableGlobalFilter: false,
        Filter: ({ column }) => {
          return (
            <FormControl>
              <DatePicker
                value={column.getFilterValue()}
                onChange={(newValue: Moment) => {
                  column.setFilterValue(newValue);
                }}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                slotProps={{
                  textField: {
                    sx: { m: "0.5rem 0", width: "100%", "& input": { fontSize: "0.8rem" } },
                    size: "small",
                  },
                }}
              />
            </FormControl>
          );
        },
        // if filter field null show all
        filterFn: (row, id, filterValue: string) =>
          filterValue ? moment(row.getValue(id)).isSame(filterValue, "day") : true,
      },
      {
        id: "campaign_end_date",
        header: t("campaign_end_date"),
        flex: 1,
        accessorFn: (row) => row.end_at && new Date(row.end_at),
        Cell: ({ row }) => {
          return row.original.end_at !== null ? moment(row.original.end_at).format("L") : t("campaign-list:dash");
        },
        enableGlobalFilter: false,
        Filter: ({ column }) => {
          return (
            <FormControl>
              <DatePicker
                value={column.getFilterValue()}
                onChange={(newValue: Moment) => {
                  column.setFilterValue(newValue);
                }}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                slotProps={{
                  textField: {
                    sx: { m: "0.5rem 0", width: "100%", "& input": { fontSize: "0.8rem" } },
                    size: "small",
                  },
                }}
              />
            </FormControl>
          );
        },
        // if filter field null show all
        filterFn: (row, id, filterValue: string) =>
          filterValue ? moment(row.getValue(id)).isSame(filterValue, "day") : true,
      },
    ],
    [i18n.language, t]
  );

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      setFilteredCampaigns(tableInstanceRef.current?.getFilteredRowModel().rows.map((a) => a.original) || []);
    }
  }, [campaigns, columnFilters, globalFilter]);

  useEffect(() => {
    if (campaigns === undefined) {
      return;
    }

    const filters = {
      status: Object.assign(
        {},
        ...Object.entries(CampaignStatusToStyle).map(
          ([status, colorStyle]): Record<string, FiltererItemWithColor> => ({
            [status]: {
              ...colorStyle,
              count: filteredCampaigns.filter((c) => c.status === status).length,
              label: t(status, { ns: "campaign-status" }),
            },
          })
        )
      ),
    };

    setSideFilters(filters);
  }, [t, filteredCampaigns, campaigns]);

  return (
    <>
      <Grid item xs={3}>
        <FilterSelector
          filterTitles={{
            status: t("filter_by_status", { ns: "campaign-list" }),
          }}
          filters={sideFilters}
          onFilterChange={setColumnFilters}
          isLoading={isLoading}
        />
      </Grid>

      <Grid item xs={9}>
        <Box sx={{ width: "100%" }}>
          <DataGridWrapper
            total={t("nb_results", { count: filteredCampaigns.length || 0, ns: "campaign-list" })}
            tableInstanceRef={tableInstanceRef}
            getFacetedUniqueValues={getFacetedUniqueValues()}
            data={orderCampaignByStatus(campaigns)}
            columns={columns}
            filterFns={{
              arrayIncludes: (row, id, filterValue: string[]) =>
                filterValue.length === 0 || filterValue.includes(row.getValue(id)),
            }}
            enableGlobalFilter
            onGlobalFilterChange={setGlobalFilter}
            enableColumnFilters
            onColumnFiltersChange={setColumnFilters}
            enableRowSelection={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding={false}
            enableSorting={false}
            manualSorting
            // if you enable enableColumnActions,
            // change the col sorting: https://github.com/Work4Labs/platform-ui/pull/257#discussion_r1058457640
            enableColumnActions={false}
            state={{ globalFilter, isLoading, columnFilters }}
            initialState={{
              columnPinning: {
                left: ["name"],
              },
            }}
            // style
            muiTableBodyCellProps={({ column, row }) => ({
              "aria-label": t("aria_label_cell", { col_name: column.columnDef.header, index: row.index + 1 }),
              sx: {
                backgroundColor: "inherit",
                cursor: "pointer",
              },
              onClick: () => {
                // onClick on the campaign name cell (used to cover clicking on the whole cell)
                if (column.id === "name") {
                  router.push(`/campaigns/${encodeURIComponent(row.original.campaign_id)}`);
                }
              },
            })}
          />
        </Box>
      </Grid>
    </>
  );
};

type CampaignListPageContentProps = {
  campaigns: SourcingCampaign[];
  isLoading: boolean;
};

export const CampaignListPageContent: FC<CampaignListPageContentProps> = ({ campaigns, isLoading }) => {
  const { t } = useTranslation(["campaign-list"]);
  loadTranslations("campaign-list");

  const { data: session } = useSession();

  const userPermissions = useUserPermissions(session?.accessToken);

  const currentOrganization = useCurrentUserOrganization(session?.user?.id);

  const requestFormLink = useOrganizationRequestFormLink(currentOrganization.data?.group_id);

  const canCreateCampaign = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.SourcingCampaignsPermissions.Write]),
    [userPermissions]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <PageTitle style={{ marginBottom: "20px" }}>{t("title")}</PageTitle>
      </Grid>
      {canCreateCampaign && (
        <Grid item xs={3}>
          <Link
            href={requestFormLink.data?.link || "/sourcing-campaign"}
            target={requestFormLink.data?.link ? "_blank" : undefined}
            passHref
          >
            <SubmitButton
              style={{
                boxShadow:
                  "6px 16px 7px rgba(0, 0, 0, 0.01), 3px 9px 6px rgba(0, 0, 0, 0.05), 2px 4px 4px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                border: "0px",
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}
              disabled={requestFormLink.isLoading}
            >
              {t("create_campaign")}
            </SubmitButton>
          </Link>
        </Grid>
      )}

      <Grid item xs={12}>
        <hr />
      </Grid>
      <CampaignTableWithFilters campaigns={campaigns} isLoading={isLoading} />
    </Grid>
  );
};
