import { ComponentProps, FC } from "react";

import { useTranslation } from "next-i18next";

import { Chip } from "@mui/material";

const VERIFIED_NUMBER_STATUS_SX: ComponentProps<typeof Chip>["sx"] = {
  backgroundColor: "#EBFFE7",
  color: "#448D50",
  borderRadius: "0.25rem",
};

const EMPTY_NUMBER_STATUS_SX: ComponentProps<typeof Chip>["sx"] = {
  backgroundColor: "#FDD5D1",
  color: "#B22323",
  borderRadius: "0.25rem",
};

interface Props {
  phone: string;
  numberStatus: string;
}

export const PhoneNumberStatusChip: FC<Props> = ({ phone, numberStatus }) => {
  const { t } = useTranslation("member-list");

  if (phone === "") return <Chip label={t("number_statuses.empty")} sx={EMPTY_NUMBER_STATUS_SX} />;
  return (
    <Chip
      label={numberStatus === "verified_number" ? t("number_statuses.verified") : t("number_statuses.unverified")}
      sx={numberStatus === "verified_number" ? VERIFIED_NUMBER_STATUS_SX : { borderRadius: "0.25rem" }}
    />
  );
};
