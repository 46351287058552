import { Dispatch, FC, SetStateAction } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { updateRoleBody, userApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthProxyUsersListItem } from "@typings";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user: AuthProxyUsersListItem;
};

export const DeleteUserModal: FC<Props> = ({ open, setOpen, user }) => {
  const { t } = useTranslation("delete-user-modal");
  loadTranslations("delete-user-modal");

  const theme = useTheme();

  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation<void, AxiosError, Partial<updateRoleBody>>({
    mutationFn: userApi.updateRole,
    onSuccess: () => {
      toast.success(t("user_deleted", { firstName: user.first_name, lastName: user.last_name }));
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.USERS],
        })
        .catch(() => {});
      closeModal();
    },
    onError: (err) => {
      switch (err.response?.status) {
        case 403:
          toast.error(t("not_allowed"));
          break;
        default:
          toast.error(t("error_occurred"));
      }
    },
  });

  const deleteUser = () => {
    deleteUserMutation.mutate({ id: user.id, body: { enabled: false } });
  };

  //reset forms when closing modal
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      aria-label="modifier role modal"
      confirmText={t("confirm")}
      cancelText={t("decline")}
      onConfirm={deleteUser}
      onClose={closeModal}
      options={{
        confirmProps: { variant: "danger" },
        centerTitle: true,
      }}
      title={t("delete_user")}
    >
      <ThemeProvider theme={theme}>
        <Box className="content">
          {deleteUserMutation.isPending ? (
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Box>
              <InputLabel htmlFor="selected-user-name">{t("selected_user")}</InputLabel>
              <OutlinedInput
                id="selected-user-name"
                placeholder={t("selected_user")}
                type="text"
                disabled
                defaultValue={user.first_name + " " + user.last_name}
                fullWidth
                sx={(theme) => ({
                  WebkitTextFillColor: theme.palette.primary[900],
                })}
              />

              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.palette.grey[800],
                  fontSize: "13px",
                  padding: "4px 16px",
                })}
              >
                {t("warning_message")}
              </Typography>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
