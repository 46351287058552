import { FC } from "react";

import { useTranslation } from "next-i18next";

import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { AriaProps, Comment, CommentKind } from "@typings";

import { CommentListSubInfo, CommentTextBox, TimelinePerson } from "./style";

interface CommentDetailProps extends AriaProps {
  comment: Comment;
}

export const CommentDetail: FC<CommentDetailProps> = ({ comment, role }) => {
  const { t } = useTranslation(["comment-details", "dates"]);
  loadTranslations("comment-details");
  loadTranslations("dates");

  const { data: user, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, comment.user_id],
    queryFn: () => AuthProxyAPI.getUser(comment.user_id),
  });

  const getKindString = () => {
    switch (comment.kind) {
      case CommentKind.INTERVIEW_CANCELLED:
        return t("cancelled_interview");
      case CommentKind.COMMENT:
        return t("commented");
      default:
        return "";
    }
  };

  const getKindSubString = () => {
    switch (comment.kind) {
      case CommentKind.INTERVIEW_CANCELLED:
        return t("interview_cancellation");
      case CommentKind.COMMENT:
        return t("comment");
      default:
        return "";
    }
  };

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[400] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && !isLoading && (
          <Box>
            <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
              <b>
                {user.first_name} {user.last_name}
              </b>{" "}
              {getKindString()}
            </Box>
            <CommentTextBox>{comment.content}</CommentTextBox>
            <Box sx={{ paddingTop: "0.25rem" }}>
              <CommentListSubInfo
                created_at={comment.created_at}
                is_bulk_action={(comment.is_bulk_action && t("comment_bulk_action")) || ""}
                kind={getKindSubString()}
              />
            </Box>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
