import { SxProps, Theme } from "@mui/system";

import { palette } from "@theme";
import { AuthProxyUser, CallHistory, Interview, InterviewWasDone } from "@typings";

export interface Application {
  id: string;

  campaign_id: string;
  candidate_id: string;
  organization_name: string;
  date: string;

  // job info
  job_id: string;
  job_title: string;
  state: string;
  city: string;
  region: string;
  postal_code: string;
  country_code: string;

  contact: string;
  status: string;
  answers: Answer[];
  last_user_id: string;
  statuses: ApplicationStatus[];
  comments: Comment[];

  created_at: string;
  last_interaction_date: string;

  last_opened_at: string;
}

export interface ApplicationWithLocation extends Application {
  job_location: string;
}

export interface Answer {
  id: string;
  question_label: string;
  answer: string;
}

export interface Question {
  id: string;
  label: string;
}

export interface Candidate {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export enum StatusCategories {
  new = "new",
  processing = "processing",
  finalized = "finalized",
  closed = "closed",
  did_not_answer = "did_not_answer",
  talent_pool = "talent_pool",
  // if you add a new one here, don't forget to update ApplicationStatusToStyle
}
type StatusCategoriesKeys = keyof typeof StatusCategories;

export interface Status {
  id: string;
  label: string;
  category: StatusCategories;
}

export interface ApplicationStatus {
  id: string;
  label: string;
  user_id: string;
  is_bulk_action: boolean;
  created_at: string;
}

export enum CommentKind {
  COMMENT = "COMMENT",
  INTERVIEW_CANCELLED = "INTERVIEW_CANCELLED",
}

export interface Comment {
  id: string;
  content: string;
  user_id: string;
  is_bulk_action: boolean;
  created_at: string;
  kind: CommentKind;
}

// needed for the application history, derived from the ats export history
export interface ApplicationExportHistory {
  id: string;
  status: string;
  user_id: string;
  created_at: string;
}

export type UsersObject = { [x: string]: AuthProxyUser };
export type CandidatesObject = { [x: string]: Candidate };

export const ApplicationStatusToStyle: Record<StatusCategoriesKeys, SxProps<Theme>> = {
  new: {
    backgroundColor: palette.success.light || "",
    color: palette.success.main || "",
  },
  processing: {
    backgroundColor: palette.warning.light || "",
    color: palette.warning.main || "",
  },
  finalized: {
    backgroundColor: palette.primary[100],
    color: palette.primary[900],
  },
  closed: {
    backgroundColor: palette.grey[100] || "",
    color: palette.grey[800] || "",
  },
  did_not_answer: {
    backgroundColor: palette.error.light || "",
    color: palette.error.main || "",
  },
  talent_pool: {
    backgroundColor: "#008772",
    color: "#FFFFFF",
  },
};

export type ApplicationsPerStatuses = {
  id: number | string;
  value: number;
  label: string;
};

export type ApplicationsReport = {
  applications_processed_count: number;
  applications_processed_time: number;
  applications_per_statuses: Array<ApplicationsPerStatuses> | null;
  applications_hire_time: number;
  applications_interview_time: number;
};

export type MessagingHistory = {
  id: string;
  status: string;
  user_id: string;
  created_at: string;
  pipeline_id: number;
};

export type MessagingReminder = {
  id: number;
  application_id: number;
  pipeline_id: number;
  planned_at: string;
  created_at: string;
};

export type MessagingTrigger = {
  id: string;
  status_id: string;
};

export type MessagingHistoryReminder = {
  pipelineId: number;
  userId?: string;
  created_at: string;
  plannedList?: Array<string>;
};

export type ApplicationHistory =
  | Comment
  | ApplicationStatus
  | ApplicationExportHistory
  | MessagingHistoryReminder
  | Interview
  | InterviewWasDone
  | CallHistory
  | DocumentsLogs;

export interface ApplicationStatusReasons {
  refused: "salary" | "schedule" | "unavailable" | "skills" | "behavior" | "location" | "other";
  validationPending: "documents" | "interview_slot" | "manager_feedback" | "candidate_answer" | "other";
}

export type ApplicationStatusReasonSource = "recruiter" | "candidate";

export enum DocumentsLogsType {
  REQUEST = "REQUEST",
  UPLOAD = "UPLOAD",
  AUTOMATION = "AUTOMATION",
}

export type DocumentsLogs = {
  log_type: DocumentsLogsType;
  document_type: string;
  created_at: string;
  user_id: string;
};
