import { AtsIntegration, AtsIntegrationUpsertion, SourcingCampaign, SourcingCampaignCreation } from "@typings";

import { sourcingCampaignsAPI } from "./api";

const create = async (body: SourcingCampaignCreation): Promise<void> => {
  if (body.launch_asap) {
    // make sure we don't set the start_date if we need to launch asap, as we don't
    // want to create confusion. We've used to do this in the form directly, but it's
    // not possible to have an empty string value for a date with MUI anymore.
    body.start_at = "";
  }

  const { data } = await sourcingCampaignsAPI.post("", body);
  return data;
};

const list = async (): Promise<Array<SourcingCampaign>> => {
  const { data } = await sourcingCampaignsAPI.get("");
  return data;
};

const get = async (campaign_id: string): Promise<SourcingCampaign> => {
  const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}`);
  return data;
};

const upsertAtsIntegration = async (campaign_id: string, payload: AtsIntegrationUpsertion): Promise<void> => {
  const { data } = await sourcingCampaignsAPI.post(`/${campaign_id}/ats-integrations`, payload);
  return data;
};

const getAtsIntegration = async (campaign_id: string): Promise<AtsIntegration> => {
  const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}/ats-integrations`);
  return data;
};

const deleteAtsIntegration = async (campaign_id: string): Promise<void> => {
  await sourcingCampaignsAPI.delete(`/${campaign_id}/ats-integrations`);
};

export const SourcingCampaignApi = {
  create,
  list,
  get,
  upsertAtsIntegration,
  getAtsIntegration,
  deleteAtsIntegration,
};
