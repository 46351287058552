import { FC, ReactNode } from "react";

import { Box, ListItem, useTheme } from "@mui/material";

interface Props {
  children?: ReactNode;
  active?: boolean;
}

export const MenuItem: FC<Props> = ({ children, active }) => {
  const theme = useTheme();
  return (
    <ListItem sx={{ backgroundColor: active ? theme.palette.primary[50] : "" }}>
      <Box
        sx={{
          width: "100%",
          cursor: "pointer",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </ListItem>
  );
};
