import { FC, useState } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, Grid, Typography } from "@mui/material";

import { InviteUserModal, MemberList } from "@components";
import { Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

export const MemberSettings: FC = () => {
  const { data: session } = useSession();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation(["members-form"]);
  loadTranslations("members-form");

  return (
    <Box>
      <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h3"
            sx={(theme) => ({
              color: theme.palette.primary[900],
              fontSize: "20px",
              fontStyle: "normal",
              lineHeight: "1.5rem",
              fontWeight: "600",
            })}
          >
            {t("user_management")}
          </Typography>

          <Box sx={{ float: "right" }}>
            <Button
              startIcon={<PersonAddIcon />}
              sx={(theme) => ({
                minWidth: "fit-content",
                textTransform: "none",
                fontWeight: "bold",
                background: theme.palette.primary[900],
                color: "white",
                borderRadius: "0.5rem",
                border: "0px",
                fontSize: "1rem",
                // TODO: update when https://github.com/Work4Labs/platform-ui/pull/264 is merged
                display: hasRole(session, Roles.ADMIN) ? "flex" : "none",
              })}
              onClick={handleOpen}
            >
              {t("invite_new_user")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
        <MemberList />
      </Grid>
      <InviteUserModal open={open} setOpen={setOpen} onClose={handleClose} />
    </Box>
  );
};
