import { FC, useState } from "react";

import { ArrowForward as ArrowForwardIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material";

type Props = {
  src: string;
  title?: string;
  content: string;
  link?: {
    href: string;
    text: string;
  };
};

export const HelperCard: FC<Props> = ({ src, title, content, link }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <Card
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary[50],
            marginBottom: "2.5rem",
            borderRadius: "1rem",
            boxShadow: "none",
          })}
        >
          <CardContent sx={{ position: "relative", height: "100%" }}>
            <CloseIcon
              sx={(theme) => ({
                position: "absolute",
                right: 16,
                top: 16,
                color: theme.palette.text.primary,
                "&:hover": { cursor: "pointer" },
              })}
              onClick={() => setShow(false)}
            />
            <Grid container height="100%">
              <Grid item xs={4} md={3} alignItems="center" justifyContent="center" display="flex">
                <CardMedia component="img" image={src} sx={{ width: "auto" }} />
              </Grid>
              <Grid item xs={8} md={9}>
                <Box display="flex" justifyContent="center" flexDirection="column" height="100%" paddingLeft={"2rem"}>
                  {title && (
                    <Typography variant="h6" fontWeight="bold" sx={(theme) => ({ color: theme.palette.text.primary })}>
                      {title}
                    </Typography>
                  )}

                  <Typography
                    variant="body1"
                    sx={(theme) => ({ color: theme.palette.text.primary, marginY: "0.5rem" })}
                  >
                    {content}
                  </Typography>

                  {link && (
                    <Link href={link.href} target="_blank" fontWeight="bold" sx={{ textDecoration: "none" }}>
                      <Box display="flex">
                        <span>{link.text} </span>
                        <ArrowForwardIcon sx={{ marginLeft: "12px" }} />
                      </Box>
                    </Link>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
