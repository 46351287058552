import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ExpandIconPros = {
  isExpanded: boolean;
  handleClick: () => void;
};

export function ExpandIcon({ isExpanded, handleClick }: ExpandIconPros) {
  return isExpanded ? (
    <FontAwesomeIcon
      icon={faChevronUp}
      style={{ paddingRight: "1em", paddingTop: "5px", height: "21px", color: "#0d0dba" }}
      onClick={handleClick}
    />
  ) : (
    <FontAwesomeIcon
      icon={faChevronDown}
      style={{ paddingRight: "1em", paddingTop: "5px", height: "21px", color: "#0d0dba" }}
      onClick={handleClick}
    />
  );
}
