import { FC, useState } from "react";

import { AxiosError } from "axios";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { userApi } from "@api";
import { loadTranslations } from "@lib";
import { Error } from "@styledcomponents";
import { useMutation } from "@tanstack/react-query";

import { yupResolver } from "@hookform/resolvers/yup";

import { getPasswordValidationSchema } from "../validate";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

//ChangePasswordModal is a modal that allows user to change their password with password validation
export const ChangePasswordModal: FC<Props> = ({ open, setOpen }) => {
  const { data: session } = useSession();
  const email = session?.user.email || "";

  const theme = useTheme();

  const { t } = useTranslation(["change-password-modal", "profile-validation"]);
  loadTranslations("change-password-modal");
  loadTranslations("profile-validation");

  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(getPasswordValidationSchema(t, email), { abortEarly: false }),
    mode: "onChange",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const updatePasswordMutation = useMutation({
    mutationFn: userApi.updatePassword,
    onSuccess: () => {
      toast.success(t("password_updated"));
      closeModal();
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 500) {
        toast.error(t("used_password"));
      } else if (err.response?.status === 401) {
        toast.error(t("bad_credentials"));
      } else {
        toast.error(t("error_occurred"));
      }
    },
  });

  const changePassword = () => {
    updatePasswordMutation.mutate({
      old_password: getValues("currentPassword"),
      new_password: getValues("newPassword"),
      repeat_password: getValues("passwordConfirmation"),
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      aria-label="modal-change-password"
      confirmText={t("change-password-modal:submit")}
      cancelText={t("change-password-modal:decline")}
      onConfirm={changePassword}
      onClose={closeModal}
      options={{
        centerTitle: true,
        confirmProps: { disabled: !isValid },
      }}
      title={t("change-password-modal:change_password")}
    >
      <ThemeProvider theme={theme}>
        <Box className="content" sx={{ maxWidth: "620px", width: "456px" }}>
          {updatePasswordMutation.isPending ? (
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Box>
              <Box sx={{ marginTop: "2rem" }}>
                <InputLabel htmlFor="current-password">{t("change-password-modal:current_password")}</InputLabel>
                <OutlinedInput
                  id="current-password"
                  placeholder={t("change-password-modal:current_password")}
                  type={showCurrentPassword ? "text" : "password"}
                  fullWidth
                  {...register("currentPassword", { required: true })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.currentPassword && <Error>{errors.currentPassword?.message}</Error>}
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <InputLabel htmlFor="new-password">{t("change-password-modal:new_password")}</InputLabel>
                <OutlinedInput
                  id="new-password"
                  placeholder={t("change-password-modal:new_password")}
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  {...register("newPassword", { required: true })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {errors.newPassword && <Error>{errors.newPassword?.message}</Error>}
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <InputLabel htmlFor="password-confirmation">
                  {t("change-password-modal:password_confirmation")}
                </InputLabel>
                <OutlinedInput
                  id="password-confirmation"
                  placeholder={t("change-password-modal:password_confirmation")}
                  type={showPasswordConfirmation ? "text" : "password"}
                  fullWidth
                  {...register("passwordConfirmation", { required: true })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                        edge="end"
                      >
                        {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.passwordConfirmation && <Error>{errors.passwordConfirmation?.message}</Error>}
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
