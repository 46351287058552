import { FC } from "react";

import { Trans, useTranslation } from "next-i18next";

import { WorkHistory as WorkIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

import { ApplicationScoringChip, ScoreBeingComputedChip } from "@components";
import { TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";

type ApplicationCompatibilityProps = {
  applicationId: string;
  campaignId: string;
  applicationScore: number | undefined;
  shouldRenderScoreChip: boolean;
};

export const ApplicationCompatibility: FC<ApplicationCompatibilityProps> = ({
  applicationId,
  campaignId,
  applicationScore,
  shouldRenderScoreChip,
}) => {
  const { t } = useTranslation(["application-details", "application-scoring"]);
  loadTranslations("application-details");
  loadTranslations("application-scoring");

  return (
    <Grid container sx={{ padding: "24px 40px 24px 40px" }}>
      <Grid item xs={12} aria-label="compatibility" role="region">
        <WorkIcon sx={{ width: "18px", height: "18px", marginRight: "12px", float: "left" }} />
        <Typography
          variant="h3"
          sx={(theme) => ({
            // float to left because ApplicationScoringChip contains multiple div that can't be displayed as inline
            // we also can't use the grid because the length of the text will vary.
            // Don't float if we have no chip to display, because then the sub text overlaps
            float: shouldRenderScoreChip ? "left" : "none",
            color: theme.palette.grey[800],
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "1.5rem",
            fontWeight: "600",
          })}
        >
          {t("compatibility")}
        </Typography>
        {shouldRenderScoreChip && (
          <Box
            style={{
              // re-position the chip with the position because the title is floating
              position: "relative",
              top: "-5px",
              left: "0.5rem",
              float: "left",
            }}
          >
            <ApplicationScoringChip applicationID={applicationId} renderChipForLowPercentages campaignID={campaignId} />
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        {applicationScore === undefined && (
          <>
            <Typography
              variant="tags"
              sx={{
                color: TEXT_COLOR.mainInfo,
              }}
            >
              {t("compatibility_computing", { ns: "application-scoring" })}
            </Typography>
            {ScoreBeingComputedChip(t, { display: "inline", position: "relative", top: "12px" })}
          </>
        )}
        {applicationScore !== undefined && applicationScore >= 0 && (
          <Typography variant="tags">
            <Trans
              t={t}
              i18nKey="compatibility_sub_text"
              // eslint-disable-next-line react/jsx-key
              components={[<strong />]}
              values={{
                // round the score to the nearest 10
                percentage: Math.round(applicationScore / 10) * 10,
              }}
            />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
