import { ApplicationStatusReasonSource, ApplicationStatusReasons } from "@typings";

export const STATUSES_WITH_AUTOMATION = ["new", "did not answer", "refused"];

export const STATUSES_WITH_OPTIONAL_SMS = ["refused"];

export const STATUSES_WITH_REASON = ["refused", "validation pending"];

export const STATUSES_REASONS_WITH_MANDATORY_COMMENT = ["other", "candidate_answer"];

export const STATUSES_REASONS_WITH_SOURCE = ["refused"];

export const STATUS_REASON_SOURCES: ApplicationStatusReasonSource[] = ["recruiter", "candidate"];

export const STATUS_REFUSAL_REASONS: ApplicationStatusReasons["refused"][] = [
  "salary",
  "schedule",
  "unavailable",
  "skills",
  "behavior",
  "location",
  "other",
];

export const STATUS_VALIDATION_PENDING_REASONS: ApplicationStatusReasons["validationPending"][] = [
  "documents",
  "interview_slot",
  "manager_feedback",
  "candidate_answer",
  "other",
];

export const STATUS_VALIDATION_REASONS_PER_LABEL: Record<string, string[]> = {
  refused: STATUS_REFUSAL_REASONS,
  ["validation pending"]: STATUS_VALIDATION_PENDING_REASONS,
};
