/**
 * This method ensure that a given array is not undefined
 * and return an empty array if it is to avoid having || [] everywhere
 * @param array
 * @returns array
 */
export function ensureArray<T>(array: Array<T> | undefined): Array<T> {
  return array || [];
}

/**
 * Convert a given hex to a rgba color.
 *
 * @param hex
 * @param alpha
 * @returns
 */
export const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
  return `rgba(${r as string},${g as string},${b as string},${alpha})`;
};
