import { MessagingHistory, MessagingReminder, MessagingTrigger } from "@typings";

import { messagingAPI } from "./api";

interface GetHistoriesQueryParams {
  application_id: string;
}
interface GetRemindersQueryParams {
  application_id: string;
}

interface GetTriggersQueryParams {
  campaign_id: string;
}

const getHistories = async (params: GetHistoriesQueryParams): Promise<Array<MessagingHistory>> => {
  const { data } = await messagingAPI.get(`/histories`, { params });
  return data;
};

const getReminders = async (params: GetRemindersQueryParams): Promise<Array<MessagingReminder>> => {
  const { data } = await messagingAPI.get(`/reminders`, { params });
  return data;
};

const getTriggers = async (params: GetTriggersQueryParams): Promise<Array<MessagingTrigger>> => {
  const { data } = await messagingAPI.get(`/triggers`, { params });
  return data;
};

export const MessagingApi = {
  getHistories,
  getReminders,
  getTriggers,
};
