import { ExportHistory } from "@typings";

import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { atsAPI } from "./api";

const getExportHistories = async (application_id: string): Promise<ExportHistory[]> => {
  const { data } = await atsAPI.get(`/export-histories`, {
    params: { application_external_id: application_id },
  });
  return data;
};

const getExporterJSONSchema = async (workflowPath: string): Promise<RJSFSchema> => {
  const { data } = await atsAPI.get(`/exporters/${workflowPath}/json-schema`);
  return data;
};

const getExporterUISchema = async (workflowPath: string): Promise<UiSchema> => {
  const { data } = await atsAPI.get(`/exporters/${workflowPath}/ui-schema`);
  return data;
};

export const AtsAPI = {
  getExportHistories,
  getExporterJSONSchema,
  getExporterUISchema,
};
