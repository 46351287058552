import { FC, useCallback, useMemo, useState } from "react";

import moment from "moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Update as UpdateIcon } from "@mui/icons-material";
import { Button, MenuItem, SelectChangeEvent } from "@mui/material";

import { Modal, ModalProps, SelectWithModal } from "@work4Labs/design-system";

import { ApplicationApi, ApplicationStatusReason, InterviewApi } from "@api";
import { QUERY_KEYS, STATUSES_REASONS_WITH_SOURCE, STATUSES_WITH_OPTIONAL_SMS, STATUSES_WITH_REASON } from "@constants";
import { loadTranslations } from "@lib";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Application,
  AtsIntegration,
  Interview,
  InterviewsConfiguration,
  MessagingTrigger,
  ORGANIZATIONS_FEATURES,
  Status,
} from "@typings";

import {
  useApplicationStatusReason,
  useCurrentUserOrganization,
  useFetchInterviewsConfigurationByCampaignID,
  useOrganizationFeatures,
} from "@hooks/queries";

import { ApplicationInterviewModal } from "../../application/application-interview-modals";
import {
  RenderATSIntegration,
  RenderStatus,
  RenderTriggers,
  SendSMSModalForm,
  StatusLabelChip,
  StatusSelectorLabel,
  UpdateReasonWhyForm,
} from "./components";
import { ApplicationStatusReasonWhyForm } from "./utils";

/**
 * Handlers for application status.
 */
const useStatuses = (application?: Application) => {
  // List available statuses for the current application.
  const statusesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
  });

  // MReturn an API status object from a given label, if it exists.
  const findStatusFromLabel = useCallback(
    (label: string) => statusesQuery.data?.find((status) => status.label === label) ?? null,
    [statusesQuery.data]
  );

  // Retrieve the current status of the application (most recent one).
  const applicationStatus = useMemo(
    () =>
      application?.statuses.reduce(
        (acc, value) => (moment(value.created_at).isAfter(acc.created_at) ? value : acc),
        application.statuses[0]
      ),
    [application]
  );

  return { statusesQuery, findStatusFromLabel, applicationStatus };
};

export interface ApplicationStatusUpdateParams {
  reasonWhy?: ApplicationStatusReasonWhyForm;
  sendSMS: boolean;
}

interface ApplicationStatusUpdaterParams {
  applicationID: string;
  newStatus: Status | null;
  campaignID: string;
  queryClient: QueryClient;
  updateParams: ApplicationStatusUpdateParams;
}

/**
 * Update the application status (and clear the cache).
 * You can pass extra params with the `updateParams` to specify the reason why and send a sms
 */
export const updateApplicationStatus = async ({
  applicationID,
  newStatus,
  campaignID,
  queryClient,
  updateParams,
}: ApplicationStatusUpdaterParams) => {
  if (newStatus === null) {
    return;
  }

  const statusRes = await ApplicationApi.updateApplicationStatus({
    application_id: applicationID,
    body: { id: newStatus.id, is_bulk_action: false, send_sms: updateParams.sendSMS },
  });

  // Update reason why if available.
  if (updateParams.reasonWhy) {
    await ApplicationApi.createApplicationStatusReason({
      application_id: applicationID,
      status_id: statusRes.id,
      body: {
        source: (STATUSES_REASONS_WITH_SOURCE.includes(newStatus.label) && updateParams.reasonWhy.source) || "",
        reason: updateParams.reasonWhy.reason as ApplicationStatusReason["reason"],
        comment: updateParams.reasonWhy.comment ?? "",
      },
    });
  }

  // Update cached queries with the new status for application.
  const cachedApplications = queryClient.getQueryData([QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignID]) as Application[];

  if (cachedApplications) {
    const applicationToUpdate = cachedApplications.findIndex((cached) => cached.id == applicationID);
    // Modify the cached value.
    cachedApplications[applicationToUpdate].status = newStatus.label;
    queryClient
      .invalidateQueries({
        queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
      })
      .catch(() => {});

    // Update cache.
    queryClient.setQueryData([QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignID], cachedApplications);
  }
};

interface SelectApplicationStatusProps {
  /**
   * The ID of the campaign the application belongs to.
   */
  campaignId: string;
  /**
   * The application for which the status is being updated.
   */
  application?: Application;
  /**
   * Custom value for the input label.
   */
  customLabel?: string;
  /**
   * Toggle visibility of the input label.
   */
  displayInputLabel?: boolean;
  /**
   * True if we want to let the user select a "reason why" for the new status
   */
  withReasonWhySelection?: boolean;
  /**
   * True if we want to show the interview creation modal to users when the new status is interview.
   * (so that users use the interview feature)
   */
  withSetupInterview?: boolean;
  /**
   * Allow the user to choose if they want to send an SMS or not, if available.
   */
  sendSMS?: boolean;
  /**
   * Callback for when the status is changed.
   *
   * The second argument contains extra parameters for updates.
   */
  onChange: (newStatus: Status | null, params: ApplicationStatusUpdateParams) => void;
  dropDownScaleWidth?: number;
}

/**
 * A component to select the new status of application. It contains logic to display diverse pop-up depending on the
 * status selected.
 * @returns
 */
export const SelectApplicationStatus: FC<SelectApplicationStatusProps> = ({
  campaignId,
  application,
  customLabel,
  displayInputLabel = true,
  withReasonWhySelection,
  withSetupInterview,
  dropDownScaleWidth,
  sendSMS: withSendSMS,
  onChange: onChangeProp,
}) => {
  // Instantiate dependencies.
  const { t } = useTranslation(["application-status", "status-selector-field"]);
  loadTranslations("application-status");
  loadTranslations("status-selector-field");

  const queryClient = useQueryClient();

  const triggers = queryClient.getQueryData<MessagingTrigger[]>([QUERY_KEYS.MESSAGING_TRIGGERS, campaignId]);
  const atsIntegration = queryClient.getQueryData<AtsIntegration>([QUERY_KEYS.ATS_INTEGRATIONS, campaignId]);

  const { data: session } = useSession();
  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);
  const { hasFeature: organizationHasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "");

  // Form data.
  const [reasonWhy, setReasonWhy] = useState<ApplicationStatusReasonWhyForm>();
  const [reasonWhyFormValid, setReasonWhyFormValid] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>();

  // Here are the hooks we are going to use to interact with the data being updated.
  const { statusesQuery, findStatusFromLabel, applicationStatus } = useStatuses(application);
  const applicationStatusReason = useApplicationStatusReason(applicationStatus, application);

  const interviewQuery = useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW, application?.id],
    queryFn: () => InterviewApi.getInterview(application?.id || ""),
  });

  const interview = useMemo<Interview | undefined>(() => {
    // get the active interview
    return interviewQuery.data?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment());
  }, [interviewQuery.data]);

  const interviewConfigurationQuery = useFetchInterviewsConfigurationByCampaignID({
    campaignId,
  });

  const interviewConfiguration = useMemo<InterviewsConfiguration | undefined>(() => {
    return interviewConfigurationQuery.data;
  }, [interviewConfigurationQuery.data]);

  // Render the status label.
  const statusRenderValue = useCallback(
    (label: string | undefined) => (
      <StatusLabelChip
        status={findStatusFromLabel(label ?? "") ?? undefined}
        // Only show reason while status has not been edited (otherwise it leads to incoherent states).
        reason={applicationStatus?.label === label ? applicationStatusReason?.data : undefined}
      />
    ),
    [findStatusFromLabel, applicationStatus?.label, applicationStatusReason?.data]
  );

  const resetStatueValues = useCallback(() => {
    setReasonWhy(undefined);
    setSendSMS(undefined);
  }, []);

  const interviewModal = useCallback(
    (_: string, submitAndCloseModal: () => unknown, cancelAndCloseModal: () => unknown, modalOpened: boolean) => {
      if (!interviewConfiguration) {
        return null;
      }

      return (
        <ApplicationInterviewModal
          open={modalOpened}
          onConfirm={() => {
            submitAndCloseModal(); // will take care of closing the modal
          }}
          onCancel={() => {
            cancelAndCloseModal(); // will take care of closing the modal
          }}
          onSkip={() => {
            // validate the status change, even if no interview was created
            submitAndCloseModal(); // will take care of closing the modal
          }}
          campaignId={campaignId}
          applicationID={application?.id ?? ""}
          interviewConfiguration={interviewConfiguration}
          allowSkip={true}
        />
      );
    },
    [application?.id, campaignId, interviewConfiguration]
  );

  const reasonWhyModal = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: ModalProps["onConfirm"],
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean
    ) => {
      const canSetupSendSMS =
        withSendSMS &&
        STATUSES_WITH_OPTIONAL_SMS.includes(selectedStatus) &&
        triggers?.some((trigger) => trigger.status_id === findStatusFromLabel(selectedStatus)?.id);

      return (
        <Modal
          scroll={"body"}
          modalIcon={<UpdateIcon />}
          title={t(`reasons.${selectedStatus}.modal.title`)}
          modalTitle={t(`reasons.${selectedStatus}.modal.category`)}
          confirmText={t("reasons.modal.confirm")}
          cancelText={t("reasons.modal.cancel")}
          onConfirm={submitAndCloseModal}
          onClose={() => {
            resetStatueValues();
            cancelAndCloseModal();
          }}
          options={{
            confirmProps: {
              disabled: !reasonWhyFormValid,
            },
          }}
          isOpen={modalOpened}
        >
          <UpdateReasonWhyForm
            sendSMS={sendSMS}
            setSendSMS={canSetupSendSMS ? setSendSMS : undefined}
            pendingStatus={selectedStatus}
            reasonWhy={reasonWhy}
            setReasonWhy={setReasonWhy}
            setFormValid={setReasonWhyFormValid}
          />
        </Modal>
      );
    },
    [resetStatueValues, findStatusFromLabel, reasonWhy, reasonWhyFormValid, sendSMS, t, triggers, withSendSMS]
  );

  const sendSMSModal = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: () => unknown,
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean
    ) => {
      const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
        return (
          <>
            <Button
              color="secondary"
              onClick={() => {
                setSendSMS(undefined);
                onClose?.();
              }}
            >
              {t("send sms.modal.cancel")}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setSendSMS(false);
                onConfirm?.();
              }}
            >
              {t("send sms.modal.dont send")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setSendSMS(true);
                onConfirm?.();
              }}
            >
              {t("send sms.modal.send")}
            </Button>
          </>
        );
      };

      return (
        <Modal
          scroll={"body"}
          modalIcon={<UpdateIcon />}
          modalTitle={t("send sms.modal.title")}
          onConfirm={submitAndCloseModal}
          onClose={() => {
            resetStatueValues();
            cancelAndCloseModal();
          }}
          customActions={customActions}
          options={{
            confirmProps: {
              disabled: !reasonWhyFormValid,
            },
          }}
          isOpen={modalOpened}
        >
          <SendSMSModalForm pendingStatus={selectedStatus} />
        </Modal>
      );
    },
    [resetStatueValues, reasonWhyFormValid, t]
  );

  const onChange = useCallback<(e: SelectChangeEvent<string | undefined>) => void>(
    (e) => {
      const statusLabel = e.target.value;

      onChangeProp(findStatusFromLabel(statusLabel ?? "new"), {
        reasonWhy: reasonWhy,
        sendSMS: sendSMS ?? true,
      });

      setSendSMS(undefined);
      setReasonWhy(undefined);
    },
    [findStatusFromLabel, onChangeProp, reasonWhy, sendSMS]
  );

  // Called when a new status is select. This function is responsible for checking if we should show a modal
  // to the user (diverse business logic).
  // For each modal, we return a function that takes the new selected status, two functions to submit the modal
  // and the modal status.
  const getModalToRender = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: () => unknown,
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean
    ) => {
      if (!selectedStatus || selectedStatus === applicationStatus?.label) {
        return null;
      }

      const alreadyHasInterview = interview && !interview.deleted;
      if (selectedStatus === "interview" && withSetupInterview && !alreadyHasInterview && interviewConfiguration) {
        return interviewModal(selectedStatus, submitAndCloseModal, cancelAndCloseModal, modalOpened);
      }

      if (
        withReasonWhySelection &&
        organizationHasFeature(ORGANIZATIONS_FEATURES.APPLICATION_STATUS_REASON) &&
        STATUSES_WITH_REASON.includes(selectedStatus)
      ) {
        return reasonWhyModal(selectedStatus, submitAndCloseModal, cancelAndCloseModal, modalOpened);
      }

      const canSetupSendSMS =
        withSendSMS &&
        STATUSES_WITH_OPTIONAL_SMS.includes(selectedStatus) &&
        triggers?.some((trigger) => trigger.status_id === findStatusFromLabel(selectedStatus)?.id);
      if (canSetupSendSMS) {
        return sendSMSModal(selectedStatus, submitAndCloseModal, cancelAndCloseModal, modalOpened);
      }

      return null;
    },
    [
      applicationStatus?.label,
      findStatusFromLabel,
      interview,
      interviewModal,
      reasonWhyModal,
      sendSMSModal,
      triggers,
      withReasonWhySelection,
      withSendSMS,
      withSetupInterview,
      organizationHasFeature,
      interviewConfiguration,
    ]
  );

  if (statusesQuery.data == null) {
    return null;
  }

  return (
    <SelectWithModal
      label={displayInputLabel ? <StatusSelectorLabel customLabel={customLabel} /> : null}
      initialValue={applicationStatus?.label ?? ""}
      renderValue={statusRenderValue}
      getModalToRender={getModalToRender}
      onChange={onChange}
      labelID="status-selector-label"
      dropDownScaleWidth={dropDownScaleWidth ?? 1.5}
      selectProps={{
        sx: {
          ".MuiInputBase-root": {
            margin: "0",
            padding: "0",
          },
        },
      }}
    >
      {statusesQuery.data.map((status: Status) => (
        <MenuItem key={status.label} value={status.label} id={`change_status_${status.label.replaceAll(" ", "_")}`}>
          <RenderStatus status={status} />
          <RenderTriggers triggers={triggers} status={status} />
          <RenderATSIntegration atsIntegration={atsIntegration} status={status} />
        </MenuItem>
      ))}
    </SelectWithModal>
  );
};
