import Link from "next/link";

import { FC, ReactNode } from "react";

import { useTheme } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  href: string;
  active: boolean;
  children?: ReactNode;
}

export const ActiveLink: FC<Props> = ({ children, active, href }) => {
  const theme = useTheme();
  return (
    <Link href={href} passHref>
      <Box sx={{ display: "contents" }}>
        {active && (
          <Box
            component="span"
            m="{1}"
            sx={{
              width: "5px",
              borderRadius: "0 16px 16px 0",
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              backgroundColor: theme.palette.primary[900],
            }}
          />
        )}
        {children}
      </Box>
    </Link>
  );
};
